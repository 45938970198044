import produce from "immer";

import * as AT from "data/rootActionTypes";

const Initial_State = {
  [AT.TEST]: null,
  [AT.USER_LIST]: [],
  [AT.INDIVIDUAL_POINT]: [],
  [AT.SK_USER_PARTICIPATION]: null,
};

const point = produce((draft, action) => {
  switch (action.type) {
    case AT.TEST:
      draft[AT.TEST] = action.payload;
      break;
    case AT.USER_LIST:
      draft[AT.USER_LIST] = action.payload;
      break;
    case AT.INDIVIDUAL_POINT:
      draft[AT.INDIVIDUAL_POINT] = action.payload;
      break;
    case AT.SK_USER_PARTICIPATION:
      draft[AT.SK_USER_PARTICIPATION] = action.payload;
      break;
    default:
      return;
  }
}, Initial_State);

export default point;
