import React from "react";
import Helmet from "react-helmet";
import DayPicker from "react-day-picker";
import moment from "moment";
import "react-day-picker/lib/style.css";
import Modal from "../Modal/Modal";
import { connect } from "react-redux";
import * as actionTypes from "data/recommendation/actionTypes";
import "./Calendar.css";

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
}

class Example extends React.Component {
  state = {
    hoverRange: undefined,
    selectedDays: [],
    weekNumber: 0,
  };

  getWeek = (date) => {
    let monthStart = new Date(date);
    monthStart.setDate(0);
    let offset = Math.max(((monthStart.getDay() + 1) % 7) - 1, 0); // -1 is for a week starting on Monday
    return Math.ceil((date.getDate() + offset) / 7 - 1);
  };

  handleDayChange = (date) => {
    const year = Number(date.getFullYear());
    const thisWeek = this.getWeek(date);
    this.props.onUpdateWeek(thisWeek);
    this.props.onUpdateMonth(Number(date.getMonth()) + 1);
    this.props.onUpdateYear(year);
    if (this.props.modifyState) {
      this.props.onUpdateModifyState();
    }

    this.props.onResetSelectedOn();

    this.setState({
      // choose only weekdays
      selectedDays: getWeekDays(getWeekRange(date).from),
    });
  };

  handleDayEnter = (date) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      weekNumber: weekNumber,
      selectedDays: days,
    });
  };

  render() {
    const { hoverRange, selectedDays } = this.state;
    const daysAreSelected = selectedDays.length > 0;
    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[1],
        to: selectedDays[5],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[1],
      selectedRangeEnd: daysAreSelected && selectedDays[5],
    };

    return (
      <Modal show={this.props.show}>
        <div className="SelectedWeekExample">
          <DayPicker
            selectedDays={selectedDays}
            showOutsideDays
            modifiers={modifiers}
            onDayClick={this.handleDayChange}
            onDayMouseEnter={this.handleDayEnter}
            onDayMouseLeave={this.handleDayLeave}
            onWeekClick={this.handleWeekClick}
            disabledDays={[{ daysOfWeek: [0, 6] }]}
          />

          <Helmet>
            <style>{`
            .SelectedWeekExample .DayPicker-Month {
              border-collapse: separate;
            }
            .SelectedWeekExample .DayPicker-WeekNumber {
              outline: none;
            }
            .SelectedWeekExample .DayPicker-Day {
              outline: none;
              border: 1px solid transparent;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange {
              background-color: #EFEFEF !important;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange {
              background-color: #fff7ba !important;
              border-top-color: #FFEB3B;
              border-bottom-color: #FFEB3B;
              border-left-color: #fff7ba;
              border-right-color: #fff7ba;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeStart {
              background-color: #FFEB3B !important;
              border-left: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
              background-color: #FFEB3B !important;
              border-right: 1px solid #FFEB3B;
            }

            .SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
            .SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
              border-radius: 0 !important;
              color: black !important;
            }
            .SelectedWeekExample .DayPicker-Day--hoverRange:hover {
              border-radius: 0 !important;
            }
          `}</style>
          </Helmet>
        </div>
        <div>원하시는 날짜를 선택해주세요.</div>
        <button className="button button1" onClick={this.props.onShowCalendar}>
          확인
        </button>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    currentYear: state.recommend.calendar.currentYear,
    currentMonth: state.recommend.calendar.currentMonth,
    month: state.recommend.calendar.month,
    year: state.recommend.calendar.year,
    modifyState: state.recommend.selection.modifyState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateWeek: (week) =>
      dispatch({
        type: actionTypes.UPDATE_WEEK,
        week: week,
      }),

    onUpdateMonth: (month) =>
      dispatch({
        type: actionTypes.UPDATE_MONTH,
        month: month,
      }),

    onUpdateYear: (year) =>
      dispatch({
        type: actionTypes.UPDATE_YEAR,
        year: year,
      }),

    onShowCalendar: () =>
      dispatch({
        type: actionTypes.UPDATE_SHOW_CALENDAR,
      }),

    onUpdateModifyState: () =>
      dispatch({
        type: actionTypes.UPDATE_MODIFY_STATE,
      }),

    onResetSelectedOn: () =>
      dispatch({
        type: actionTypes.RESET_SELECTED_ON,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Example);
