import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Formik, Form } from "formik";
import { object, string } from "yup";
import moment from "moment";

import { apiClient } from "api/client";

import styled from "styled-components";

import NavigationBar from "../NavigationBar";
import SearchBox from "./SearchBox";
import ResultBox from "./ResultBox";
import PlateBox from "./PlateBox";

import { MainRowContainer, MainColContainer } from "../StyledComps";

let initialValues = {
  departmentId: "",
  cornerId: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  bld: "",
};

const validationSchema = object({
  departmentId: string().required(),
  date: string().required(),
  bld: string().required(),
});

const navigationOptions = [
  { name: "메뉴 조회/수정", to: "/editMenu" },
  { name: "메뉴 입력", to: "/makeMenu" },
];

export default function EditMenu() {
  const location = useLocation();

  const [menuInfo, setMenuInfo] = useState({});
  const [customFoodList, setCustomFoodList] = useState([]);
  
  const onSubmit = async (values) => {
    const { departmentId, cornerId, bld, date } = values;
    const res = await apiClient.get(`/api/menu/single`, {
      params: {
        departmentId,
        cornerId,
        date,
        bld,
      },
    });

    if (res.data) {
      setCustomFoodList(res.data.foodlist);
      setMenuInfo(res.data.menu);
    } else {
      setCustomFoodList([]);
      setMenuInfo({});
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        submitForm={onSubmit}
      >
        <StyledForm>
          <NavigationBar options={navigationOptions} />
          <MainRowContainer>
            <MainColContainer>
              <SearchBox passedValues={location.state} />
              <PlateBox menuInfo={menuInfo} />
            </MainColContainer>

            <MainColContainer flex={2}>
              <ResultBox menuInfo={menuInfo} customFoodList={customFoodList} />
            </MainColContainer>
          </MainRowContainer>
        </StyledForm>
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  margin: 10px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-top: 0px;
`;