import styled from "styled-components";
import { Form } from "formik";

export const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 40px;
  margin: 10px;
`;

export const StyledForm = styled(Form)`
  width: 100%;
  padding: 30px;
  padding-top: 0px;
`;

export const SearchContainer = styled.div`
  flex: 1;
  padding: 5px;
  padding-top: 15px;
  background-color: none;
  border-bottom: 3px solid lightgray;
`;

export const SearchOptionContainer = styled.div`
  background-color: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border: 1px solid none;
`;

export const SearchBarContainer = styled.div`
  background-color: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
  width: 100%;
  background-color: none;
  font-size: 30px;
  font-weight: 300;
`;

export const InnerContainer = styled.div`
  width: 100%;
  min-height: 85vh;
  background-color: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 3px solid lightgray;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 3px solid lightgray;
  flex: 1.5;
  border: 1px solid none;
`;

export const MiniDashContainer = styled.div`
  background-color: none;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 15px;
`;

export const ResultContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: none;
`;

export const ResultRowContainer = styled.div`
  width: 95%;
  background-color: none;

  display: flex;
  justify-content: space-around;
  padding: 2px;
  marign: 3px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

export const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

export const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 3px;
`;

export const MenuBox = styled.div`
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 10px;
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 1)};
  flex-direction: column;
  margin-top: 10px;
  background-color: none;
`;

export const MenuTitle = styled.div`
  width: 100%;
  background-color: none;
  font-size: 35px;
  font-weight: 300;
  padding: 10px;
`;

export const MainRowContainer = styled.div`
  width: 100%;
  height: 85vh;
  border: 1px solid none;
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => (flex ? flex : 1)};
  gap: 20px;
`;

export const MainColContainer = styled(MainRowContainer)`
  flex-direction: column;
`;


