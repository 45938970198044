import * as actionTypes from "../actionTypes";

const initialState = {
  monthData: {},
  userInputData: {},
  amountData: {},
  userName: "",
  userEmail: "",
  userId: "",
  config: {},
  initialized: false,
  startDownload: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MONTHDATA:
      return {
        ...state,
        monthData: action.monthData,
      };

    case actionTypes.UPDATE_AMOUNT_DATA:
      return {
        ...state,
        amountData: action.amountData,
      };

    case actionTypes.UPDATE_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case actionTypes.UPDATE_CONFIG:
      return {
        ...state,
        config: action.config,
      };

    case actionTypes.UPDATE_USER_EMAIL:
      return {
        ...state,
        userEmail: action.userEmail,
      };
    case actionTypes.UPDATE_USER_ID:
      return {
        ...state,
        userId: action.userId,
      };
    case actionTypes.UPDATE_INITIALIZED:
      return {
        ...state,
        initialized: action.initialized,
      };

    case actionTypes.UPDATE_START_DOWNLOAD:
      return {
        ...state,
        startDownload: !state.startDownload,
      };
    case actionTypes.UPDATE_USER_INPUT_DATA:
      return {
        ...state,
        userInputData: action.userInputData,
        amountData: action.amountData,
      };
    default:
      return state;
  }
};
