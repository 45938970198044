import { api as authApi } from "./auth";
import { api as departmentApi } from "./department";
import { api as userApi } from "./user";
import { api as mailApi } from "./mail";
import { api as inventory } from "./inventory";
import { api as dashboardApi } from "./dashboard";
import { api as pointApi } from "./point";
import { api as foodApi } from "./food";

export default {
  authApi,
  departmentApi,
  userApi,
  mailApi,
  inventory,
  dashboardApi,
  pointApi,
  foodApi
};
