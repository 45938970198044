import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "api";
import { apiClient } from "api/client";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const AddDepartmentModal = ({
  handleDepartmentModalOpen,
  handleEngDepartmentNameChange,
  handleDepartmentNameChange,
  handleDepartmentName,
  handleEngDepartmentName,
  departmentName,
  engDepartmentName,
  getJsonDepartmentList,
  jsonDepartmentList,
}) => {
  const [nutritionist, setNutritionist] = useState("");
  const [type, setType] = useState("");
  const [educationOffice, setEducationOffice] = useState("");
  const [sourceDepartmentId, setSourceDepartmentId] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [zerowasteDefaultVolume, setZerowasteDefaultVolume] = useState(0);
  const [dailyTextType, setDailyTextType] = useState("");

  const departmentType = [
    { value: 1, name: "학교" },
    { value: 2, name: "회사" },
    { value: 3, name: "관공서" },
    { value: 4, name: "기관" },
  ];

  const educationOfficeTypeOptions = [
    "서울특별시교육청",
    "부산광역시교육청",
    "대구광역시교육청",
    "인천광역시교육청",
    "광주광역시교육청",
    "대전광역시교육청",
    "울산광역시교육청",
    "세종특별자치시교육청",
    "경기도교육청",
    "강원도교육청",
    "충청북도교육청",
    "충청남도교육청",
    "전라북도교육청",
    "전라남도교육청",
    "경상북도교육청",
    "경상남도교육청",
    "제주특별자치도교육청",
    "재외한국학교교육청",
  ];

  const handleNutritionistChange = (e) => {
    setNutritionist(e.target.value);
  };
  const handleDepartmentType = (e) => {
    setType(e.target.value);
  };

  const handleEducationOffice = (e) => {
    setEducationOffice(e.target.value);
  };

  const handleNutritionist = (name) => {
    setNutritionist(name);
  };
  const handleType = (type) => {
    setType(type);
  };

  const handleSourceDepartmentId = (e) => {
    setSourceDepartmentId(e.target.value);
  };

  const handleNewRadioButton = () => {
    setIsChecked(true);
  };

  const handleOtherRadioButton = () => {
    setIsChecked(false);
  };

  const handleTotalNumber = (e) => {
    setTotalNumber(e.target.value);
  };

  const handleDailyTextType = (e) => {
    setDailyTextType(e.target.value);
  };

  const handleZerowasteDefaultVolume = (e) => {
    setZerowasteDefaultVolume(e.target.value);
  };

  const handleCloseButton = () => {
    document.getElementById("department").value = "";
    document.getElementById("engDepartment").value = "";
    document.getElementById("nutritionist").value = "";
    document.getElementById("type").selectedIndex = 0;
    document.getElementById("sourceDepartmentName").selectedIndex = 0;
    setSourceDepartmentId(null);
    handleDepartmentName(null);
    handleEngDepartmentName(null);
    handleNutritionist(null);
    handleType(null);
    handleDepartmentModalOpen();
  };

  const addDepartment = async () => {
    if (!totalNumber) alert("식수를 입력하세요");
    if (!zerowasteDefaultVolume) alert("기준배식량을 입력하세요");
    if (departmentName) {
      if (engDepartmentName) {
        if (type) {
          const data = {
            departmentName,
            departmentEngName: engDepartmentName,
            nutritionistName: nutritionist,
            type,
            educationOffice,
            sourceDepartmentId,
            totalNumber,
            zerowasteDefaultVolume,
            dailyTextType,
          };

          const res = await apiClient.post(`/api/department`, data);
          console.log(res.data);
          document.getElementById("department").value = "";
          document.getElementById("engDepartment").value = "";
          document.getElementById("nutritionist").value = "";
          document.getElementById("dailyTextType").value = "";
          document.getElementById("totalNumber").value = "";
          document.getElementById("zerowasteDefaultVolume").value = "";
          document.getElementById("type").selectedIndex = 0;
          document.getElementById("educationOffice").selectedIndex = 0;
          document.getElementById("sourceDepartmentName").selectedIndex = 0;
          setSourceDepartmentId(null);
          handleDepartmentName(null);
          handleEngDepartmentName(null);
          handleNutritionist(null);
          handleType(null);
          setEducationOffice(null);
          setDailyTextType(null);
          setTotalNumber(null);
          setZerowasteDefaultVolume(null);
          getJsonDepartmentList();

          const info = res.data.errorMessage;
          if (info) {
            alert(info);
          } else {
            alert("기관이 성공적으로 추가되었습니다");
            // handleDepartmentSettingsModalOpen(true);
            // handleDepartmentModalOpen(false);
          }
        } else {
          alert("기관타입을 선택하세요");
        }
      } else {
        alert("기관명(영어)을 입력하세요");
      }
    } else {
      alert("기관명을 입력하세요");
    }
  };

  useEffect(() => {
    getJsonDepartmentList();
  }, []);

  return (
    <DepartmentContainer>
      <CloseButton onClick={handleCloseButton}>x</CloseButton>
      <HeadingText>기관 추가</HeadingText>
      <MenuContainer>
        <MenuName>기관명</MenuName>
        <input
          style={{ width: "380px" }}
          id="department"
          onChange={handleDepartmentNameChange}
        ></input>

        <MenuName>기관명(영어)</MenuName>
        <input
          style={{ width: "380px" }}
          id="engDepartment"
          onChange={handleEngDepartmentNameChange}
        ></input>

        <MenuName>영양사명(선택)</MenuName>
        <input
          style={{ width: "380px" }}
          id="nutritionist"
          onChange={handleNutritionistChange}
        ></input>

        <MenuName>기관 Type</MenuName>
        <StyledSelect id="type" onChange={handleDepartmentType}>
          <StyledOption value="">Type </StyledOption>
          {departmentType.map((departmentType, index) => (
            <StyledOption key={index} value={departmentType.name}>
              {departmentType.name}
            </StyledOption>
          ))}
        </StyledSelect>

        <MenuName>교육청 Type (학교인 경우에만)</MenuName>
        <StyledSelect
          id="educationOffice"
          onChange={handleEducationOffice}
          disabled={type !== "학교"}
        >
          <StyledOption value="">Type </StyledOption>
          {educationOfficeTypeOptions.map((educationOffice, index) => (
            <StyledOption key={index} value={educationOffice}>
              {educationOffice}
            </StyledOption>
          ))}
        </StyledSelect>

        <MenuName>총 식수</MenuName>
        <input
          style={{ width: "380px" }}
          id="totalNumber"
          type="number"
          onChange={handleTotalNumber}
        ></input>

        <MenuName>기준배식량</MenuName>
        <input
          style={{ width: "380px" }}
          id="zerowasteDefaultVolume"
          type="number"
          onChange={handleZerowasteDefaultVolume}
        ></input>

        <MenuName>daily text type</MenuName>
        <input
          style={{ width: "380px" }}
          id="dailyTextType"
          onChange={handleDailyTextType}
        ></input>
      </MenuContainer>
      <input
        type="radio"
        name="isChecked"
        onClick={handleNewRadioButton}
        value="new"
      />
      기본 설정값 입력하기 <br />
      <input
        type="radio"
        onClick={handleOtherRadioButton}
        name="isChecked"
        value="other"
      />
      타 기관 설정값 불러오기
      {isChecked ? (
        <div>
          설정 값을 불러올 기관을 선택하세요:
          <StyledSelect
            disabled
            id="sourceDepartmentName"
            onChange={handleSourceDepartmentId}
          >
            <StyledOption id="sourceDepartmentName" value="">
              기관을 선택하세요
            </StyledOption>
            {jsonDepartmentList &&
              jsonDepartmentList?.sort().map((departmentList, index) => (
                <StyledOption key={index} value={departmentList.id}>
                  {departmentList.name}
                </StyledOption>
              ))}
          </StyledSelect>
        </div>
      ) : (
        <div>
          설정 값을 불러올 기관을 선택하세요:
          <StyledSelect
            id="sourceDepartmentName"
            onChange={handleSourceDepartmentId}
          >
            <StyledOption id="sourceDepartmentName" value="">
              기관을 선택하세요
            </StyledOption>
            {jsonDepartmentList &&
              jsonDepartmentList?.sort().map((departmentList, index) => (
                <StyledOption key={index} value={departmentList.id}>
                  {departmentList.name}
                </StyledOption>
              ))}
          </StyledSelect>
        </div>
      )}
      <StyledButton onClick={addDepartment}>기관 추가하기</StyledButton>
    </DepartmentContainer>
  );
};

export default AddDepartmentModal;

const HeadingText = styled.h1`
  text-align: center;
`;

const CloseButton = styled.button`
  margin-left: 350px;
  width: 30px;
`;

const StyledButton = styled.button`
  margin-top: 50px;
  margin-left: 230px;
`;

const DepartmentContainer = styled.div`
  padding: 5px;
`;

const StyledSelect = styled.select`
  width: 380px;
  height: 30px;
  border-radius: 5px;
  font-weight: 500;
  background-color: white;
  margin-bottom: 5px;
`;
const StyledOption = styled.option`
  width: 100%;
`;
const MenuContainer = styled.div`
  justify-content: center;
  border-bottom: 5px solid lightgray;
`;

const MenuName = styled.h2``;
