import React, { useEffect } from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";

import * as actionTypes from "data/recommendation/actionTypes";
import SelectBox1 from "./SelectBox/SelectBox1";
import SelectBox2 from "./SelectBox/SelectBox2";
import SelectBox3 from "./SelectBox/SelectBox3";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Calendar from "./Calendar/Calendar";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Link } from "react-router-dom";
import classes from "./index.module.css";
import { initConfig } from "data/recommendation/actions/user";

const StyledBox = styled.div`
  background: #ffffff;
  // color: #white;
  // font-size: 25px !important;
  // font-family: "NanumGothicBold", cursive;
  // margin: 0 auto;
  // text-align: left bottom;
  vertical-align: bottom;
  padding-bottom: 250px;
  z-index: 100;
  height: 100px;
`;

const InfoBox = (props) => {
  const userSession = props.userSession;
  let userEmail = null;
  if (userSession) {
    userEmail = userSession.emailAddress;
  }

  let emailAddressConverted = userEmail.replace("@", "at");
  const dotIndex = emailAddressConverted.indexOf(".");
  emailAddressConverted = emailAddressConverted.slice(0, dotIndex);

  async function initialize(emailAddressConverted) {
    if (!props.initialized) {
      await props.onUpdateUserEmail(emailAddressConverted);
      await props.onUpdateUserId(1);
      await props.onInitConfig();
      await props.onUpdateInitialized(true);
    }
  }

  useEffect(() => {
    if (userEmail) {
      initialize(emailAddressConverted);
    }
  }, [userEmail]);

  if (!userSession) {
    return null;
  }

  const currentYear = props.year;
  const currentMonth = props.month;
  const currentWeek = Number(props.week) + 1;
  let yearMonthWeek = `${currentYear}년 ${currentMonth}월`;
  let selectBox1 = "";

  if (props.monthOrWeek === "week") {
    yearMonthWeek = `${currentYear}년 ${currentMonth}월 ${currentWeek}주차`;
    selectBox1 = <SelectBox1 />;
  }

  const afilliation = {
    elem: "초등학교",
    middle: "중학교",
    high: "고등학교",
    goverment: "공공기관",
    company: "회사",
    other: "기타",
  };

  const targetCalories = getTargetCalories(props.config.target_calories);
  const mealPrice = getMealPrice(props.config.meal_price);

  return (
    <div>
      <Calendar show={props.showCalendar} />
      <StyledBox>
        <ThemeProvider
          theme={{
            palette: {
              blue: "#4b77d4",
              gray: "#495057",
              pink: "#f06595",
            },
          }}
        ></ThemeProvider>
        <h1>
          <div className={classes.InfoBoxCommon3}>
            <div
              style={{
                position: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <span>
                <DateRangeIcon
                  style={{
                    fontSize: "48px",
                    position: "relative",
                    zIndex: "100",
                    top: "8px",
                  }}
                  onClick={() => props.onShowCalendar()}
                />
                {yearMonthWeek}
              </span>
            </div>
            <div>{selectBox1}</div>
            <div>
              <SelectBox2
                updateMonthOrWeek={props.updateMonthOrWeek}
                MonthOrWeek={props.MonthOrWeek}
              />
            </div>
            <div>
              <SelectBox3 />
            </div>
          </div>
          <div className={classes.InfoBoxCommon}>
            <span className={classes.InfoBoxCommon2}>
              {afilliation[props.config.affiliation_type]}{" "}
            </span>
            <span className={classes.InfoBoxCommon2}>월~금 </span>
            <span className={classes.InfoBoxCommon2}>{mealPrice} </span>
            <span className={classes.InfoBoxCommon2}>
              1식 {Number(props.config.sidedish_num) + 1}찬
            </span>
            <span className={classes.InfoBoxCommon2}>
              디저트 {Number(props.config.dessert_num)}개
            </span>
            <span className={classes.InfoBoxCommon2}>{targetCalories}</span>
            <Link to="/config">
              <SettingsOutlinedIcon
                style={{
                  fontSize: "40px",
                  position: "relative",
                  top: "11px",
                  color: "#d3d3d3",
                }}
              />
            </Link>
          </div>
        </h1>
      </StyledBox>
    </div>
  );
};

const getTargetCalories = (target_calories) => {
  let targetCalories = "";
  if (target_calories === "free") {
    targetCalories = "칼로리 제한 없음";
  } else {
    targetCalories = `${Number(target_calories) - 100} ~ ${
      Number(target_calories) + 100
    } KCAL`;
  }
  return targetCalories;
};

const getMealPrice = (meal_price) => {
  let mealPrice = "";
  if (meal_price === "free") {
    mealPrice = "가격 제한 없음";
  } else {
    mealPrice = `${Number(meal_price) - 500} ~ ${Number(meal_price) + 500} 원`;
  }
  return mealPrice;
};

const mapStateToProps = (state) => {
  return {
    currentYear: state.recommend.calendar.year,
    currentMonth: state.recommend.calendar.month,
    currentWeek: state.recommend.calendar.week,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    week: state.recommend.calendar.week,
    showCalendar: state.recommend.calendar.showCalendar,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    config: state.recommend.user.config,
    userSession: state.user.userSession,
    initialized: state.recommend.user.initialized,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onIncreaseWeek: (currentWeek) =>
      dispatch({
        type: actionTypes.INCREASE_WEEK,
        week: currentWeek,
      }),

    onIncreaseLastWeek: () =>
      dispatch({
        type: actionTypes.INCREASE_LAST_WEEK,
      }),

    onDecreaseWeek: (currentWeek) =>
      dispatch({
        type: actionTypes.DECREASE_WEEK,
        week: currentWeek,
      }),
    onDecreaseFirstWeek: () =>
      dispatch({
        type: actionTypes.DECREASE_FIRST_WEEK,
      }),

    onShowCalendar: () =>
      dispatch({
        type: actionTypes.UPDATE_SHOW_CALENDAR,
      }),
    onInitConfig: () => dispatch(initConfig()),
    onUpdateUserEmail: (userEmail) =>
      dispatch({
        type: actionTypes.UPDATE_USER_EMAIL,
        userEmail: userEmail,
      }),
    onUpdateUserId: (userId) =>
      dispatch({
        type: actionTypes.UPDATE_USER_ID,
        userId,
      }),
    onUpdateInitialized: (initialized) =>
      dispatch({
        type: actionTypes.UPDATE_INITIALIZED,
        initialized: initialized,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoBox);
