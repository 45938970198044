import { apiClient } from "../client";

export const api = {
  getWaitingAdmin: async () => {
    return await apiClient.get("/api/admin/user/waiting-admin");
  },
  getAdminList: async () => {
    return await apiClient.get("api/admin/user/admin-list");
  },
  deleteUser: async (userId) => {
    return await apiClient.delete(`/api/admin/user/${userId}`);
  },
  giveTempPassword: async (giveTempPasswordInfo) => {
    return await apiClient.put(
      `/api/admin/user/temp-password`,
      giveTempPasswordInfo
    );
  },

  updatePassword: async (updatePasswordInfo) => {
    return await apiClient.put(`/api/admin/user/password`, updatePasswordInfo);
  },
  toggleApproved: async (userId) => {
    return await apiClient.put(`/api/admin/user/approval/${userId}`);
  },
  whoAmI: async () => {
    return await apiClient.get("api/admin/user/me");
  },
  getUserList: async (payload) => {
    return await apiClient.post("/api/user/list", payload).catch((error) => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },
};
