import { useState, useEffect } from "react";
import api from "api";

export const useCornerList = () => {
  const [cornerList, setCornerList] = useState([]);

  useEffect(() => {
    api.departmentApi.getCornerList().then((res) => {
      const sortedList = res.data.sort(
        (a, b) => a.departmentId - b.departmentId
      );
      setCornerList(sortedList);
    });
  }, []);

  return cornerList;
};

export default useCornerList;
