import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

import { apiClient } from "api/client";

import styled from "styled-components";

import { useFormikContext } from "formik";

import { nuviSortFunction } from "pages/MasterPages/helper";

const CreateAndAddCustomFood = ({
  modalOpen,
  handleModalOpen,
  addToUpdatedList,
  setCreateMode,
}) => {
  const [keyword, setKeyword] = useState("");
  const [nuviSearchResult, setNuviSearchResult] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [newCustomFoodName, setNewCustomFoodName] = useState("");
  const [newCustomFoodRatio, setNewCustomFoodRatio] = useState(1);

  const { values } = useFormikContext();
  const departmentId = Number(values.departmentId);

  const handleNewCustomFoodName = (e) => {
    setNewCustomFoodName(e.target.value);
  };

  const handleNewCustomFoodRatio = (e) => {
    setNewCustomFoodRatio(e.target.value);
  };

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const searchNuviFood = async () => {
    const res = await apiClient.get(`/api/food/nuvi-food/list`, {
      params: {
        foodName: keyword,
      },
    });
    setNuviSearchResult(res.data);
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    } else {
      e.preventDefault();
      searchNuviFood();
    }
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const nuviPageCount = Math.ceil(nuviSearchResult.length / PER_PAGE);

  const currentPageNuviSearchResult = nuviSearchResult
    .sort(nuviSortFunction)
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer key={idx} onClick={() => setSelectedFood(el)}>
        <ResultRow>{el.foodName}</ResultRow>
        <ResultRow>{el.calories}</ResultRow>
        <ResultRow>{el.servingAmount}</ResultRow>
      </ResultRowContainer>
    ));

  const resetValues = async () => {
    document.getElementById("keyword").value = "";
    await setNuviSearchResult([]);
    await setKeyword("");
    await setNewCustomFoodRatio(1);
    await setNewCustomFoodName("");
  };

  const makeNewCustomFood = async () => {
    try {
      if (newCustomFoodName === "") {
        alert("음식명을 입력하세요");
        return;
      }
      const { id } = selectedFood;
      const data = {
        foodName: newCustomFoodName,
        nuviFoodId: id,
        nuviUserId: 1,
        departmentId,
      };

      const res = await apiClient.post(`/api/food/custom-food`, data);
      if (res.data.alreadyExists) {
        alert(res.data.message);
      } else {
        const createdCustomFood = res.data;
        const toBeAdded = {
          ...createdCustomFood,
          nuviCustomRatio: newCustomFoodRatio,
        };
        await addToUpdatedList(toBeAdded);
        await alert(`${newCustomFoodName}이/가 커스텀푸드에 추가되었습니다`);
        await resetValues();
        await handleModalOpen();
      }
    } catch (e) {
      alert(e.message);
    }
  };

  useEffect(() => {
    resetValues();
  }, [modalOpen]);

  return (
    <>
      <SearchBarContainer>
        <StyledTextInput
          id="keyword"
          type="text"
          onChange={handleKeyword}
          placeholder="추가할 음식을 검색하세요"
          onKeyPress={searchOnPressEnter}
        />
        <StyledButton
          id="searchButton"
          onClick={searchNuviFood}
          style={{
            margin: 0,
          }}
        >
          검색
        </StyledButton>
        <StyledButton
          id="createModeButton"
          onClick={() => setCreateMode(false)}
          style={{
            margin: 0,
            fontSize: "15px",
          }}
        >
          기존 custom food 사용해서 추가
        </StyledButton>
        <StyledButton
          id="createModeButton"
          onClick={() => setCreateMode(false)}
          style={{
            margin: 0,
            fontSize: "15px",
          }}
        >
          기존 custom food 사용해서 추가
        </StyledButton>
        <StyledButton
          id="createModeButton"
          onClick={() => setCreateMode(false)}
          style={{
            margin: 0,
            fontSize: "15px"
          }}
        >
          기존 custom food 사용해서 추가
        </StyledButton>
      </SearchBarContainer>

      {nuviSearchResult.length > 0 && (
        <>
          <ResultRowNameContainer>
            <ResultRow>음식명</ResultRow>
            <ResultRow>칼로리(kcal)</ResultRow>
            <ResultRow>제공량(g)</ResultRow>
          </ResultRowNameContainer>

          {currentPageNuviSearchResult}

          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={nuviPageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />

          {selectedFood !== null && (
            <AddCustomFoodContainer>
              <Text>정보를 입력하세요</Text>
              <InputInfoContainer>
                <InputContainer>
                  <Text>음식명</Text>
                  <StyledTextInput
                    id="customFoodName"
                    type="text"
                    placeholder={
                      selectedFood ? selectedFood.foodName : "커스텀푸드 이름"
                    }
                    style={{ width: "300px" }}
                    onChange={handleNewCustomFoodName}
                  />
                </InputContainer>
                <InputContainer>
                  <Text>비율</Text>
                  <StyledTextInput
                    id="customFoodRatio"
                    type="number"
                    defaultValue={1}
                    step="0.1"
                    min="0"
                    placeholder="ratio"
                    style={{ width: "300px" }}
                    onChange={handleNewCustomFoodRatio}
                  />
                </InputContainer>
                <InputContainer style={{ marginTop: "15px" }}>
                  <Text>칼로리</Text>
                  <Text style={{ width: "300px" }}>
                    {selectedFood
                      ? (selectedFood.calories * newCustomFoodRatio).toFixed(2)
                      : null}
                    kcal
                  </Text>
                </InputContainer>
                <InputContainer>
                  <Text>제공량</Text>
                  <Text style={{ width: "300px" }}>
                    {selectedFood
                      ? (
                          selectedFood.servingAmount * newCustomFoodRatio
                        ).toFixed(2)
                      : null}
                    g
                  </Text>
                </InputContainer>

                <StyledButton
                  type="button"
                  onClick={makeNewCustomFood}
                  style={{ marginTop: "20px" }}
                >
                  커스텀푸드 추가
                </StyledButton>
              </InputInfoContainer>
            </AddCustomFoodContainer>
          )}
        </>
      )}
    </>
  );
};

export default CreateAndAddCustomFood;

const ResultRowContainer = styled.div`
  width: 90%;
  background-color: white;

  display: flex;
  justify-content: space-around;
  padding: 2px;
  margin: 3px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 3px;
  font-size: 30px;
`;

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 70px;

  border-radius: 5px;
  margin: 5px;
`;

const StyledTextInput = styled.input`
  width: 600px;
  height: 50px;
  font-size: 30px;
`;

const StyledButton = styled.button`
  width: 250px;
  height: 50px;
  margin: 15px;
  font-size: 30px;
  cursor: pointer;
`;

const AddCustomFoodContainer = styled.div`
  width: 80%;
  border-top: 3px solid lightgray;
  padding: 15px;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  min-width: 150px;
  font-size: 30px;
  margin: 5px;
  text-align: center;
`;

const InputInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  width; 100%;
  margin: 5px;
`;
