import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import * as actionTypes from "../../../../data/recommendation/actions/actionTypes";

import FoodItem from "../FoodItem/FoodItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import axios from "../../../../data/recommendation/axios";

const styles = () => {
  return {
    cell1: {
      paddingBottom: "10px",
      textAlign: "center",
      backgroundColor: "#ffd600",
      width: "140px",
    },

    cell2: {
      padding: "0px",
      textAlign: "center",
      paddingBottom: "10px",
    },
  };
};

const initNutrition = (name, id) => {
  return {
    name: name,
    id: id,
    amount: 0,
    calcium_mg: 0,
    calories: 0,
    carbohydrate_g: 0,
    fat_g: 0,
    protein_g: 0,
    isEditMode: false,
  };
};

class NutritionTable extends Component {
  amountData = this.props.amountData;
  state = {
    nutritionInfo: [
      initNutrition("", "0"),
      initNutrition("", "1"),
      initNutrition("", "2"),
      initNutrition("", "3"),
      initNutrition("", "4"),
      initNutrition("", "5"),
      initNutrition("", "6"),
      initNutrition("", "7"),
      initNutrition("", "8"),
    ],
    isEditMode: true,
    previous: {},

    updateNutrition: (menuList) => {
      const currentMonth = this.props.month;
      const currentYear = this.props.year;
      const Identifier =
        String(currentYear) +
        "-" +
        String(currentMonth) +
        "-" +
        this.props.selectedLD;

      if (this.props.amountData[Identifier] === undefined) {
        return;
      }

      const dailyAmountData = this.props.amountData[Identifier][
        this.props.selectedDay
      ];

      let arrayData = [];
      let totalData = {};
      axios
        .post("nutrition", {
          menuList,
          amountList: dailyAmountData,
        })
        .then((response) => {
          let nutritionData = response.data;
          for (var i = 0; i < Object.keys(nutritionData).length; i++) {
            if (Object.keys(nutritionData)[i] !== "total") {
              const name = nutritionData[i]["name"];
              arrayData.push({
                ...nutritionData[i],
                amount: dailyAmountData[name],
                isEditMode: false,
              });
            } else {
              totalData = nutritionData["total"];
            }
          }

          this.setState({
            nutritionInfo: arrayData,
          });

          this.props.onUpdateTotalNutrition(totalData);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  };

  componentDidMount() {
    this.state.updateNutrition(this.props.menuList);
  }

  render() {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                textAlign: "center",
                backgroundColor: "#ffd600",
                width: "10px",
                height: "40px",
                paddingBottom: "12px",
              }}
            ></TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>음식명</b>
            </TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>기준량</b>
              <span style={{ fontSize: "10px" }}>(g)</span>
            </TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>열량</b>
              <span style={{ fontSize: "10px" }}>(kcal)</span>
            </TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>탄수화물</b>
              <span style={{ fontSize: "10px" }}>(g)</span>
            </TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>단백질</b>
              <span style={{ fontSize: "10px" }}>(g)</span>
            </TableCell>
            <TableCell className={classes.cell1} style={{ padding: "0px" }}>
              <b>지방</b>
              <span style={{ fontSize: "10px" }}>(g)</span>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <FoodItem
            nutritionInfo={this.state.nutritionInfo}
            updateNutrition={this.state.updateNutrition}
          />

          <TableRow>
            <TableCell
              style={{ padding: "0px", textAlign: "center" }}
            ></TableCell>
            <TableCell style={{ padding: "0px", textAlign: "center" }}>
              <b>총량</b>
            </TableCell>
            <TableCell className={classes.cell2}>
              <b>{this.props.totalNutrition.amount}</b>
            </TableCell>
            <TableCell className={classes.cell2}>
              <b>{this.props.totalNutrition.calories}</b>
            </TableCell>
            <TableCell className={classes.cell2}>
              <b>{this.props.totalNutrition.carbohydrate_g}</b>
            </TableCell>
            <TableCell className={classes.cell2}>
              <b>{this.props.totalNutrition.protein_g}</b>
            </TableCell>
            <TableCell className={classes.cell2}>
              <b>{this.props.totalNutrition.fat_g}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalNutrition: state.recommend.calendar.totalNutrition,
    amountData: state.recommend.user.amountData,
    selectedLD: state.recommend.selection.selectedLD,
    selectedDay: state.recommend.selection.selectedDay,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTotalNutrition: (totalNutrition) =>
      dispatch({
        type: actionTypes.UPDATE_TOTAL_NUTRITION,
        totalNutrition: totalNutrition,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NutritionTable));
