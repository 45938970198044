import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { apiClient } from "api/client";

import "react-checkbox-tree/lib/react-checkbox-tree.css";

const AddScannerModal = ({
  handleScannerModalOpen,
  getJsonScannerList,
  getJsonDepartmentList,
  jsonDepartmentList,
}) => {
  const [serialNumber, setSerialNumber] = useState("");
  const [sourceDepartmentId, setSourceDepartmentId] = useState("");
  const [sourceScannerId, setSourceScannerId] = useState("");
  const [targetDepartmentId, setTargetDepartmentId] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [jsonDepartmentListWithScanner, setJsonDepartmentListWithScanner] = useState("");
  const [scannerListfromDepartment, setScannerListfromDepartment] = useState("");
  const handleSerialNumberChange = (e) => {
    setSerialNumber(e.target.value);
  };
  const handleTargetDepartmentId = (e) => {
    setTargetDepartmentId(e.target.value);
  };
  const handleSourceDepartmentId = (e) => {
    setSourceDepartmentId(e.target.value);
  };
  const handleSourceScannerId = (e) => {
    setSourceScannerId(e.target.value);
  };
  const handleNewRadioButton = () => {
    setIsChecked(true);
  };

  const handleOtherRadioButton = () => {
    setIsChecked(false);
  };

  const handleCloseButton = () => {
    handleScannerModalOpen();
    getJsonScannerList();
    document.getElementById("targetDepartmentId").selectedIndex = 0;
    document.getElementById("serialNumber").value = "";
    document.getElementById("sourceDepartmentId").selectedIndex = 0;
    document.getElementById("sourceScannerId").selectedIndex = 0;
    setSerialNumber(null);
    setTargetDepartmentId(null);
    setSourceDepartmentId(null);
    setSourceScannerId(null);
  };

  const addScannerSettings = async () => {
    if (targetDepartmentId) {
      if (serialNumber) {
        const data = {
          serialNumber,
          targetDepartmentId,
          sourceScannerId,
          sourceDepartmentId,
        };
        const res = await apiClient.post(`/api/scanner`, data);

        if (res.data.errorMessage) {
          alert(res.data.errorMessage);
        } else {
          alert("스캐너가 추가되었습니다");
          getJsonScannerList();
          getJsonDepartmentListWithScanner();
          // console.log("추가된 스캐너",res.data);
          document.getElementById("targetDepartmentId").selectedIndex = 0;
          document.getElementById("serialNumber").value = "";
          document.getElementById("sourceDepartmentId").selectedIndex = 0;
          document.getElementById("sourceScannerId").selectedIndex = 0;
          setSourceDepartmentId(null);
          setSourceScannerId(null);
          setSerialNumber(null);
          setTargetDepartmentId(null);
        }
      } else {
        alert("시리얼번호를 입력하세요");
      }
    } else {
      alert("스캐너를 추가할 기관명을 선택하세요");
    }
  };

  const getScannerListfromDepartment = async () => {
    const data = {
      sourceDepartmentId,
    };
    const res = await apiClient.post(`/api/scanner/list`, data);
    setScannerListfromDepartment(res.data.findScanner);
    // console.log(res.data.findScanner);
  };

  const getJsonDepartmentListWithScanner = async () => {
    const res = await apiClient.get(`/api/scanner/json/list`);
    setJsonDepartmentListWithScanner(res.data[0].children);
    // console.log(res.data[0].children);
  };

  useEffect(() => {
    getJsonDepartmentList();
    getJsonDepartmentListWithScanner();
  }, []);

  useEffect(() => {
    getScannerListfromDepartment();
  }, [sourceDepartmentId]);

  return (
    <ScannerContainer>
      <CloseButton onClick={handleCloseButton}>x</CloseButton>
      <MenuContainer>
        <HeadingText>스캐너 추가</HeadingText>
        스캐너를 추가할 기관명:
        <StyledSelect
          id="targetDepartmentId"
          onChange={handleTargetDepartmentId}
        >
          <StyledOption value="">기관명을 선택하세요 </StyledOption>
          {jsonDepartmentList &&
            jsonDepartmentList?.map((departmentList, index) => (
              <StyledOption key={index} value={departmentList.id}>
                {departmentList.name}
              </StyledOption>
            ))}
        </StyledSelect>
        <MenuName>SerialNo:</MenuName>
        <input
          style={{width:"380px"}}
          id="serialNumber"
          placeholder="SerialNo"
          onChange={handleSerialNumberChange}
        ></input>
      </MenuContainer>
      <input
        type="radio"
        name="isChecked"
        onClick={handleNewRadioButton}
        value="new"
      />
      기본 설정값 입력하기 <br />
      <input
        type="radio"
        onClick={handleOtherRadioButton}
        name="isChecked"
        value="other"
      />
      다른 스캐너 설정값 불러오기
      <br />
      {isChecked ? (
        <div>
          <StyledSelect
            disabled
            id="sourceDepartmentId"
            onChange={handleSourceDepartmentId}
          >
            <StyledOption value="">기관명을 선택하세요 </StyledOption>
            {jsonDepartmentListWithScanner &&
              jsonDepartmentListWithScanner?.map((departmentList, index) => (
                <StyledOption key={index} value={departmentList.value}>
                  {departmentList.label}
                </StyledOption>
              ))}
          </StyledSelect>
          <StyledSelect
            disabled
            id="sourceScannerId"
            onChange={handleSourceScannerId}
          >
            <StyledOption value="">스캐너를 선택하세요 </StyledOption>
            {scannerListfromDepartment &&
              scannerListfromDepartment?.map(
                (scannerListfromDepartment, index) => (
                  <StyledOption
                    key={index}
                    value={scannerListfromDepartment.id}
                  >
                    {scannerListfromDepartment.serialNumber}
                  </StyledOption>
                )
              )}
          </StyledSelect>
        </div>
      ) : (
        <div>
          <StyledSelect
            id="sourceDepartmentId"
            onChange={handleSourceDepartmentId}
          >
            <StyledOption value="">기관명을 선택하세요 </StyledOption>
            {jsonDepartmentListWithScanner &&
              jsonDepartmentListWithScanner?.map((departmentList, index) => (
                <StyledOption key={index} value={departmentList.value}>
                  {departmentList.label}
                </StyledOption>
              ))}
          </StyledSelect>
          <StyledSelect id="sourceScannerId" onChange={handleSourceScannerId}>
            <StyledOption value="">스캐너를 선택하세요 </StyledOption>
            {scannerListfromDepartment &&
              scannerListfromDepartment?.map(
                (scannerListfromDepartment, index) => (
                  <StyledOption
                    key={index}
                    value={scannerListfromDepartment.id}
                  >
                    {scannerListfromDepartment.serialNumber}
                  </StyledOption>
                )
              )}
          </StyledSelect>
        </div>
      )}
      <StyledButton onClick={addScannerSettings}>스캐너 추가하기</StyledButton>
    </ScannerContainer>
  );
};

export default AddScannerModal;

const ScannerContainer = styled.div`
  padding: 5px;
`;

const HeadingText = styled.h1`
  text-align: center;
`;

const CloseButton = styled.button`
  margin-left: 350px;
  width: 30px;
`;

const StyledButton = styled.button`
  margin-top: 30px;
  margin-left: 230px;
`;

const StyledSelect = styled.select`
  width: 380px;
  height: 30px;
  border-radius: 5px;
  font-weight: 500;
  background-color: white;
  margin-bottom: 5px;
`;
const StyledOption = styled.option`
  width: 100%;
`;
const MenuContainer = styled.div`
  border: 1px solid white;
  justify-content: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 5px solid lightgray;
`;

const MenuName = styled.h2``;
