import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Field, useFormikContext } from "formik";

import {
  useNuviFoodMainCategories,
  useNuviFoodSubCategories,
  useCornerList,
} from "hooks";

import styled from "styled-components";

import { apiClient } from "api/client";

import {
  Title,
  SearchBarContainer,
  SearchOptionContainer,
} from "pages/MasterPages/StyledComps";
import { FormatListNumberedOutlined } from "@material-ui/icons";

const BLD_OPTIONS = [
  { id: "B", name: "조식" },
  { id: "L", name: "중식" },
  { id: "D", name: "석식" },
];

const ANSWER_OPTIONS = [
  { id: "O", name: "O" },
  { id: "X", name: "X" },
];

const fieldStyle = {
  width: "170px",
  height: "40px",
  fontSize: "20px",
  borderRadius: "5px",
  border: "1px solid black",
};

export default function SearchBox({
  searchMode,
  departmentList,
  toggleSearchMode,
  handleCustomSearchResult,
  handleNuviSearchResult,
}) {
  const history = useHistory();

  const [keyword, setKeyword] = useState("");
  const [nuviFoodMainCategoryId, setNuviFoodMainCategoryId] = useState(null);
  const [nuviFoodSubCategoryId, setNuviFoodSubCategoryId] = useState(null);

  const searchInputRef = useRef();
  const { values, setFieldValue } = useFormikContext();

  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();
  const cornerList = useCornerList();

  const { departmentId, cornerId, date, bld } = values;

  const cornerSelectIsDisabled = useMemo(() => !values.departmentId, [values]);

  const cornerFilterFunction = (corner) => {
    const isMyCorner = corner.departmentId === Number(departmentId);
    return isMyCorner;
  };

  const handleDepartmentName = (depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  };

  const departmentName = useMemo(
    () => handleDepartmentName(Number(departmentId)),
    [departmentId]
  );

  const getMenulist = async () => {
    const res = await apiClient.get(`/api/menu/single`, {
      params: {
        bld,
        departmentId,
        date,
        cornerId,
      },
    });

    console.log('## resdata: ', res.data);

    if (res.data) {
      if (
        window.confirm(
          "이미 작성된 메뉴가 있습니다. 기존 메뉴를 수정하시겠습니까?"
        )
      ) {
        history.push({
          pathname: "/editMenu",
          state: {
            departmentId,
            date,
            bld,
            cornerId,
          },
        });
      }
    }
  };

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleNuviFoodMainCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodMainCategoryId(value);
  };
  const handleNuviFoodSubCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodSubCategoryId(value);
  };

  const searchNuviFood = async (keyword) => {
    const res = await apiClient.get(`/api/food/nuvi-food/list`, {
      params: {
        foodName: keyword,
        departmentId,
        nuviFoodMainCategoryId,
        nuviFoodSubCategoryId,
      },
    });
    handleCustomSearchResult(res.data);
  };

  const searchKeyword = () => {
    searchNuviFood(keyword);
    searchInputRef.current.value = "";
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      // e.preventDefault();
      return;
    } else {
      e.preventDefault();
      searchKeyword();
    }
  };

  useEffect(() => {
    if (departmentId !== "" && bld !== "") {
      getMenulist();
    }
    setFieldValue("customFoodList", []);
  }, [bld, departmentId, date, cornerId]);

  return (
    <SearchContainer>
      <Title>
        메뉴기본정보{" "}
        {departmentId === "" || bld === "" ? (
          <SubTitle style={{ color: "red" }}>
            기관명, 날짜, BLD를 선택해주세요
          </SubTitle>
        ) : (
          <SubTitle>OK!</SubTitle>
        )}{" "}
      </Title>

      <SearchOptionContainer>
        <SelectContainer>
          <FieldLabel>기관명</FieldLabel>

          <Field as="select" name="departmentId" style={fieldStyle}>
            <option value=""> 기관 선택 </option>
            {departmentList.map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.name}
              </option>
            ))}
          </Field>
        </SelectContainer>

        <SelectContainer>
          <FieldLabel>코너명</FieldLabel>

          <Field
            as="select"
            name="cornerId"
            style={fieldStyle}
            disabled={cornerSelectIsDisabled}
          >
            <option value=""> 코너 선택 </option>
            {cornerList.filter(cornerFilterFunction).map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.cornerName === "default"
                  ? departmentName + " 기본코너"
                  : el.cornerName}
              </option>
            ))}
          </Field>
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>날짜</FieldLabel>

          <Field name="date" type="date" style={fieldStyle} />
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>BLD</FieldLabel>

          <Field as="select" name="bld" style={fieldStyle}>
            <option value=""> 선택 </option>
            {BLD_OPTIONS.map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.name}
              </option>
            ))}
          </Field>
        </SelectContainer>
      </SearchOptionContainer>
      <SearchOptionContainer>
        <SelectContainer>
          <FieldLabel>코멘트</FieldLabel>
          <Field name="menuComment" style={{ ...fieldStyle, width: "90%" }} />
        </SelectContainer>
      </SearchOptionContainer>
      <SearchOptionContainer>
        <SelectContainer>
          <FieldLabel>퀴즈</FieldLabel>
          <Field name="quiz" style={{ ...fieldStyle, width: "100%" }} />
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>정답</FieldLabel>
          <Field as="select" name="answer" style={fieldStyle}>
            <option value=""> 선택 </option>
            {ANSWER_OPTIONS.map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.name}
              </option>
            ))}
          </Field>
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>해설</FieldLabel>
          <Field name="quizComment" style={{ ...fieldStyle, width: "100%" }} />
        </SelectContainer>
      </SearchOptionContainer>

      {departmentId !== "" && bld !== "" && (
        <>
          <Title
            style={{
              paddingTop: "20px",
            }}
          >
            음식 검색
            <SubTitle>추가할 음식을 검색해주세요</SubTitle>
          </Title>

          <SearchBarContainer
            style={{
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <StyledSelect onChange={handleNuviFoodMainCategoryId}>
              <option value="">없음</option>
              {nuviFoodMainCategories.map(({ id, categoryName }) => (
                <option value={id}>{categoryName}</option>
              ))}
            </StyledSelect>

            <StyledSelect onChange={handleNuviFoodSubCategoryId}>
              <option value="">없음</option>
              {nuviFoodSubCategories
                .filter(
                  (subCategory) =>
                    subCategory.nuviFoodMainCategoryId ===
                    Number(nuviFoodMainCategoryId)
                )
                .map(({ id, categoryName }) => (
                  <option value={id}>{categoryName}</option>
                ))}
            </StyledSelect>

            <StyledTextInput
              id="searchInput"
              type="text"
              onChange={handleKeyword}
              ref={searchInputRef}
              placeholder={`음식명을 입력하세요`}
              defaultValue={null}
              onKeyPress={searchOnPressEnter}
            />
            <StyledButton
              id="searchButton"
              type="button"
              onClick={searchKeyword}
            >
              검색
            </StyledButton>
          </SearchBarContainer>
        </>
      )}
    </SearchContainer>
  );
}

const SearchContainer = styled.div`
  flex: 1;
  padding: 5px;
  padding-top: 15px;
  background-color: none;
  border-bottom: 3px solid lightgray;
`;

const StyledButton = styled.button`
  width: 150px;
  height: 35px;
  margin: 20px;
  font-size: 20px;
  cursor: pointer;
`;

const SelectContainer = styled.div`
  background-color: none;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
`;

const StyledTextInput = styled.input`
  width: 300px;
  height: 35px;
  border-radius: 5px;
`;

const StyledSelect = styled.select`
  width: 120px;
  height: 35px;
`;

const SubTitle = styled.span`
  width: 100%;
  background-color: none;
  font-size: 25px;
  font-weight: 300;
  padding: 10px;
  color: green;
`;

const FieldLabel = styled.div`
  display: flex;
  width: 100px;
  height: 45px;
  font-size: 20px;
  border-radius: 5px;
  background-color: none;
  margin: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
