import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import configureStore from "./data/configureStore";
import "./normalize.css";
import "./index.css";
import App from "./App";
import colors from "theme/colors";

const { store, history } = configureStore();
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={colors}>
        <App history={history} />
      </ThemeProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);
