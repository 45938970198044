import React from "react";
import { Formik, Form, Field, useField } from "formik";
import { object, string, number } from "yup";

import styled from "styled-components";

import {
  useDepartmentList,
  useNuviFoodMainCategories,
  useNuviFoodSubCategories,
  useFoodGroups,
} from "hooks";

import { apiClient } from "api/client";

const BLD_OPTIONS = [
  { label: "조식", value: "B" },
  { label: "중식", value: "L" },
  { label: "석식", value: "D" },
];

const initialValues = {
  source: "",
  foodName: "",
  servingAmount: 0,
  calories: 0,
  carbohydrate_g: 0,
  protein_g: 0,
  fat_g: 0,
  sodium_mg: 0,
  nuviFoodMainCategoryId: "",
  nuviFoodSubCategoryId: "",
  foodGroupId: "",
  departmentId: "",
  date: "",
  bld: "",
};

const validationSchema = object({
  foodName: string().required(),
  departmentId: string().required(),
  date: string().required(),
  bld: string().required(),
  servingAmount: number(),
  source: string(),
  calories: number(),
  carbohydrate_g: number(),
  protein_g: number(),
  fat_g: number(),
  sodium_mg: number(),
  nuviFoodMainCategoryId: string(),
  nuviFoodSubCategoryId: string(),
});

const resetOptionalValues = () => {
  document.getElementById("vitamin_a_ug_re").value = "";
  document.getElementById("vitamin_b1_mg").value = "";
  document.getElementById("vitamin_b2_mg").value = "";
  document.getElementById("vitamin_c_mg").value = "";
  document.getElementById("calcium_mg").value = "";
  document.getElementById("iron_mg").value = "";
};

const CreateNewNuviFood = () => {
  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();
  const departmentList = useDepartmentList();
  const foodGroups = useFoodGroups();

  const makeNewNuviFood = async (newNuviFood, { resetForm }) => {
    try {
      const { foodName, carbohydrate_g, protein_g, fat_g } = newNuviFood;
      console.log('## new nuvifood: ', newNuviFood)
      if (carbohydrate_g && protein_g && fat_g) {
        newNuviFood.calories = carbohydrate_g * 4 + protein_g * 4 + fat_g * 9;
        newNuviFood.foodName = foodName.trim();

      }

      if (window.confirm("누비푸드에 추가하시겠습니까?")) {
        const res = await apiClient.post(`/api/food/nuvi-food`, newNuviFood);
        alert("누비푸드에 추가되었습니다");
        resetForm({});
        resetOptionalValues();
      }
    } catch (e) {
      alert(e.message);
    }
  };

  return (
    <Maker>
      <MenuTitle>새로 추가하기</MenuTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={makeNewNuviFood}
        validationSchema={validationSchema}
      >
        {({ values, setFieldvalue }) => (
          <StyledForm>
            <OptionalContainer>

              <ElementContainer>
                <RequiredInputLabel>*누비푸드이름</RequiredInputLabel>
                <StyledTextInput name="foodName" placeholder="" />
              </ElementContainer>

              <ElementContainer>
                <RequiredInputLabel>*기관</RequiredInputLabel>
                <Field
                  as="select"
                  name="departmentId"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <option value={null}> 선택 </option>
                  {departmentList.map((department, idx) => (
                    <option key={idx} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </Field>
              </ElementContainer>

              <ElementContainer>
                <RequiredInputLabel>*일자</RequiredInputLabel>
                <Field
                  type="date"
                  name="date"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                    border: '1px solid gray'
                  }}
                />
              </ElementContainer>

              <ElementContainer>
                <RequiredInputLabel>*BLD</RequiredInputLabel>
                <Field
                  as="select"
                  name="bld"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <option value={null}> 선택 </option>
                  {BLD_OPTIONS.map((option, idx) => (
                    <option key={idx} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
              </ElementContainer>


              <ElementContainer>
                <InputLabel>제공량(g)</InputLabel>
                <StyledTextInput
                  type="number"
                  min="0"
                  name="servingAmount"
                  placeholder=""
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>출처</InputLabel>
                <StyledTextInput name="source" placeholder="" />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>칼로리(kcal)</InputLabel>
                <InputLabel>
                  {values.carbohydrate_g * 4 +
                    values.protein_g * 4 +
                    values.fat_g * 9}
                </InputLabel>
                <StyledTextInput type="hidden" min="1" name="calories" />
              </ElementContainer>
              <ElementContainer>
                <InputLabel>탄수화물(g)</InputLabel>
                <StyledTextInput
                  type="number"
                  min="0"
                  name="carbohydrate_g"
                  placeholder=""
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>단백질(g)</InputLabel>
                <StyledTextInput
                  type="number"
                  min="0"
                  name="protein_g"
                  placeholder=""
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>지방(g)</InputLabel>
                <StyledTextInput
                  type="number"
                  min="0"
                  name="fat_g"
                  placeholder=""
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>나트륨(mg)</InputLabel>
                <StyledTextInput
                  type="number"
                  min="0"
                  name="sodium_mg"
                  placeholder=""
                  step="any"
                />
              </ElementContainer>


            </OptionalContainer>


            <OptionalContainer>
              <ElementContainer>
                <InputLabel>누비랩 대분류</InputLabel>
                <Field
                  as="select"
                  name="nuviFoodMainCategoryId"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <option value={null}> 선택 </option>
                  {nuviFoodMainCategories.map((el, idx) => (
                    <option key={idx} value={el.id}>
                      {el.categoryName}
                    </option>
                  ))}
                </Field>
              </ElementContainer>
              <ElementContainer>
                <InputLabel>누비랩 중분류</InputLabel>
                <Field
                  as="select"
                  name="nuviFoodSubCategoryId"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <option value={null}> 선택 </option>
                  {nuviFoodSubCategories
                    .filter(
                      // 대분류에 속하는 중분류만 표출
                      (subCategory) =>
                        subCategory.nuviFoodMainCategoryId ===
                        Number(values.nuviFoodMainCategoryId)
                    )
                    .map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.categoryName}
                      </option>
                    ))}
                </Field>
              </ElementContainer>
              <ElementContainer>
                <InputLabel>식품군</InputLabel>
                <Field
                  as="select"
                  name="foodGroupId"
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <option value={""}> 없음 </option>
                  {foodGroups.map((el, idx) => (
                    <option key={idx} value={el.id}>
                      {el.groupName}
                    </option>
                  ))}
                </Field>
              </ElementContainer>

              <ElementContainer>
                <InputLabel>비타민 A(ug RE)</InputLabel>
                <StyledTextInput
                  id="vitamin_a_ug_re"
                  name="vitamin_a_ug_re"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>비타민 B1(mg)</InputLabel>
                <StyledTextInput
                  id="vitamin_b1_mg"
                  name="vitamin_b1_mg"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>비타민 b2(mg)</InputLabel>
                <StyledTextInput
                  id="vitamin_b2_mg"
                  name="vitamin_b2_mg"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>비타민 c(mg)</InputLabel>
                <StyledTextInput
                  id="vitamin_c_mg"
                  name="vitamin_c_mg"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>칼슘(mg)</InputLabel>
                <StyledTextInput
                  id="calcium_mg"
                  name="calcium_mg"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ElementContainer>
                <InputLabel>철분(mg)</InputLabel>
                <StyledTextInput
                  id="iron_mg"
                  name="iron_mg"
                  placeholder=""
                  defaultValue={null}
                  step="any"
                />
              </ElementContainer>

              <ButtonContainer>
                <StyledButton type="submit">누비푸드에 추가하기</StyledButton>
              </ButtonContainer>
            </OptionalContainer>
          </StyledForm>
        )}
      </Formik>
    </Maker>
  );
};

const TextInput = (props) => {
  const [field, meta, helpers] = useField(props);
  return (
    <InputContainer>
      <StyledInput {...field} {...props} />
    </InputContainer>
  );
};

export default CreateNewNuviFood;

const StyledTextInput = styled(TextInput)`
  width: 300px;
  height: 30px;
  border: 1px solid black;
  margin-top: 5px;
`;

const RequiredContainer = styled.div`
  width: 100%;
  background-color: none;
  justify-content: center;
  margin: 5px;
  border: 2px dashed gray;
  border-radius: 5px;
  flex: 1;
  padding: 10px;
`;

const OptionalContainer = styled.div`
  flex: 1;
  width: 100%;
  background-color: none;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  border-radius: 5px;
  width: 300px;
  height: 30px;
`;

const ElementContainer = styled.div`
  width: 100%;
  background-color: none;
  padding: 6px;
`;

const Maker = styled.div`
  flex: 1;
  min-height: 80vh;
  background-color: none;
  display: flex;
  flex-direction: column;
  border-left: 3px solid lightgray;
  padding-left: 20px;
`;

const StyledForm = styled(Form)`
  background-color: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  width: 90%;
  gap: 20px;
`;

const InputLabel = styled.div`
  width: 100%;
  font-size: 18px;

`;

const RequiredInputLabel = styled.div`
  width: 100%;
  font-size: 18px;
  color: red;
`;

const MenuTitle = styled.div`
  width: 100%;
  background-color: none;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled(ElementContainer)`
  display: flex;
  margin-top: 20px;
`;

const StyledButton = styled.button`
  width: 300px;
  height: 35px;
  align-self: center;
  margin 1px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;

const MenuName = styled.div`
  width: 100%;
  font-size: 22px;
  padding: 5px;
`;
