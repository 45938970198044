import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GetWeekTable from "./GetWeekTable";
import GetCommands from "./GetCommands";
import GetMonthTable from "./GetMonthTable";
import { GetMonthCommands } from "./GetMonthTable/helper";
import classes from "../index.module.css";
import GetSummary from "../Summary/Summary";
import { Spinner } from "../Spinner";
import { initMonthData, initConfig } from "data/recommendation/actions/user";

const GetTable = (props) => {
  const showLD = props.showLD;
  const [initialized, setInitialized] = useState(false);
  async function initMonthDataHelper(props) {
    await props.onInitConfig();
    await props.onInitMonthData();
    setInitialized(true);
    console.log("initialized!");
  }

  useEffect(() => {
    console.log(props.displayingDays);

    if (Object.keys(props.displayingDays).length > 0) {
      initMonthDataHelper(props);
      console.log("start...");
    }
  }, [props.displayingDays, showLD]);

  const [dayToMenuWeeklyLunch, setDayToMenuWeeklyLunch] = useState({});
  const [dayToMenuWeeklyDinner, setDayToMenuWeeklyDinner] = useState({});
  const [dayToMenuMonth, setDayToMenuMonth] = useState({});
  const year = props.year;
  const month = props.month;
  const updateDayToMenu = (displayingDays, monthData, year, month, showLD) => {
    const [newDayToMenuLunch, newDayToMenuDinner] = getDayToMenu_(
      displayingDays,
      monthData,
      year,
      month
    );
    setDayToMenuWeeklyLunch(newDayToMenuLunch);
    setDayToMenuWeeklyDinner(newDayToMenuDinner);
    const newDayToMenuMonth = getDayToMenuMonth(year, month, showLD, monthData);
    setDayToMenuMonth(newDayToMenuMonth);
    setInitialized(false);
  };
  useEffect(() => {
    updateDayToMenu(
      props.displayingDays,
      props.monthData,
      year,
      month,
      props.showLD
    );
  }, [initialized, props.modifyState, props.loading]);

  const getDayToMenuMonth = (year, month, showLD, monthData) => {
    const Identifier = `${year}-${month}-${showLD === "D" ? "D" : "L"}`;

    let dayToMenuMonth = {};
    if (monthData && Object.keys(monthData).includes(Identifier)) {
      dayToMenuMonth = monthData[Identifier];
    }

    return dayToMenuMonth;
  };

  const commandListMonth = GetMonthCommands(month, year);
  const commandList = GetCommands(month, year, props.week);

  let Summary = "";
  if (props.selectedDay !== 0 && props.modifyState) {
    Summary = <GetSummary />;
  }

  if (props.loading === "recommending") {
    return Spinner("식단을 생성 중입니다");
  }

  if (props.loading === "saving") {
    return Spinner("식단을 저장 중입니다");
  }

  if (props.loading === "submitfile") {
    return Spinner("파일을 제출 중입니다");
  }
  let table = "";
  switch (props.monthOrWeek) {
    case "week":
      table = (
        <div className={classes.TableAndSummary_week}>
          <GetWeekTable
            commandList={commandList}
            dayToMenuLunch={dayToMenuWeeklyLunch}
            dayToMenuDinner={dayToMenuWeeklyDinner}
          />
          {Summary}
        </div>
      );
      return table;
    case "month":
      table = (
        <div className={classes.TableAndSummary_month}>
          <GetMonthTable
            commandList={commandListMonth}
            dayToMenu={dayToMenuMonth}
          />
          {Summary}
        </div>
      );
      return table;
    default:
      return table;
  }
};

const getIdentifiers = (year, month) => {
  const [Identifier1, Identifier2] = [
    String(year) + "-" + String(month) + "-" + "L",
    String(year) + "-" + String(month) + "-" + "D",
  ];
  return [Identifier1, Identifier2];
};

const getDayToMenu_ = (displayingDays, monthData, year, month) => {
  const [Identifier1, Identifier2] = getIdentifiers(year, month);
  const dayToMenuLunch = {};
  const dayToMenuDinner = {};
  const days = Object.values(displayingDays);
  if (displayingDays) {
    for (var i = 0; i < days.length; i++) {
      if (
        monthData[Identifier1] &&
        monthData[Identifier2] &&
        (days[i] in monthData[Identifier1] || days[i] in monthData[Identifier2])
      ) {
        dayToMenuLunch[days[i]] = monthData[Identifier1][days[i]];
        dayToMenuDinner[days[i]] = monthData[Identifier2][days[i]];
      }
    }
  }

  return [dayToMenuLunch, dayToMenuDinner];
};

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    monthData: state.recommend.user.monthData,
    modifyState: state.recommend.selection.modifyState,
    showLD: state.recommend.calendar.showLD,
    selectedLD: state.recommend.selection.selectedLD,
    selectedDay: state.recommend.selection.selectedDay,
    selectedDays: state.recommend.selection.selectedDays,
    displayingDays: state.recommend.selection.displayingDays,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    totalCalories: state.recommend.calendar.totalCalories,
    loading: state.recommend.selection.loading,
    userEmail: state.recommend.user.userEmail,
    initialized: state.recommend.user.initialized,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInitMonthData: () => dispatch(initMonthData()),
    onInitConfig: () => dispatch(initConfig()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetTable);
