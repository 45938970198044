import { useState, useEffect } from "react";
import api from "api";

export const useFoodMainCategories = () => {
  const [foodMainCategories, setFoodMainCategories] = useState([]);

  useEffect(() => {
    api.foodApi.getFoodMainCategories().then((res) => {
      setFoodMainCategories(res.data);
    });
  }, []);

  return foodMainCategories;
};

export const useFoodSubCategories = () => {
  const [foodSubCategories, setFoodSubCategories] = useState([]);

  useEffect(() => {
    api.foodApi.getFoodSubCategories().then((res) => {
      setFoodSubCategories(res.data);
    });
  }, []);

  return foodSubCategories;
};

export const useNuviFoodMainCategories = () => {
  const [nuviFoodMainCategories, setNuviFoodMainCategories] = useState([]);

  useEffect(() => {
    api.foodApi.getNuviFoodMainCategories().then((res) => {
      setNuviFoodMainCategories(res.data);
    });
  }, []);

  return nuviFoodMainCategories;
};

export const useNuviFoodSubCategories = () => {
  const [nuviFoodSubCategories, setNuviFoodSubCategories] = useState([]);

  useEffect(() => {
    api.foodApi.getNuviFoodSubCategories().then((res) => {
      setNuviFoodSubCategories(res.data);
    });
  }, []);

  return nuviFoodSubCategories;
};

export const useFoodGroups = () => {
  const [foodGroups, setFoodGroups] = useState([]);

  useEffect(() => {
    api.foodApi.getFoodGroups().then((res) => {
      setFoodGroups(res.data);
    });
  }, []);

  return foodGroups;
};
