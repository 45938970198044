import * as actionTypes from "../actionTypes";

const initialState = {
  highlight: [],
  highlightState: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_HIGHLIGHT:
      return {
        ...state,
        highlight: action.highlight,
      };

    case actionTypes.UPDATE_HIGHLIGHT_STATE:
      return {
        ...state,
        highlightState: !state.highlightState,
      };

    default:
      return state;
  }
};
