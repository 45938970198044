import "./index.css";
import { connect } from "react-redux";

const GetHtmlScript = (monthval, year, week) => {
  let getHtml = "";

  let month = "0" + String(monthval);
  month = month.slice(-2);

  const day = new Date(year, month - 1, 1);
  //이번 달의 첫째 날을 구합니다.
  const firstDayName = day.getDay();

  //이번 달의 마지막 날을 구합니다.
  const lastDay = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();
  //지난 달의 마지막 날을 구합니다.
  const prevLastDay = new Date(day.getFullYear(), day.getMonth(), 0).getDate();

  //매월 일수가 달라지므로 이번 달 날짜 개수를 세기 위한 변수를 만들고 초기화 합니다.
  let lastDayCount = 1;
  let i = Number(week);

  if (i === 0) {
    let startDay = 1;
    for (let j = 0; j < 6; j++) {
      if (j < firstDayName) {
        if (j === 0) {
          // getHtml += `block2(${prevLastDay}, ${firstDayName}, ${j})|`;
        } else if (j === 6) {
          getHtml += `block1()|`;
        } else {
          getHtml += `block1(${prevLastDay + j - firstDayName + 1})|`;
        }
      } else if (j === firstDayName) {
        if (j === 0) {
          // getHtml += `block1()|`;
          startDay += 1;
        } else if (j === 6) {
          getHtml += `block1()|`;
          startDay += 1;
        } else {
          getHtml += `mlock1(${startDay}, dayMenu)|`;
          startDay += 1;
        }
      } else if (j > firstDayName) {
        if (j === 0) {
          // getHtml += `block1()|`;
          startDay += 1;
        } else if (j === 6) {
          getHtml += `block1()|`;
          startDay += 1;
        } else {
          getHtml += `mlock1(${startDay}, dayMenu)|`;
          startDay += 1;
        }
      }
    }
  } else {
    let startDay = 7 * i - firstDayName + 1;
    for (let j = 0; j < 6; j++) {
      if (i > 0 && startDay <= lastDay) {
        if (j === 0) {
          startDay += 1;
        } else if (j === 6) {
          getHtml += `block1()|`;
          startDay += 1;
        } else {
          getHtml += `mlock1(${startDay},  dayMenu)|`;
          startDay += 1;
        }
      } else if (startDay > lastDay) {
        if (j === 0) {
          lastDayCount += 1;
        } else if (j === 6) {
          getHtml += `block1()|`;
          lastDayCount += 1;
        } else {
          getHtml += `block1()|`;
          lastDayCount += 1;
        }
      }
    }
  }

  //월요일~금요일을 위해 5번 반복합니다.
  return getHtml;
};

const GetCommands = (tables, year, week) => {
  let calendarData = "";
  calendarData = GetHtmlScript(tables, year, week);
  // }

  if (calendarData === "") {
    return [];
  } else {
    const functionList = calendarData.split("|").map((x) => x.slice(0, 6));
    const inputList = calendarData
      .split("|")
      .map((x) => x.slice(6).split(",").map(String));

    let commandList = functionList.map((x, idx) => x + inputList[idx]);

    commandList = commandList.filter(function (el) {
      return el != "";
    });
    return commandList;
  }
};

export default GetCommands;
