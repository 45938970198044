import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";

const activeStyle = {
  width: "300px",
  color: "black",
  fontWeight: "bold",
  textDecoration: "none"
};

const NavigationBar = ({ options }) => {
  return (
    <NavigationContainer>
      <ButtonContainer>
        {options.map((option, idx) => (
          <StyledNavLink key={idx} to={option.to} activeStyle={activeStyle}>
            {option.name}
          </StyledNavLink>
        ))}
      </ButtonContainer>
    </NavigationContainer>
  );
};

export default NavigationBar;

const NavigationContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 50px;
  background-color: none;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  margin-top: 20px;
  border-bottom: 3px solid lightgray;
`;

const ButtonContainer = styled.div`
  width: 400px;
  background-color: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledNavLink = styled(NavLink)`
  width: 300px;
  font-size: 30px;
  text-decoration: none;
  font-weight: 300;
`;
