import React, { useEffect, useState, forwardRef } from "react";
import styled from "styled-components";
import { Formik } from "formik";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "data";

import { dashboardColor } from "theme/colors";
import { BorderedBox } from "components/Styled";

import MenuCalorieInput from "./MenuCalorieInput";

let imgSrc = process.env.PUBLIC_URL + "/dashboard/menu.svg";

const sampleItems = [
  { id: 0, food_name: "배추김치", calorie: 0 },
  { id: 1, food_name: "단무지 무침", calorie: 0 },
  { id: 2, food_name: "맛살 초무침", calorie: 0 },
  { id: 3, food_name: "생선까스", calorie: 0 },
  { id: 4, food_name: "현미밥", calorie: 0 },
  { id: 5, food_name: "오징어 무국", calorie: 0 },
];

// 밥, 국, 주찬, 부찬, 김치, 기타 순서대로 대시보드 내 표기
const sortFunction = (a, b) => {
  const aMainCategoryId = a.foodMainCategoryId;
  const bMainCategoryId = b.foodMainCategoryId;

  const rice = [10];
  const soup = [3, 19, 20];
  const main = [2, 6, 8, 11, 21, 22, 23];
  const sub = [9, 16, 17, 18];
  const kimchi = [5];
  // const etc = [1, 4, 7, 12, 13, 14, 15];

  const setCategoryValue = (mainCategoryId) => {
    if (rice.includes(mainCategoryId)) {
      return 0;
    }
    if (soup.includes(mainCategoryId)) {
      return 1;
    }
    if (main.includes(mainCategoryId)) {
      return 2;
    }
    if (sub.includes(mainCategoryId)) {
      return 3;
    }
    if (kimchi.includes(mainCategoryId)) {
      return 4;
    } else {
      return 5;
    }
  };

  return setCategoryValue(aMainCategoryId) - setCategoryValue(bMainCategoryId);
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: "flex",
  padding: grid,
  overflow: "auto",
});

const MenuItem = ({
  idx,
  item,
  isInEditMode,
  calorieHandler,
  isInFakeView,
  provided,
  snapshot,
  showSodium,
}) => {
  const [value, setValue] = useState(Math.round(item.calories));
  calorieHandler(idx, value);

  return (
    <MenuItemContainer
      key={item.name}
      showSodium={showSodium}
      natrium={item.sodium_mg}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
    >
      <MenuItemName>{item.foodName}</MenuItemName>
      <CalorieContainer>
        {isInEditMode ? (
          <MenuCalorieInput
            name={item.id}
            onChange={(e) => setValue(Number(e.target.value))}
          />
        ) : (
          <MenuCalorie>
            {isInFakeView ? value : Math.round(item.calories)}
          </MenuCalorie>
        )}
        <MenuCalorieUnit>Kcal</MenuCalorieUnit>
      </CalorieContainer>
    </MenuItemContainer>
  );
};

const MenuForm = ({
  isInEditMode,
  formik,
  menuList,
  menuImgSrc,
  menuPreviewSrc,
  calorieHandler,
  isInFakeView,
  showSodium,
}) => {
  const [foodlist, setFoodlist] = useState([]);

  if (menuImgSrc || menuPreviewSrc) {
    menuPreviewSrc ? (imgSrc = menuPreviewSrc) : (imgSrc = menuImgSrc);
  }

  useEffect(() => {
    if (!isInEditMode) {
      //수정된 값 날리는 api
      let sum = 0;
      for (let k in formik.values) {
        sum += Number(formik.values[k]);
      }
    }
  }, [isInEditMode]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      foodlist,
      result.source.index,
      result.destination.index
    );

    setFoodlist(items);
  };

  useEffect(() => {
    if (menuList) {
      setFoodlist(menuList.foodList.slice().sort(sortFunction));
    }
  }, [menuList]);

  return (
    <MenuContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        {menuList ? (
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {foodlist.map((el, idx) => (
                  <Draggable
                    key={el.id.toString()}
                    draggableId={el.id.toString()}
                    index={idx}
                  >
                    {(provided, snapshot) => (
                      <MenuItem
                        provided={provided}
                        snapshot={snapshot}
                        idx={idx}
                        item={el}
                        isInEditMode={isInEditMode}
                        calorieHandler={calorieHandler}
                        isInFakeView={isInFakeView}
                        showSodium={showSodium}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        ) : (
          <MenuRow>
            <NoMenuInfoContainer>금일 메뉴 정보가 없습니다</NoMenuInfoContainer>
          </MenuRow>
        )}
      </DragDropContext>

      {menuList && <MenuImage src={imgSrc} alt="menu" />}

      <BottomContainer>
        <SodiumContainer>
          {showSodium && menuList && (
            <Information>
              1일 <b>나트륨 권장량</b> 50% 이상 메뉴
            </Information>
          )}
        </SodiumContainer>

        <NuviLabelConatiner>
          <NuviLabel style={{ color: "gray", fontWeight: 400 }}>
            together with
          </NuviLabel>
          <NuviLabel> Nuvilabs</NuviLabel>
        </NuviLabelConatiner>
      </BottomContainer>
    </MenuContainer>
  );
};

export default function MenuComp({
  calorieHandler,
  isInFakeView,
  isInEditMode,
  showSodium,
}) {
  const menuList = useSelector(selectors.dashboard.getMenuList);
  const menuImgUrl = useSelector(selectors.dashboard.getMenuImageSrc);
  const menuPreviewSrc = useSelector(selectors.dashboard.getMenuPreviewSrc);

  return (
    <Formik
      initialValues={{
        first: sampleItems[0].calorie,
        second: sampleItems[1].calorie,
        third: sampleItems[2].calorie,
        fourth: sampleItems[3].calorie,
        fifth: sampleItems[4].calorie,
        sixth: sampleItems[5].calorie,
      }}
    >
      {(formik) => (
        <MenuForm
          isInEditMode={isInEditMode}
          formik={formik}
          menuList={menuList}
          menuImgSrc={menuImgUrl}
          menuPreviewSrc={menuPreviewSrc}
          calorieHandler={calorieHandler}
          isInFakeView={isInFakeView}
          isInEditMode={isInEditMode}
          showSodium={showSodium}
        />
      )}
    </Formik>
  );
}

const MenuContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;

  background-color: none;
  width: 100%;
  @media (max-width: 1120px) {
    flex-direction: column;
  }
`;

const MenuRow = styled.div`
  display: flex;
  justify-content: space-around;

  background-color: none;
`;

const MenuItemContainer = styled(BorderedBox)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0.5%;
  margin-top: 15px;
  border: ${({ natrium, showSodium }) =>
    showSodium && natrium > 1000
      ? `solid 3px ${dashboardColor.orange}`
      : "none"};

  background-color: none;
`;

const NoMenuInfoContainer = styled(BorderedBox)`
  width: 50%;
  min-width: 300px;
  display: flex;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  flex-direction: column;
  margin: 0;
  margin-top: 15px;
  border: ${({ natrium }) =>
    natrium > 1000 ? `solid 3px ${dashboardColor.orange}` : "none"};

  @media (max-width: 1120px) {
    width: 50%;
    flex-direction: column;
    font-size: 1rem;
  }
`;

const MenuItemName = styled.div`
  font-weight: 450;
  font-size: 1.5rem;
  width: 100%;

  text-overflow: ellipsis;
  text-align: center;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

const CalorieContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
const MenuCalorie = styled.div`
  font-weight: 700;
  font-size: 3rem;
  color: ${dashboardColor.blue};
  margin-right: 10px;
  margin-top: 2px;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 1rem;
  }
`;

const MenuCalorieUnit = styled.div`
  font-size: 1.5rem;
  font-wedith: 300;
  color: rgba(43, 136, 240, 0.7);
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

const MenuImage = styled.img`
  width: 55%;
  align-self: center;
  border-radius: 5%;
  @media (max-width: 1120px) {
    width: 50%;
  }
`;

const BottomContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  background-color: none;
  display: flex;
  align-content: start;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 1rem;
  }
`;
const SodiumContainer = styled.div`
  width: 100%;
  @media (max-width: 1120px) {
    width: 50%;
    font-size: 1rem;
    flex: 2.5;
  }
`;
const Information = styled.div`
  align-self: flex-start;
  border-radius: 10px;
  border: solid 3px ${dashboardColor.orange};
  padding: 3px 10px;
  margin: 20px;
  padding: 10px;
  width: 40%;
  text-align: center;
  font-size: 2rem;
  @media (max-width: 1120px) {
    font-size: 1rem;
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
`;

const NuviLabelConatiner = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: 23px;
  color: ${dashboardColor.gray_8};
  font-weight: 300;
  align-items: center;
  background-color: none;
  position: absolute;
  right: 2%;
  bottom: 3%;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 1rem;
    position: static;
    margin-left: 20px;
    align-items: right;
    flex: 1;
  }
`;

const NuviLabel = styled.div`
  margin: 5px;
  font-weight: bold;
  font-size: 1.8rem;
  color: ${dashboardColor.blue};
  @media (max-width: 1120px) {
    font-size: 1rem;
  }
`;
