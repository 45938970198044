import React, { useState } from "react";
import styled from "styled-components";

import { Modal, MasterNavigation } from "components";
import DropDown from "components/DropDown";
import Colors from "theme/colors";

const menu = "/icons/menuButton.svg";
const tabs = [
  // {
  //   name: "사용자 관리",
  //   tabs: [
  //     { name: "신청내역", route: "/adminApply" },
  //     { name: "관리자리스트", route: "/adminList" }
  //   ]
  // },
  {
    name: "메뉴 관리",
    tabs: [
      { name: "메뉴 조회/수정", route: "/editMenu" },
      { name: "메뉴 입력", route: "/makeMenu" },
    ],
  },
  {
    name: "음식 관리",
    tabs: [{ name: "누비푸드 관리", route: "/nuviFoodInfo" }],
  },
  {
    name: "스캐너 관리",
    tabs: [{ name: "스캐너 관리", route: "/scannerManagement" }],
  },
];

export default function MasterLayout({ children, master }) {
  const [sideBarOpen, setSidebarOpen] = useState(false);

  const handleMenuClick = () => {
    setSidebarOpen(!sideBarOpen);
  };

  const dropDownClickHandler = () => {
    setSidebarOpen(false);
  };

  if (master) {
    return (
      <Layout>
        <MasterNavigation
          tabs={tabs}
          style={{ gridColumn: `1/2` }}
          sideBarOpen={sideBarOpen}
          handleMenuClick={handleMenuClick}
        />
        {sideBarOpen && <DropDown onClick={dropDownClickHandler} />}
        <MenuWrapper>
          <MenuButton src={menu} alt={"menu"} onClick={handleMenuClick} />
        </MenuWrapper>
        <Contents>{children}</Contents>
        <Modal />
      </Layout>
    );
  } else {
    return <div>권한이 없습니다</div>;
  }
}

const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.gray_2};

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const MenuWrapper = styled.div`
  width: 30px;
  flex: 0 0 30px;
  margin-top: 10px;
  background-color: none;
`;

const MenuButton = styled.img`
  position: fixed;
  width: 30px;
  margin: 5px;
  align-self: flex-start;
  cursor: pointer;
`;

const Contents = styled.div`
  padding: 10px;
  flex: 1 1;
  @media (min-width: 1200px) {
  }
`;
