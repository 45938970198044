import { apiClient } from "../client";

export const api = {
  getFoodMainCategories: async () => {
    return await apiClient.get("/api/food/main-categories").catch((error) => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },

  getFoodSubCategories: async () => {
    return await apiClient.get("/api/food/sub-categories").catch((error) => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },

  getNuviFoodMainCategories: async () => {
    const { data } = await apiClient
      .get("/api/admin/food/nuvi-main-categories")
      .catch((error) => {
        console.log("error.message", error.message);
        throw new Error(error.message);
      });
    return data;
  },

  getNuviFoodSubCategories: async () => {
    const { data } = await apiClient
      .get("/api/admin/food/nuvi-sub-categories")
      .catch((error) => {
        console.log("error.message", error.message);
        throw new Error(error.message);
      });

    return data;
  },

  getFoodGroups: async () => {
    return await apiClient.get("/api/food/groups").catch((error) => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },
};
