import React from "react";
import { useSelector } from "react-redux";

import { selectors } from "data";
import { BorderedBox } from "components/Styled";

import styled from "styled-components";
import moment from "moment";

import { dashboardColor } from "theme/colors";
import { awsPhotoUrl } from "config";

export default function TitleBoxComp({ date }) {
  const user = useSelector(selectors.user.getUserSession);
  const logoUrl = user.Department.logo;

  return (
    <TitleBox>
      <Title>오늘의 급식</Title>
      <DateLabel>{moment(date).format("YYYY.M.D")}</DateLabel>
      <DepartmentLogo src={logoUrl} alt="logo" />
    </TitleBox>
  );
}

//이부분이 오늘의 급식 표출되는 부분
const TitleBox = styled(BorderedBox)`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px 0 80px 0px;
  padding: 30px;
  align-items: center;
  background-color: none;
  border-radius: 5%;
  width: 100%;

  @media (max-width: 1120px) {
    width: 100%;
    flex-direction: column;
    margin: 0px;
    text-align: center;
    padding: 0px;
    margin-top: 10px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 3.3rem;
  color: ${dashboardColor.blue};
  opacity: 0.7;
  margin-top: 20px;

  @media (max-width: 1120px) {
    width: 100%;
    flex-direction: column;
    font-size: 1.5rem;
    margin-top: 0px;
    text-align: center;
  }
`;

const DateLabel = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 2rem;
  color: ${dashboardColor.gray_6};
  margin: 10px;
  background-color: none;
  @media (max-width: 1120px) {
    width: 100%;
    flex-direction: column;
    font-size: 1.5rem;
  }
`;

const DepartmentLogo = styled.img`
  margin: 15px;
  padding: 2px;
  width: 100%;
  max-width: 10vw;
  flex: 1;
  @media (max-width: 1120px) {
    padding: 0px;
    margin: 0px;
  }
`;
