import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { object, string, array } from "yup";

import { apiClient } from "api/client";

import styled from "styled-components";
import moment from "moment";

import { useDepartmentList } from "hooks";

import SearchBox from "./SearchBox";
import MiniDashBox from "./MiniDashBox";
import ResultBox from "./ResultBox";
import NavigationBar from "../NavigationBar";
import AddNuviFoodModal from "pages/MasterPages/AddNuviFoodModal";

import {
  Container,
  StyledForm,
  InnerContainer,
  LeftContainer,
} from "pages/MasterPages/StyledComps";

const initialValues = {
  departmentId: "",
  cornerId: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
  bld: "L",
  customFoodList: [],
  menuComment: "",
  quiz: "",
  answer: "",
  quizComment: "",
};

const validationSchema = object({
  departmentId: string().required(),
  date: string().required(),
  bld: string().required(),
  customFoodList: array().min(1),
});

const navigationOptions = [
  { name: "메뉴 조회/수정", to: "/editMenu" },
  { name: "메뉴 입력", to: "/makeMenu" },
];

export default function MakeMenu(props) {
  const departmentList = useDepartmentList();

  const [modalOpen, setModalOpen] = useState(false);
  const [searchMode, setSearchMode] = useState("customFood");
  const [nuviSearchResult, setNuviSearchResult] = useState([]);
  const [customSearchResult, setCustomSearchResult] = useState([]);

  const handleDepartmentLabel = (departmentId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === Number(departmentId)) {
        return departmentList[i].name;
      }
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      const { departmentId, date, bld } = values;
      const message = `${handleDepartmentLabel(
        departmentId
      )} ${date}, ${bld} 메뉴를 추가하시겠습니까?`;

      if (window.confirm(message)) {
        createMenu(values);
        resetForm({});
        setCustomSearchResult([]);
        setNuviSearchResult([]);
      }
    } catch (e) {
      alert(e.message);
    }
  };

  const createMenu = async (values) => {
    try {
      const res = await apiClient.post(`/api/menu/list`, values);
      alert(res.data.message);
    } catch (e) {
      alert(e.message);
    }
  };

  const toggleSearchMode = (e) => {
    setSearchMode(e.target.value);
  };

  const handleCustomSearchResult = (result) => {
    setCustomSearchResult(result);
  };

  const handleNuviSearchResult = (result) => {
    setNuviSearchResult(result);
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    modalOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "visible");
  }, [modalOpen]);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <StyledForm>
            <NavigationBar options={navigationOptions} />
            <InnerContainer>
              <LeftContainer>
                <SearchBox
                  searchMode={searchMode}
                  departmentList={departmentList}
                  toggleSearchMode={toggleSearchMode}
                  handleCustomSearchResult={handleCustomSearchResult}
                  handleNuviSearchResult={handleNuviSearchResult}
                  customSearchResult={customSearchResult}
                  nuviSearchResult={nuviSearchResult}
                  handleModalOpen={handleModalOpen}
                />
                {values.departmentId !== "" && values.bld !== "" && (
                  <ResultBox
                    searchMode={searchMode}
                    customSearchResult={customSearchResult}
                    nuviSearchResult={nuviSearchResult}
                    handleModalOpen={handleModalOpen}
                    handleCustomSearchResult={handleCustomSearchResult}
                    handleNuviSearchResult={handleNuviSearchResult}
                  />
                )}
              </LeftContainer>
              <MiniDashBox departmentList={departmentList} />
            </InnerContainer>
          </StyledForm>
        )}
      </Formik>

      <ModalWrapper visible={modalOpen}>
        <ModalInner>
          <AddNuviFoodModal
            modalOpen={modalOpen}
            handleModalOpen={handleModalOpen}
          />
        </ModalInner>
      </ModalWrapper>
    </Container>
  );
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 1600px;
  height: 1000px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: white;
  border: 3px solid black;
  font-size: 0.8rem;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
