import { apiClient } from "../client";

export const api = {
  getIndividualPoint: async () => {
    return await apiClient.get(`/api/point/individual`).catch(error => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },
  updatePoint: async payload => {
    return await apiClient.patch("/api/point/pt", payload).catch(error => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },
  getSKUserParticipation: async payload => {
    return await apiClient
      .get("/api/point/sk-user-participation", payload)
      .catch(error => {
        console.log("error.message", error.message);
        throw new Error(error.message);
      });
  }
};