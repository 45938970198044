import { apiClient } from "../client";

export const api = {
  getMenuList: async ({ bld, startDate, endDate }) => {
    return await apiClient.get(
      `/api/menu/list?&bld=${bld}&startDate=${startDate}&endDate=${endDate}`
    );
  },
  setMenuImage: async payload => {
    const menuListId = payload.menuListId;
    const file = payload.formData;

    // dpeartmentId 필요 없음 (서버 API 확인)
    return await apiClient.post(
      `/api/menu/menu-image/dashboard?menuListId=${menuListId}`,
      file
    );
  },
  deleteMenuImage: async payload => {
    return await apiClient.delete(`/api/menu/menu-image/dashboard`, {
      data: payload
    });
  }
};
