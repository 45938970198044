import { put, call } from "redux-saga/effects";
import { actions } from "data";
import api from "api";

import { DASHBOARD_ONLY } from "constants/index";

export function* loginTraditional(action) {
  try {
    yield put(actions.user.loginLoading());
    const { userLoginInfo } = action;

    const res = yield api.authApi.loginTraditional(userLoginInfo);
    const userData = res.data;
    const departmentId = userData.departmentId;

    yield put(actions.user.setUserSession(userData));
    if (userData.id === "master") {
      yield put(actions.router.push("/adminApply"));
      return;
    }

    if (DASHBOARD_ONLY.includes(departmentId)) {
      yield put(actions.router.push("/dashboard"));
    } else {
      yield put(actions.router.push("/daily"));
    }
    yield put(actions.user.loginSuccess(userData));
  } catch (e) {
    if (e.status || (e.response.status && e.response.status !== 500)) {
      yield put(
        actions.user.loginFailure({ message: e.response.data.message })
      );
    } else {
      yield put(actions.router.push("/500"));
    }
  }
}

export function* loginSocial(action) {
  try {
    yield put(actions.user.loginLoading());
    const { googleToken } = action.userLoginInfo;

    const res = yield api.authApi.loginSocial({ googleToken });

    const user = res.data;
    if (user.type) {
      //가입되어있으면 그대로 로그인
      yield put(actions.user.setUserSession(user));
      // setAuthCookie(user.token);
      yield put(actions.user.loginSuccess(user));

      if (user.Department.name === "강릉시청" || "aT") {
        yield put(actions.router.push("/dashboard"));
      } else {
        yield put(actions.router.push("/daily"));
      }
    } else {
      //가입이 안되어있으면 추가정보 기입
      yield put(actions.user.loginSuccess(user));
      yield put(actions.user.toggleAuthMode("registerSocial"));
      return;
    }
  } catch (e) {
    console.log("e.name", e.name);
    if (e.status || (e.status && e.status !== 500)) {
      yield put(actions.user.loginFailure({ message: e.message }));
    } else {
      yield put(actions.router.push("/500"));
    }
  }
}

export function* logout(action) {
  try {
    yield put(actions.user.resetAuth());
    yield api.authApi.logout();
    yield put(actions.router.push("/"));
  } catch (e) {
    console.log("e.message", e.message);
  }
}

export function* register(action) {
  try {
    const { userRegisterInfo } = action;
    const { type } = userRegisterInfo;

    let res;
    yield put(actions.user.registerLoading());

    if (type === "social") {
      res = yield api.authApi.registerSocial(userRegisterInfo);
    } else {
      res = yield api.authApi.registerTraditional(userRegisterInfo);
    }
    const userData = res.data;
    yield put(actions.user.registerSuccess(userData));
    yield put(
      actions.modal.setModal({
        modalType: "APPLY_REGISTER_MODAL",
        modalProps: {},
      })
    );
  } catch (e) {
    console.log("e.status", e.status);
    console.log("e.message", e.message);
    yield put(actions.user.registerFailure({ message: e.message }));
  }
}

export function* whoAmI(action) {
  try {
    yield put(actions.user.whoAmILoading());

    const res = yield call(api.userApi.whoAmI);
    const user = res.data;
    yield put(actions.user.setUserSession(user));
    yield put(actions.user.whoAmISuccess(user));
  } catch (e) {
    yield put(actions.user.whoAmIFailure({ message: e.message }));
  }
}

export function* giveTempPassword(action) {
  try {
    yield put(actions.user.giveTempPasswordLoading());

    const { tempPasswordInfo, onClick } = action.giveTempPasswordInfo;
    const { emailAddress, tempPassword } = tempPasswordInfo;
    yield api.userApi.giveTempPassword({ emailAddress, tempPassword });

    const res = yield api.mailApi.sendTempPassword(tempPasswordInfo);
    const { data } = res;
    yield put(actions.user.giveTempPasswordSuccess(data));
    yield put(
      actions.modal.setModal({
        modalType: "CONDITIONAL",
        modalProps: {
          onClick,
          contents: `${tempPasswordInfo.emailAddress} 수신함을 확인해 주세요.`,
          buttonName: "확인",
        },
      })
    );
  } catch (e) {
    yield put(actions.user.giveTempPasswordFailure({ message: e.message }));
    yield put(
      actions.modal.setModal({
        modalType: "CONDITIONAL",
        modalProps: {
          contents: `메일 전송에 실패하였습니다.`,
          buttonName: "확인",
        },
      })
    );
  }
}

export function* updatePassword(action) {
  try {
    const { updatePasswordInfo } = action;
    yield put(actions.user.updatePasswordLoading());
    yield api.userApi.updatePassword(updatePasswordInfo);
    yield put(actions.user.updatePasswordSuccess(true));
    yield put(actions.router.push("/profile"));
  } catch (e) {
    yield put(actions.user.updatePasswordFailure({ message: e.message }));
  }
}
