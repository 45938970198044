import { put, call } from "redux-saga/effects";

import { actions } from "data";
import api from "api";

export function* test(action) {
  try {
    console.log("test");
  } catch (e) {
    console.log(e.message);
  }
}

export function* getUserList(action) {
  try {
    const res = yield api.userApi.getUserList(action.payload);
    yield put(actions.point.setUserList(res.data));
  } catch (e) {
    console.log(e.message);
  }
}

export function* getIndividualPoint(action) {
  try {
    const res = yield api.pointApi.getIndividualPoint(action.payload);
    // data sort
    let array = [];
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].IndividualPoints.map(
        (el, idx) => (el.name = res.data[i].name)
      );
      array = array.concat(res.data[i].IndividualPoints);
    }
    yield put(actions.point.setIndividualPoint(array));
  } catch (e) {
    console.log(e.message);
  }
}

export function* updatePoint(action) {
  try {
    const res = yield api.pointApi.updatePoint(action.payload);
    if (res.data) {
      yield put(
        actions.modal.modalUpAndGo({
          contents: "포인트가 업데이트되었습니다."
        })
      );
      yield put(actions.point.setUserList([]));
    } else {
      yield put(
        actions.modal.modalUpAndGo({
          contents: "포인트가 부족합니다."
        })
      );
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* getSKUserParticipation(action) {
  try {
    const res = yield api.pointApi.getSKUserParticipation(action.payload);
    // yield put(actions.point.getSKUserParticipation(res.data))
  } catch (e) {
    console.log(e.message);
  }
}
