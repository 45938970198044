import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  useNuviFoodMainCategories,
  useNuviFoodSubCategories,
  useDepartmentList,
} from "hooks";

import ReactPaginate from "react-paginate";

import { apiClient } from "api/client";

import styled from "styled-components";

export default function FindAndAddNuviFood({
  modalOpen,
  handleModalOpen,
  addToUpdatedList,
  departmentId,
}) {
  const history = useHistory();
  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();
  const departmentList = useDepartmentList();

  const [keyword, setKeword] = useState("");
  const [customSearchResult, setCustomSearchResult] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);
  const [ratio, setRatio] = useState(1);

  const [nuviFoodMainCategoryId, setNuviFoodMainCategoryId] = useState(null);
  const [nuviFoodSubCategoryId, setNuviFoodSubCategoryId] = useState(null);

  const handleKeyword = (e) => {
    setKeword(e.target.value);
  };

  const handleRatio = (e) => {
    setRatio(e.target.value);
  };

  const handleNuviFoodMainCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodMainCategoryId(value);
  };
  const handleNuviFoodSubCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodSubCategoryId(value);
  };

  const searchNuviFood = async () => {
    const res = await apiClient.get(`/api/food/nuvi-food/list`, {
      params: {
        foodName: keyword,
        departmentId,
        nuviFoodMainCategoryId,
        nuviFoodSubCategoryId,
      },
    });
    setCustomSearchResult(res.data);
    if (!res.data[0]) {
      if (
        window.confirm(
          "검색결과가 없습니다. 새로운 누비푸드를 생성하시겠습니까?"
        )
      ) {
        history.push({
          pathname: "/nuviFoodInfo",
        });
      }
    }
  };

  const resetValues = () => {
    setSelectedFood(null);
    setCustomSearchResult([]);
    document.getElementById("keyword").value = "";
  };

  const handleAddFood = () => {
    if (window.confirm(`${selectedFood.foodName}을 추가하시겠습니까?`)) {
      const toBeAdded = {
        ...selectedFood,
        nuviCustomRatio: ratio,
      };
      addToUpdatedList(toBeAdded);
      handleModalOpen();
      resetValues();
    }
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    } else {
      e.preventDefault();
      searchNuviFood();
    }
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const customPageCount = Math.ceil(customSearchResult.length / PER_PAGE);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const handleDepartmentName = (depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  };

  const currentPageCustomSearchResult = customSearchResult
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer key={idx} onClick={() => setSelectedFood(el)}>
        <ResultRow style={{ flex: 1.5}}>{handleDepartmentName(el.departmentId)}</ResultRow>
        <ResultRow>{el.date}</ResultRow>
        <ResultRow>{el.bld}</ResultRow>
        <ResultRow style={{ flex: 2}}>{el.foodName}</ResultRow>
        <ResultRow>{el.source}</ResultRow>
        <ResultRow>{el.calories}</ResultRow>
        <ResultRow>{el.servingAmount}</ResultRow>
      </ResultRowContainer>
    ));

  useEffect(() => {
    resetValues();
  }, [modalOpen]);

  return (
    <React.Fragment>
      <BarContainer>
        <SearchBarContainer>
          <StyledSelect onChange={handleNuviFoodMainCategoryId}>
            <option value="">없음</option>
            {nuviFoodMainCategories.map(({ id, categoryName }) => (
              <option value={id}>{categoryName}</option>
            ))}
          </StyledSelect>

          <StyledSelect onChange={handleNuviFoodSubCategoryId}>
            <option value="">없음</option>
            {nuviFoodSubCategories
              .filter(
                (subCategory) =>
                  subCategory.nuviFoodMainCategoryId ===
                  Number(nuviFoodMainCategoryId)
              )
              .map(({ id, categoryName }) => (
                <option value={id}>{categoryName}</option>
              ))}
          </StyledSelect>

          <StyledTextInput
            width="300px"
            id="keyword"
            type="text"
            onChange={handleKeyword}
            placeholder="추가할 음식을 검색하세요!"
            onKeyPress={searchOnPressEnter}
          />
        </SearchBarContainer>

        <StyledButton id="searchButton" onClick={searchNuviFood}>
          검색
        </StyledButton>
      </BarContainer>
      {customSearchResult.length > 0 && (
        <>
          <ResultRowNameContainer>
            <ResultRow style={{ flex: 1.5}}>기관명</ResultRow>
            <ResultRow>일자</ResultRow>
            <ResultRow>BLD</ResultRow>
            <ResultRow style={{ flex: 2}}>음식명</ResultRow>
            <ResultRow>출처</ResultRow>
            <ResultRow>칼로리(kcal)</ResultRow>
            <ResultRow>제공량(g)</ResultRow>
          </ResultRowNameContainer>
          {currentPageCustomSearchResult}

          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={customPageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />

          {selectedFood !== null && (
            <>
              <ResultRowNameContainer>
                <ResultRow>음식명</ResultRow>
                <ResultRow>비율</ResultRow>
                <ResultRow>칼로리(kcal)</ResultRow>
                <ResultRow>제공량(g)</ResultRow>
              </ResultRowNameContainer>
              <ResultRowContainer
                style={{
                  marginBottom: "25px",
                }}
              >
                <ResultRow>{selectedFood.foodName}</ResultRow>
                <ResultRow>
                  <input
                    type="number"
                    onChange={handleRatio}
                    defaultValue={1}
                    step="0.1"
                    min="0"
                  />
                </ResultRow>
                <ResultRow>
                  {(selectedFood.calories * ratio).toFixed(2)}
                </ResultRow>
                <ResultRow>
                  {(selectedFood.servingAmount * ratio).toFixed(2)}
                </ResultRow>
              </ResultRowContainer>
              <StyledButton onClick={handleAddFood}>
                메뉴에 추가하기
              </StyledButton>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

const ResultRowContainer = styled.div`
  width: 95%;
  background-color: white;

  display: flex;
  justify-content: space-around;
  padding: 2px;
  margin: 3px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;

  &: {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 35px;
  text-align: center;
  padding: 2px;
  margin: 3px;
  font-size: 27px;
  flex: 1;
`;

const BarContainer = styled.div`
  width: 95%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 15px;
  border-bottom: 1px solid gray;
  margin-bottom: 20px;
`;

const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const StyledTextInput = styled.input`
  width: 500px;
  height: 50px;
  font-size: 30px;
`;

const StyledButton = styled.button`
  width: 250px;
  height: 50px;
  margin: 15px;
  font-size: 30px;
  cursor: pointer;
`;

const StyledSelect = styled.select`
  width: 120px;
  height: 50px;
`;
