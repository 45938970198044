import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducer";
import modal from "./modal/reducer";
import admins from "./admins/reducer";
import loading from "./loading/reducer";
import inventory from "./inventory/reducer";
import dashboard from "./dashboard/reducer";
import point from "./point/reducer";
import recommend from "./recommendation/reducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    user,
    modal,
    admins,
    loading,
    inventory,
    dashboard,
    point,
    recommend
  });

export { createRootReducer };
