import React from "react";
import styled from "styled-components";

import { useDepartmentList, useFoodGroups } from "hooks";
import ExcelExportButton from "./ExcelExportButton";

const ReadBox = ({ customFoodList, menuInfo }) => {
  const departmentList = useDepartmentList();
  const foodGroupList = useFoodGroups();
  const totalInfo = getMenuNutritionInfo(
    customFoodList,
    menuInfo,
    departmentList
  );
  const { comment, Quizzes } = menuInfo;
  let quizContent,
    answer,
    quizComment = null;

  if (Quizzes && Quizzes[0]) {
    quizContent = Quizzes[0].quizContent;
    answer = Quizzes[0].answer;
    quizComment = Quizzes[0].commentRight;
  }

  const handleFoodGroupName = (foodGroupId) => {
    for (let i = 0; i < foodGroupList.length; i++) {
      if (foodGroupList[i].id === foodGroupId) {
        return foodGroupList[i].groupName;
      }
    }
    return "없음";
  };

  const totalCalories = customFoodList.reduce(
    (a, c) => a + c.calories * c.nuviCustomRatio,
    0
  );
  const totalServingAmount = customFoodList.reduce(
    (a, c) => a + c.servingAmount * c.nuviCustomRatio,
    0
  );

  if (customFoodList.length > 0) {
    return (
      <Container>
        <ResultRowNameContainer>메뉴 코멘트</ResultRowNameContainer>
        <ResultRowContainer>
          <ResultRow>코멘트: </ResultRow>
          <ResultRow>{comment}</ResultRow>
        </ResultRowContainer>
        <ResultRowNameContainer>퀴즈</ResultRowNameContainer>

        <ResultRowContainer>
          <ResultRow>퀴즈: </ResultRow>
          <ResultRow>{quizContent}</ResultRow>
        </ResultRowContainer>
        <ResultRowContainer>
          <ResultRow>정답: </ResultRow>
          <ResultRow>{answer}</ResultRow>
        </ResultRowContainer>
        <ResultRowContainer>
          <ResultRow>해설: </ResultRow>
          <ResultRow>{quizComment}</ResultRow>
        </ResultRowContainer>

        <ResultRowNameContainer>
          <ResultRow>누비푸드ID</ResultRow>
          <ResultRow>누비푸드명</ResultRow>
          <ResultRow>비율</ResultRow>
          <ResultRow>칼로리(kcal)</ResultRow>
          <ResultRow>제공량</ResultRow>
          <ResultRow>식품군</ResultRow>
        </ResultRowNameContainer>

        {customFoodList.map((food, idx) => (
          <ResultRowContainer key={idx}>
            <ResultRow>{food.id}</ResultRow>
            <ResultRow>{food.foodName}</ResultRow>
            <ResultRow>{food.nuviCustomRatio}</ResultRow>
            <ResultRow>
              {(food.calories * food.nuviCustomRatio).toFixed(2)}
            </ResultRow>
            <ResultRow>
              {(food.servingAmount * food.nuviCustomRatio).toFixed(2)}
            </ResultRow>
            <ResultRow>{handleFoodGroupName(food.foodGroupId)}</ResultRow>
          </ResultRowContainer>
        ))}
        <TotalInfoContainer>
          <TotalCalorieDiv>
            총 칼로리: {totalInfo.calories.toFixed(2)}kcal
          </TotalCalorieDiv>
          <TotalCalorieDiv>총 제공량: {totalServingAmount.toFixed(2)}g</TotalCalorieDiv>
        </TotalInfoContainer>
        <ExcelExportButton data={totalInfo}>
          영양정보 다운로드
        </ExcelExportButton>
      </Container>
    );
  } else {
    return (
      <div>
        <div>no result</div>
      </div>
    );
  }
};

const defaultObj = {
  calcium_mg: 0,
  calories: 0,
  carbohydrate_g: 0,
  fat_g: 0,
  iron_mg: 0,
  protein_g: 0,
  sodium_mg: 0,
  vitamin_a_ug_re: 0,
  vitamin_b1_mg: 0,
  vitamin_b2_mg: 0,
  vitamin_c_mg: 0,
  servingAmount: 0,
  nuviCustomRatio: 1,
};

const getMenuNutritionInfo = (customFoodList, menuInfo, departmentList) => {
  const { id, bld, departmentId, menuDate } = menuInfo;

  const handleDepartmentName = (depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  };

  const totalInfo = customFoodList.reduce((a, c) => {
    let newObj = {
      menuInfo,
    };

    newObj.calcium_mg =
      a.calcium_mg * a.nuviCustomRatio + c.calcium_mg * c.nuviCustomRatio;
    newObj.calories =
      a.calories * a.nuviCustomRatio + c.calories * c.nuviCustomRatio;
    newObj.carbohydrate_g =
      a.carbohydrate_g * a.nuviCustomRatio +
      c.carbohydrate_g * c.nuviCustomRatio;
    newObj.fat_g = a.fat_g * a.nuviCustomRatio + c.fat_g * c.nuviCustomRatio;
    newObj.iron_mg =
      a.iron_mg * a.nuviCustomRatio + c.iron_mg * c.nuviCustomRatio;
    newObj.protein_g =
      a.protein_g * a.nuviCustomRatio + c.protein_g * c.nuviCustomRatio;
    newObj.sodium_mg =
      a.sodium_mg * a.nuviCustomRatio + c.sodium_mg * c.nuviCustomRatio;
    newObj.vitamin_a_ug_re =
      a.vitamin_a_ug_re * a.nuviCustomRatio +
      c.vitamin_a_ug_re * c.nuviCustomRatio;
    newObj.vitamin_b1_mg =
      a.vitamin_b1_mg * a.nuviCustomRatio + c.vitamin_b1_mg * c.nuviCustomRatio;
    newObj.vitamin_b2_mg =
      a.vitamin_b2_mg * a.nuviCustomRatio + c.vitamin_b2_mg * c.nuviCustomRatio;
    newObj.vitamin_c_mg =
      a.vitamin_c_mg * a.nuviCustomRatio + c.vitamin_c_mg * c.nuviCustomRatio;
    newObj.sodium_mg =
      a.sodium_mg * a.nuviCustomRatio + c.sodium_mg * c.nuviCustomRatio;
    newObj.servingAmount =
      a.servingAmount * a.nuviCustomRatio + c.servingAmount * c.nuviCustomRatio;
    newObj.nuviCustomRatio = 1;

    return newObj;
  }, defaultObj);

  // const totalInfo = customFoodList.reduce((a, c) => {
  //   let newObj = {
  //     menuInfo,
  //   };

  //   newObj.calcium_mg = a.calcium_mg + c.calcium_mg;
  //   newObj.calories = a.calories + c.calories;
  //   newObj.carbohydrate_g = a.carbohydrate_g + c.carbohydrate_g;
  //   newObj.fat_g = a.fat_g + c.fat_g;
  //   newObj.iron_mg = a.iron_mg + c.iron_mg;
  //   newObj.protein_g = a.protein_g + c.protein_g;
  //   newObj.sodium_mg = a.sodium_mg + c.sodium_mg;
  //   newObj.vitamin_a_ug_re = a.vitamin_a_ug_re + c.vitamin_a_ug_re;
  //   newObj.vitamin_b1_mg = a.vitamin_b1_mg + c.vitamin_b1_mg;
  //   newObj.vitamin_b2_mg = a.vitamin_b2_mg + c.vitamin_b2_mg;
  //   newObj.vitamin_c_mg = a.vitamin_c_mg + c.vitamin_c_mg;
  //   newObj.sodium_mg = a.sodium_mg + c.sodium_mg;
  //   newObj.servingAmount = a.servingAmount + c.servingAmount;

  //   return newObj;
  // }, defaultObj);

  totalInfo.id = id;
  totalInfo.bld = bld;
  totalInfo.departmentName = handleDepartmentName(departmentId);
  totalInfo.menuDate = menuDate;

  return totalInfo;
};

export default ReadBox;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
`;

const ResultRowContainer = styled.div`
  width: 90%;
  background-color: white;

  display: flex;
  justify-content: space-around;
  padding: 2px;
  marign: 3px;
  border: 1px solid black;
  border-radius: 5px;

  &:hover {
    background-color: lightgray;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px;
  overflow: hidden;
`;

const TotalInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TotalCalorieDiv = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 300;
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  margin-top: 30px;
  height: 40px;
  font-size: 22px;
  font-align: flex-start;
`;
