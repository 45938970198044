import React, { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import * as actionTypes from "../../../data/recommendation/actionTypes";
import { postConfig } from "../../../data/recommendation/actions/user";
import { Spinner } from "../Spinner";
import { Link } from "react-router-dom";

const StyledSelect = styled.select`
  color: var(--blue);
`;

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: var(--red-600);
  width: 400px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
`;

const MySelect = ({ label, text, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <div style={{ display: "flex" }}>
        <StyledLabel htmlFor={props.id || props.name}>
          {text ? label + text : label}
        </StyledLabel>
      </div>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const Config = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const userEmail = props.userEmail;
  const userId = props.userId;
  if (!userEmail) {
    alert("먼저 로그인해주세요");
    props.history.replace("/");
    return null;
  }

  async function submitConfig(data) {
    setSubmitting(true);
    const targetCaloriesCheck =
      data.target_calories == "free" ||
      Number(data.target_calories) >=
        300 + 100 * (Number(data.sidedish_num) + Number(data.dessert_num));

    if (!targetCaloriesCheck) {
      alert("열량을 최소기준보다 높게 설정해주세요");
      setSubmitting(false);
    } else if (!userEmail) {
      alert("식단 정보 저장에 실패하였습니다");
      setSubmitting(false);
    } else {
      postConfig(data, userId);
      props.onUpdateConfig({ ...data, isinDB: true });
      alert("식단 설정이 저장되었습니다");
      props.history.push("./recommendation");
    }
  }

  if (submitting) {
    return Spinner("식단을 저장하는 중입니다");
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        top: "100px",
        width: "600px",
        height: "600px",
        left: "600px",
        position: "relative",
      }}
    >
      <div>
        <h1>식단 정보 설정</h1>
      </div>

      <Formik
        initialValues={{
          affiliation_type: "",
          sidedish_num: "",
          dessert_num: "",
          target_calories: "",
          special: "",
          meal_price: "",
        }}
        validationSchema={Yup.object({
          affiliation_type: Yup.string().required("Required"),
          sidedish_num: Yup.string().required("Required"),
          dessert_num: Yup.string().required("Required"),
          target_calories: Yup.string().required("Required"),
          special: Yup.string().required("Required"),
          meal_price: Yup.string().required("Required"),
        })}
        onSubmit={(data) => {
          submitConfig(data);
        }}
      >
        <Form>
          <MySelect label="기관 종류" name="affiliation_type">
            <option value="">기관 종류를 선택하세요</option>
            <option value="elem">초등학교</option>
            <option value="middle">중학교</option>
            <option value="high">고등학교</option>
            <option value="goverment">공공기관</option>
            <option value="company">회사</option>
            <option value="other">기타</option>
          </MySelect>
          <MySelect label="반찬 수" name="sidedish_num">
            <option value="">반찬 수를 선택하세요</option>
            <option value="2">3개</option>
            <option value="3">4개</option>
            <option value="4">5개</option>
            <option value="5">6개</option>
          </MySelect>
          <MySelect label="디저트 수" name="dessert_num">
            <option value="">디저트 수를 선택하세요</option>
            <option value="0">0개</option>
            <option value="1">1개</option>
            <option value="2">2개</option>
          </MySelect>
          <MySelect
            label="열량"
            text="(최소기준: 300+100*반찬수+100*디저트수)"
            name="target_calories"
          >
            <option value="">열량을 선택하세요</option>
            <option value="500">400~600kcal</option>
            <option value="600">500~700kcal</option>
            <option value="700">600~800kcal</option>
            <option value="800">700~900kcal</option>
            <option value="900">800~1000kcal</option>
            <option value="1000">900~1100kcal</option>
            <option value="free">상관없음</option>
          </MySelect>
          <MySelect label="특식 제공일" name="special">
            <option value="">특식 제공일을 선택하세요</option>
            <option value="mon">월</option>
            <option value="tue">화</option>
            <option value="wed">수</option>
            <option value="thu">목</option>
            <option value="fri">금</option>
            <option value="free">상관없음</option>
          </MySelect>

          <MySelect label="식재료비/1식" name="meal_price">
            <option value="">식재료비를 선택하세요</option>
            <option value="1000">500~1500원</option>
            <option value="2000">1500~2500원</option>
            <option value="3000">2500~3500원</option>
            <option value="4000">3500~4500원</option>
            <option value="5000">4500~5500원</option>
            <option value="free">상관없음</option>
          </MySelect>
          <div />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button type="submit" disabled={submitting}>
              저장하기
            </button>
            <Link to="/recommendation">
              <button disabled={submitting}>취소하기</button>
            </Link>
            {/* </div> */}
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userEmail: state.recommend.user.userEmail,
    userId: state.recommend.user.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateConfig: (config) =>
      dispatch({
        type: actionTypes.UPDATE_CONFIG,
        config: config,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);
