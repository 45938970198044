import React, { useState, useMemo } from "react";
import ReactPaginate from "react-paginate";

import {
  Title,
  ResultContainer,
  ResultRowContainer,
  ResultRowNameContainer,
  ResultRow,
} from "pages/MasterPages/StyledComps";

import { useDepartmentList } from "hooks";

import { nuviSortFunction } from "pages/MasterPages/helper";

const ResultBox = ({ nuviSearchResult, selectNuviFood }) => {
  const departmentList = useDepartmentList();

  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const nuviPageCount = Math.ceil(nuviSearchResult.length / PER_PAGE);

  const handleDepartmentName = (depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  };

  const currentPageNuviSearchResult = nuviSearchResult
    .sort(nuviSortFunction)
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer
        key={idx}
        onClick={() => {
          selectNuviFood(el);
        }}
      >
        <ResultRow>{handleDepartmentName(el.departmentId)}</ResultRow>
        <ResultRow>{el.date}</ResultRow>
        <ResultRow>{el.bld}</ResultRow>
        <ResultRow>{el.foodName}</ResultRow>
        <ResultRow>{el.servingAmount}</ResultRow>
        <ResultRow>{el.calories}</ResultRow>
        <ResultRow>{el.source}</ResultRow>
      </ResultRowContainer>
    ));

  if (nuviSearchResult[0]) {
    return (
      <ResultContainer>
        <Title>검색결과</Title>
        <ResultRowNameContainer>
          <ResultRow>기관명</ResultRow>
          <ResultRow>일자</ResultRow>
          <ResultRow>BLD</ResultRow>
          <ResultRow>누비푸드이름</ResultRow>
          <ResultRow>제공량(g)</ResultRow>
          <ResultRow>칼로리(kcal)</ResultRow>
          <ResultRow>출처</ResultRow>
        </ResultRowNameContainer>
        {currentPageNuviSearchResult}
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={nuviPageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </ResultContainer>
    );
  } else {
    return (
      <ResultContainer>
        <Title>검색결과</Title>
        <div>검색 결과가 없습니다</div>
      </ResultContainer>
    );
  }
};

export default ResultBox;
