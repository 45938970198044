import React, { useState } from "react";
import styled from "styled-components";

import CreateAndAddCustomFood from "./CreateAndAddCustomFood";
import FindAndAddNuviFood from "./FindAndAddNuviFood";

export default function AddMenuModal({
  departmentId,
  modalOpen,
  handleModalOpen,
  addToUpdatedList,
}) {
  const [createMode, setCreateMode] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Escape" && modalOpen) {
      handleModalOpen();
    }
  };

  document.onkeydown = handleKeyPress;

  return (
    <ResultContainer>
      <TitleContainer>
        <Title>
          {createMode ? "음식 새로 만들어 추가하기" : "음식 추가하기"}
        </Title>
        <CloseButton onClick={handleModalOpen}>Close</CloseButton>
      </TitleContainer>
      {/* {createMode ? (
        <CreateAndAddCustomFood
          modalOpen={modalOpen}
          handleModalOpen={handleModalOpen}
          addToUpdatedList={addToUpdatedList}
          setCreateMode={setCreateMode}
        />
      ) : ( */}
      <FindAndAddNuviFood
        modalOpen={modalOpen}
        handleModalOpen={handleModalOpen}
        addToUpdatedList={addToUpdatedList}
        setCreateMode={setCreateMode}
        departmentId={departmentId}
      />
      {/* )} */}
    </ResultContainer>
  );
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;

const Title = styled.div`
  width: 100%;
  background-color: none;
  font-size: 40px;
  font-weight: bold;
  padding: 10px;
`;

const CloseButton = styled.button`
  width: 200px;
  height: 50px;
  margin: 15px;
  font-size: 30px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
`;
