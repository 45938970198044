// import { persistReducer } from "redux-persist";
// import sessionStorage from "redux-persist/lib/storage/session";
import { combineReducers } from "redux";
import highlight from "./reducers/highlight";
import calendar from "./reducers/calendar";
import selection from "./reducers/selection";
import user from "./reducers/user";

// const persistConfig = {
//   key: "root",
//   storage: sessionStorage,
//   whitelist: ["user"]
// };

const rootReducer = combineReducers({
  highlight: highlight,
  selection: selection,
  calendar: calendar,
  user: user
});

export default rootReducer;
