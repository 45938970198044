import React from "react";
import Loader from "react-loader-spinner";

export const Spinner = text => {
  return (
    <div
      style={{
        display: "flex",
        height: "50%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: "2vh", color: "grey" }}>{text}</p>
      <Loader type="Bars" color="#ffd600" height={100} width={200} />
    </div>
  );
};
