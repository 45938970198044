import React, { useState, useEffect } from "react";
import { convertDuplicates, findDuplicateClass } from "./helper";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";
import * as actionTypes from "../../../../data/recommendation/actionTypes";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./index.css";
import Duplicates from "../../Statistics/Duplicates";

const GreenCheckbox = withStyles({
  root: {
    color: yellow[400],
    "&$checked": {
      color: yellow[600],
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    style={{ width: 100, height: 100 }}
    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
    checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
    color="default"
    {...props}
  />
));

const GetMonthTable = (props) => {
  const days = [];
  const weekDays = [];
  props.commandList.forEach((command) => {
    days.push(command.slice(command.indexOf("(") + 1, command.indexOf(",")));

    if (command.slice(0, 1) === "m") {
      weekDays.push(
        command.slice(command.indexOf("(") + 1, command.indexOf(","))
      );
    }
  });

  const initState = {};
  const fullState = {};
  const fullStateL = {};
  const fullStateD = {};

  for (var day of days) {
    initState[`L${day}`] = false;
    initState[`D${day}`] = false;
    fullState[`L${day}`] = true;
    fullState[`D${day}`] = true;
    fullStateL[`L${day}`] = true;
    fullStateL[`D${day}`] = false;
    fullStateD[`L${day}`] = false;
    fullStateD[`D${day}`] = true;
  }

  const [state, setState] = useState(initState);

  useEffect(() => {
    if (props.resetSelected) {
      setState(initState);
      props.onResetSelectedOff();
    }
  });

  // 전체 선택 시 rerendering하게 만들기
  useEffect(() => {
    if (props.allSelected) {
      if (props.showLD === "L") {
        const copyState = Object.assign({}, fullState);
        setState(Object.assign(copyState, fullStateL));
      } else if (props.showLD === "D") {
        const copyState = Object.assign({}, fullState);
        setState(Object.assign(copyState, fullStateD));
      }
    } else {
      setState(initState);
    }
  }, [props.allSelected]);

  useEffect(() => {}, [props.highlightState]);
  useEffect(() => {
    props.onUpdateSelectState(state);
  }, [state]);

  useEffect(() => {
    for (var i = 0; i < weekDays.length; i++)
      if (!props.displayingDays.includes(Number(weekDays[i]))) {
        props.onUpdateDisplayingDays(Number(weekDays[i]));
      }
  }, [weekDays]);

  const handleChange = (event, startDayCount, LD) => {
    const key = `${LD}${startDayCount}`; //LD + String(startDayCount);
    setState({ ...state, [key]: event.target.checked });
    props.onUpdateSelectedDay(startDayCount);
    props.onUpdateSelectedLD(LD);
    if (!state[key] && !props.modifyState) {
      props.onUpdateModifyState();
    }
  };

  const block1 = (startDayCount) => {
    return (
      <div className="calendar__day3_month">
        <div style={{ textAlign: "left" }}>
          <b>{startDayCount}</b>
        </div>
        <span></span>
      </div>
    );
  };

  const block2 = (prevLastDay, firstDayName, j) => {
    return (
      <div className="calendar__day3_month">
        <div style={{ textAlign: "left" }}>
          <b>{prevLastDay - (firstDayName - 1) + j}</b>
        </div>
        <span></span>
      </div>
    );
  };

  const block3 = (prevLastDay, firstDayName, j) => {
    return (
      <div className="calendar__day">
        <div style={{ textAlign: "left" }}>
          {prevLastDay - (firstDayName - 1) + j}
        </div>
        <span></span>
      </div>
    );
  };

  const block5 = (prevLastDay, firstDayName, j) => {
    return (
      <div className="calendar__day3_month">
        <div style={{ textAlign: "left" }}>
          <b>{String(prevLastDay - (firstDayName - 1) + j)}</b>
        </div>
      </div>
    );
  };

  const menuDetail = (elem, startDayCount, LD, todayMenu, calories) => {
    //elem: state[key]
    const menuLen = todayMenu.length;
    todayMenu = todayMenu.slice(0, -1).map((line, idx) => (
      <span key={idx}>
        {convertDuplicates(line, duplicateClass, idx, menuLen)}
        <br />
      </span>
    ));

    return (
      <div className="calendar__day4_month">
        <div
          style={{
            textAlign: "left",
            color: "black",
            fontSize: "18px",
            position: "relative",
            left: "12px",
          }}
        >
          <b>{String(startDayCount)}</b>
        </div>

        <div
          style={{
            textAlign: "right",
            marginTop: "-30px",
            position: "relative",
            right: "12px",
          }}
        >
          <FormControlLabel
            control={
              <GreenCheckbox
                style={{
                  marginRight: "-30px",
                }}
                checked={elem || false}
                onChange={(event) => handleChange(event, startDayCount, LD)}
                name="checkedG"
              />
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "85%",
          }}
        >
          <div style={{ marginTop: "-25px" }}>{todayMenu}</div>
          <div
            style={{
              borderTop: "2px solid lightgrey",
              fontSize: "14px",
              paddingTop: "3px",
              lineHeight: "14px",
              position: "relative",
              top: "8px",
            }}
          >
            {calories}
          </div>
        </div>
      </div>
    );
  };

  const mlock1 = (startDayCount, dayMenu) => {
    let todayMenu = dayMenu.replace(/-/gi, "\n").split("\n");
    const LD = props.showLD;
    const key = LD + String(startDayCount);
    const calories = getCalories(todayMenu);
    return menuDetail(state[key], startDayCount, LD, todayMenu, calories);
  };

  let Duplicate = "";

  const Lunch = `${String(props.year)}-${String(props.month)}-L`;

  const Dinner = `${String(props.year)}-${String(props.month)}-D`;

  let lunchData = "";
  let dinnerData = "";
  if (Lunch in props.monthData) {
    lunchData = props.monthData[Lunch];
  }

  if (Dinner in props.monthData) {
    dinnerData = props.monthData[Dinner];
  }

  if (props.initialized && (lunchData || dinnerData)) {
    Duplicate = <Duplicates />;
  }
  let duplicateClass = { 0: [], 1: [], 2: [], 3: [], 4: [] };

  // 중복되는 밥 메뉴가 있는 경우 실행
  for (var i = 0; i < 5; i++) {
    if (props.highlight[0]) {
      duplicateClass[i] = findDuplicateClass(props.highlight[i], i);
    }
  }

  return (
    <div className="MenuTable">
      {props.commandList.map((x, idx) =>
        x[0] === "b" ? (
          <span key={idx}>{eval(x)}</span>
        ) : (
          <span key={idx}>
            {eval(
              x.slice(0, -8) +
                '"' +
                String(props.dayToMenu[x.slice(7, 9).replace(",", "")]) +
                '"' +
                "," +
                "duplicateClass" +
                ")"
            )}
          </span>
        )
      )}
      {Duplicate}
      <p>&nbsp;</p>
    </div>
  );
};

const getCalories = (todayMenu) => {
  let calories = todayMenu.slice(-1);
  calories = calories[0].replace("(", "");
  calories = calories.replace(")", "");
  return calories;
};

const mapStateToProps = (state) => {
  return {
    highlight: state.recommend.highlight.highlight,
    currentMonth: state.recommend.calendar.currentMonth,
    showLD: state.recommend.calendar.showLD,
    modifyState: state.recommend.selection.modifyState,
    allSelected: state.recommend.selection.allSelected,
    resetSelected: state.recommend.selection.resetSelected,
    displayingDays: state.recommend.selection.displayingDays,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    monthData: state.recommend.user.monthData,
    initialized: state.recommend.user.initialized,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateModifyState: () =>
      dispatch({
        type: actionTypes.UPDATE_MODIFY_STATE,
      }),

    onUpdateSelectedDay: (day) =>
      dispatch({
        type: actionTypes.UPDATE_SELECTED_DAY,
        selectedDay: day,
      }),

    onResetSelectedOff: () => {
      dispatch({
        type: actionTypes.RESET_SELECTED_OFF,
        resetSelected: false,
      });
    },

    onUpdateSelectState: (selectState) =>
      dispatch({
        type: actionTypes.UPDATE_SELECT_STATE,
        selectState: selectState,
      }),

    onUpdateSelectedLD: (LD) =>
      dispatch({
        type: actionTypes.UPDATE_SELECTED_LD,
        selectedLD: LD,
      }),

    onUpdateDisplayingDays: (day) =>
      dispatch({
        type: actionTypes.UPDATE_DISPLAYING_DAYS,
        day: day,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetMonthTable);
