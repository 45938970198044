import React, { useState } from "react";

import { useNuviFoodMainCategories, useNuviFoodSubCategories } from "hooks";

import styled from "styled-components";

import { apiClient } from "api/client";

import {
  SearchContainer,
  SearchBarContainer,
  Title,
} from "pages/MasterPages/StyledComps";

export default function SearchBox({ handleNuviSearchResult, handleModalOpen }) {
  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();

  const [keyword, setKeyword] = useState("");
  const [nuviFoodMainCategoryId, setNuviFoodMainCategoryId] = useState(null);
  const [nuviFoodSubCategoryId, setNuviFoodSubCategoryId] = useState(null);

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const handleNuviFoodMainCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodMainCategoryId(value);
  };
  const handleNuviFoodSubCategoryId = (e) => {
    const value = e.target.value;
    setNuviFoodSubCategoryId(value);
  };

  const searchNuviFood = async () => {
    const res = await apiClient.get(`/api/food/nuvi-food/list`, {
      params: {
        foodName: keyword,
        nuviFoodMainCategoryId,
        nuviFoodSubCategoryId,
      },
    });
    handleNuviSearchResult(res.data);
    setKeyword("");
    document.getElementById("searchInput").value = "";
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    } else {
      e.preventDefault();
      searchNuviFood();
    }
  };

  return (
    <SearchContainer>
      <Title>누비푸드 조회/추가</Title>
      <SearchBarContainer>
        <StyledSelect onChange={handleNuviFoodMainCategoryId}>
          <option value="">없음</option>
          {nuviFoodMainCategories.map(({ id, categoryName }) => (
            <option value={id}>{categoryName}</option>
          ))}
        </StyledSelect>

        <StyledSelect onChange={handleNuviFoodSubCategoryId}>
          <option value="">없음</option>
          {nuviFoodSubCategories
            .filter(
              (subCategory) =>
                subCategory.nuviFoodMainCategoryId ===
                Number(nuviFoodMainCategoryId)
            )
            .map(({ id, categoryName }) => (
              <option value={id}>{categoryName}</option>
            ))}
        </StyledSelect>
        <StyledTextInput
          id="searchInput"
          type="text"
          onChange={handleKeyword}
          onKeyPress={searchOnPressEnter}
          autoFocus
          placeholder="누비푸드 이름을 입력하세요"
        />
        <StyledButton type="button" onClick={searchNuviFood}>
          검색
        </StyledButton>
        <StyledButton type="button" onClick={handleModalOpen}>
          추가
        </StyledButton>
      </SearchBarContainer>
    </SearchContainer>
  );
}

const StyledTextInput = styled.input`
  width: 400px;
  height: 50px;
  font-size: 30px;
`;

const StyledButton = styled.button`
  width: 200px;
  height: 50px;
  margin: 15px;
  font-size: 30px;
  cursor: pointer;
`;

const StyledSelect = styled.select`
  width: 120px;
  height: 50px;
`;
