import React from "react";
import { ConnectedRouter as Router } from "connected-react-router";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import { MasterRoutes, AdminRoutes } from "routes";
import { DashBoardLayout, MasterLayout, AuthLayout } from "layout";

import {
  Daily,
  Total,
  Auth,
  AdminApply,
  AdminList,
  Error404,
  Error500,
  UserProfile,
  Inventory,
  Dashboard,
  Point,
  MakeMenu,
  EditMenu,
  NuviFoodInfo,
  ScannerManagement,
  Recommendation,
  Config,
  FoodTable,
  IngredientTable,
} from "pages";

function App({ history }) {
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/"
          render={(matchProps) => (
            <AuthLayout>
              <Auth {...matchProps} />
            </AuthLayout>
          )}
        />
        <AdminRoutes path="/daily" layout={DashBoardLayout} component={Daily} />
        <AdminRoutes path="/total" layout={DashBoardLayout} component={Total} />
        <AdminRoutes
          path="/inventory"
          layout={DashBoardLayout}
          component={Inventory}
        />
        <AdminRoutes
          path="/profile"
          layout={DashBoardLayout}
          component={UserProfile}
        />
        <AdminRoutes
          path="/dashboard"
          layout={DashBoardLayout}
          component={Dashboard}
        />

        <AdminRoutes
          path="/recommendation"
          layout={DashBoardLayout}
          component={Recommendation}
        />

        <AdminRoutes
          path="/config"
          layout={DashBoardLayout}
          component={Config}
        />

        <AdminRoutes
          path="/dbtable/food"
          layout={DashBoardLayout}
          component={FoodTable}
        />

        <AdminRoutes
          path="/dbtable/ingredient"
          layout={DashBoardLayout}
          component={IngredientTable}
        />

        <AdminRoutes path="/point" layout={DashBoardLayout} component={Point} />

        <MasterRoutes
          path="/adminApply"
          layout={MasterLayout}
          component={AdminApply}
        />
        <MasterRoutes
          path="/adminList"
          layout={MasterLayout}
          component={AdminList}
        />
        <MasterRoutes
          path="/makeMenu"
          layout={MasterLayout}
          component={MakeMenu}
        />
        <MasterRoutes
          path="/editMenu"
          layout={MasterLayout}
          component={EditMenu}
        />
        <MasterRoutes
          path="/nuviFoodInfo"
          layout={MasterLayout}
          component={NuviFoodInfo}
        />
        <MasterRoutes
          path="/scannerManagement"
          layout={MasterLayout}
          component={ScannerManagement}
        />
        <Route path="/500" component={Error500} />
        <Route path="/" component={Error404} />
      </Switch>
    </Router>
  );
}

export default App;
