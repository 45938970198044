import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "data";

import styled from "styled-components";
import moment from "moment";

import { apiClient } from "api/client";

export default function ReadPoint({ tabHandler }) {
  const dispatch = useDispatch();

  const user = useSelector(selectors.user.getUserSession);
  const departmentName = user.Department.name;
  const departmentId = user.departmentId;

  const [userName, setUserName] = useState("");
  const [rfidNumber, setRFIDNumber] = useState("");
  const [pointValue, setPointValue] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [RFIDSearchMode, setRFIDSearchMode] = useState(false);
  const [userList, setUserList] = useState([]);

  const handeSearchKeyword = (e) => {
    if (RFIDSearchMode) {
      setRFIDNumber(e.target.value);
    } else {
      setUserName(e.target.value);
    }
  };

  const handlePointValue = (e) => {
    setPointValue(e.target.value);
  };

  const resetValues = () => {
    setUserName("");
    setRFIDNumber("");
    setPointValue(0);
    setSelectedUser(null);
    setUserList([]);
    document.getElementById("search").value = "";
    document.getElementById("point").value = "";
    // document.getElementById("actionType").selectedIndex = 0;
  };

  const handleNameSearch = async () => {
    const data = {
      userName,
      departmentId,
    };
    const res = await apiClient.post(`/api/user/list`, data);
    const userList = res.data;
    setUserList(userList.filter((user) => !user.isAdmin));
  };

  const handleRFIDSearch = async () => {
    const data = {
      departmentId,
      rfidNumber,
    };
    const res = await apiClient.post(`/api/user/list/rfid`, data);
    const rfidUserList = res.data;
    if (rfidUserList) {
      setUserList(rfidUserList);
    }
  };

  const handleSearch = () => {
    if (RFIDSearchMode) {
      handleRFIDSearch();
    } else {
      handleNameSearch();
    }
  };

  const updatePoint = () => {
    let value = Number(pointValue);
    const data = {
      userId: selectedUser.id,
      pointValue: value * -1, // 차감 전용이므로 음수처리
      actionType: "포인트 사용",
    };
    dispatch(actions.modal.clearModal());
    dispatch(actions.point.updatePoint(data));
    resetValues();
  };

  const checkUpdate = () => {
    let value = Number(pointValue);
    if (value > 0) {
      dispatch(
        actions.modal.setModal({
          modalType: "CONDITIONAL",
          modalProps: {
            contents: `${selectedUser.name}님 ${pointValue}P 사용하시겠습니까?`,
            onClick: updatePoint,
            buttonName: "확인",
          },
        })
      );
    } else {
      alert("사용할 포인트는 0보다 커야 합니다");
    }
  };

  const getIndividualPoint = () => {
    dispatch(
      actions.point.getIndividualPoint({
        departmentId,
      })
    );
  };

  const toggleRFIDMode = () => {
    setRFIDSearchMode(!RFIDSearchMode);
    resetValues();
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    } else {
      e.preventDefault();
      handleSearch();
    }
  };

  useEffect(() => {
    getIndividualPoint();
  }, []);

  useEffect(() => {
    document.getElementById("search").focus();
  }, [RFIDSearchMode]);

  return (
    <ColumnWrapper>
      <ModeChangeButton onClick={tabHandler}>
        전체 포인트 사용내역
      </ModeChangeButton>
      <Title>{departmentName} </Title>
      <Title>개인별 포인트 조회/사용</Title>

      <Container>
        <LeftContainer>
          <ActionContainer>
            <ToggleButtonContainer>
              <ToggleButton onClick={toggleRFIDMode}>
                {RFIDSearchMode ? "이름검색" : "RFID검색"}
              </ToggleButton>
            </ToggleButtonContainer>
            <SubTitle>
              {RFIDSearchMode ? "1. 사용자 검색(RFID)" : "1. 사용자 검색(이름)"}
            </SubTitle>

            <ItemContainer>
              <StyledInput
                id="search"
                type="text"
                onChange={handeSearchKeyword}
                placeholder={
                  RFIDSearchMode
                    ? "RFID카드를 태그해주세요"
                    : "사용자명을 입력하세요"
                }
                onKeyPress={searchOnPressEnter}
                style={{ flex: 3 }}
                autoFocus
              />
              <MiniButton onClick={handleSearch}>검색</MiniButton>

              {selectedUser ? (
                <InfoText>
                  <BoldSpan>{selectedUser.name}</BoldSpan>
                  님이 선택되었습니다
                </InfoText>
              ) : (
                <InfoText>사용자를 선택해주세요</InfoText>
              )}

              <UserListContainer>
                {userList[0] && (
                  <UserContainer
                    style={{
                      backgroundColor: "whitesmoke",
                      fontWeight: "bold",
                    }}
                  >
                    <UserInfoItem>이름</UserInfoItem>
                    <UserInfoItem>가용포인트</UserInfoItem>
                    <UserInfoItem>핸드폰</UserInfoItem>
                  </UserContainer>
                )}

                {userList
                  // .filter((el) => !el.isAdmin)
                  .map((el, idx) => (
                    <UserContainer
                      key={idx}
                      userId={el.id}
                      selecteduser={selectedUser}
                      onClick={() => setSelectedUser(el)}
                    >
                      <UserInfoItem>{`${el.name}`}</UserInfoItem>
                      <UserInfoItem>{`${el.availablePoint}P`}</UserInfoItem>
                      <UserInfoItem>
                        {el.PersonalInfo
                          ? `${el.PersonalInfo.phoneNumber}`
                          : "정보없음"}
                        {/* {el.PersonalInfo
                          ? `${el.PersonalInfo.phoneNumber.substr(-4)}`
                          : "정보없음"} */}
                      </UserInfoItem>
                    </UserContainer>
                  ))}
              </UserListContainer>
            </ItemContainer>
          </ActionContainer>

          <ActionContainer>
            <SubTitle>2. 포인트 사용</SubTitle>
            <ItemContainer>
              <StyledInput
                id="point"
                type="number"
                placeholder="0"
                onChange={handlePointValue}
                min={0}
              ></StyledInput>
              <MiniButton
                onClick={checkUpdate}
                disabled={selectedUser ? false : true}
                width="100px"
              >
                포인트 사용
              </MiniButton>

              {selectedUser &&
                (pointValue >= 0 ? (
                  <InfoText>
                    <BoldSpan>{selectedUser.name}</BoldSpan>님{" "}
                    <BoldSpan>{pointValue}</BoldSpan>
                    포인트 사용
                  </InfoText>
                ) : (
                  <InfoText>사용할 포인트는 0보다 커야합니다</InfoText>
                ))}
            </ItemContainer>
          </ActionContainer>
        </LeftContainer>
        <RightContainer>
          <ActionContainer>
            {selectedUser && (
              <>
                <SubTitle>{selectedUser.name}님 포인트 사용내역</SubTitle>
                <ItemContainer
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PointRow
                    style={{
                      backgroundColor: "whitesmoke",
                      fontWeight: "bold",
                    }}
                  >
                    <Text>사용일자</Text>
                    <Text>차감포인트</Text>
                    <RecordText>비고</RecordText>
                  </PointRow>

                  {selectedUser.IndividualPoints.filter((el) => el.point < 0)
                    .reverse()
                    .map((el, idx) => (
                      <PointRow key={idx}>
                        <Text>{moment(el.createdAt).format("YYYY.M.D ")}</Text>
                        <Text>{el.point}P</Text>
                        <RecordText>{el.actionType}</RecordText>
                      </PointRow>
                    ))}
                </ItemContainer>
              </>
            )}
          </ActionContainer>
        </RightContainer>
      </Container>
    </ColumnWrapper>
  );
}

const ColumnWrapper = styled.div`
  width: 100%;
  min-height: 97vh;
  font-family: Noto Sans KR, sans-serif;

  background-color: none;
  justify-content: center;

  padding: 0px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  @media (min-width: 1133px) {
    display: flex;
  }
  @media (max-width: 600px) {
    width: 90%;
    flex-direction: column;
    padding-left: 10%;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 3px solid whitesmoke;
  min-width: 50vw;
  @media (max-width: 600px) {
    border: 1px solid transparent;
    width: 95%;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50vw;
  @media (max-width: 600px) {
    width: 95%;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserListContainer = styled.div`
  width: 100%;
  background-color: none;
  justify-content: center;
  aligh-items: center;
`;

const UserContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;

  padding: 10px;
  border: ${({ userId, selecteduser }) => {
    if (selecteduser && selecteduser.id === userId) {
      return "3px solid #ff7b25";
    } else {
      return "1px solid black";
    }
  }};
  border-radius: 5px;
  @media (max-width: 550px) {
    width: 100%;
    display: flex;
  }
`;

const ItemContainer = styled.div`
  width: 500px;
  background-color: none;
  justify-content: space-around;
  border: 1px solid lightgray;
  border-radius: 10px;
  margin: 20px;
  padding: 3%;
  @media (max-width: 550px) {
    width: 100%;
    overflow: scroll;
  }
`;

const PointRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 2rem;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  position: relative;
  background-color: white;

  @media (max-width: 550px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Text = styled.div`
  min-width: 100px;
  background-color: none;
  font-size: 1rem;
`;
const RecordText = styled.div`
  min-width: 150px;
  background-color: none;
  font-size: 1rem;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin: 10px 50px;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
`;

const MiniButton = styled.button`
  width: ${({ width }) => (width ? width : "50px")};
  height: 2rem;
  border: 1px solid black;
  background-color: white;
  margin: 3px;
  @media (max-width: 550px) {
    width: 30%;
  }
`;

const ToggleButtonContainer = styled.div`
  width: 90%;
  border: 1px solid none;
`;

const ToggleButton = styled.button`
  width: 100px;
  height: 2rem;
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 20px;
  background-color: white;
  margin: 3px;
  @media (max-width: 550px) {
    width: 30%;
  }
`;

const StyledInput = styled.input`
  width: 300px;
  height: 2rem;
  margin: 10px;
  background-color: none;

  @media (max-width: 550px) {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
`;

const ModeChangeButton = styled.button`
  min-width: 200px;
  min-height: 2rem;
  border: 1px solid black;
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: white;
  border-radius: 20px;
`;

const UserInfoItem = styled.div`
  width: 300px;
`;

const InfoText = styled.div`
  font-size: 20px;
  margin: 25px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
  font-size: 20px;
`;
