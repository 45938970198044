import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";

import { useDepartmentList, useCornerList } from "hooks";
import styled from "styled-components";

import { MenuBox, MenuTitle } from "pages/MasterPages/StyledComps";

const BLD_OPTIONS = [
  { id: "B", name: "조식" },
  { id: "L", name: "중식" },
  { id: "D", name: "석식" },
];

const fieldStyle = {
  width: "100%",
  height: "50px",
  fontSize: "22px",
  borderRadius: "5px",
  border: "1px solid black",
  flex: 2,
  textAlign: "start",
  paddingLeft: "20px",
};

export default function SearchBox({ passedValues }) {
  const { values, submitForm, setFieldValue } = useFormikContext();
  const departmentId = passedValues?.departmentId
    ? passedValues?.departmentId
    : values?.departmentId;
  const departmentList = useDepartmentList();

  const cornerList = useCornerList().filter(
    (cornerInfo) => cornerInfo.departmentId === Number(departmentId)
  );

  useEffect(() => {
    submitForm();
  }, [values, submitForm]);

  useEffect(() => {
    if (passedValues) {
      const { departmentId, cornerId, date, bld } = passedValues;
      setFieldValue("departmentId", departmentId);
      setFieldValue("cornerId", cornerId);
      setFieldValue("date", date);
      setFieldValue("bld", bld);
    }
  }, []);

  return (
    <MenuBox>
      <MenuTitle> 기본 선택</MenuTitle>
      <SearchOptionContainer>
        <SelectContainer>
          <FieldLabel>날짜</FieldLabel>
          <Field name="date" type="date" style={fieldStyle} />
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>기관명</FieldLabel>
          <Field as="select" name="departmentId" style={fieldStyle}>
            <option value={""}> 기관 선택 </option>
            {departmentList.map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.name}
              </option>
            ))}
          </Field>
        </SelectContainer>
        <SelectContainer>
          <FieldLabel>코너</FieldLabel>
          <Field as="select" name="cornerId" style={fieldStyle}>
            <option value={""}> 선택 </option>
            {cornerList.map((cornerInfo, idx) => (
              <option key={idx} value={cornerInfo.id}>
                {cornerInfo.cornerName}
              </option>
            ))}
          </Field>
        </SelectContainer>

        <SelectContainer>
          <FieldLabel>BLD</FieldLabel>
          <Field as="select" name="bld" style={fieldStyle}>
            <option value={""}> 선택 </option>
            {BLD_OPTIONS.map((el, idx) => (
              <option key={idx} value={el.id}>
                {el.name}
              </option>
            ))}
          </Field>
        </SelectContainer>
      </SearchOptionContainer>
    </MenuBox>
  );
}

const SearchOptionContainer = styled.div`
  width: 100%;
  background-color: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectContainer = styled.div`
  width: 450px;
  background-color: none;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: 1px solid none;
  gap: 15px;
`;

const FieldLabel = styled.div`
  display: flex;
  flex: 1;
  width: 100px;
  height: 50px;
  font-size: 20px;
  border: 1px solid #808080;
  border-radius: 5px;
  background-color: white;
  margin: 5px;

  text-align: center;
  justify-content: center;
  align-items: center;
`;
