import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import * as actionTypes from "data/recommendation/actionTypes";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledBox = styled.div`
  background: #ffffff;
  color: #white;
  font-size: 25px !important;
  // font-family: "NanumGothicBold", cursive;
  margin: 0 auto;
  margin-top: 0rem;
  text-align: left bottom;
  vertical-align: bottom;
  z-index: 100;
  border: 1px black;
`;

const SelectBox = (props) => {
  const classes = useStyles();
  const initState = props.monthOrWeek === "week" ? "" : "Month";
  const [state, setState] = React.useState(initState);

  const handleChange = (event) => {
    setState(event.target.value);
    if (props.highlightState) {
      props.onUpdateHighlightState();
    }
    props.onResetSelectedOn();
    if (event.target.value === "") {
      props.onUpdateMonthOrWeek("week");
      props.onUpdateShowLD("LD");
    } else if (event.target.value === "Month") {
      props.onUpdateMonthOrWeek("month");
      props.onUpdateShowLD("L");
    }
    props.onResetSelectedOn();
  };

  return (
    <StyledBox>
      <div
        style={{
          color: "black",
          // borderRight: "solid #d3d3d3",
          // borderLeft: "solid #d3d3d3",
          // background: "yellow",
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              style={{
                textAlign: "center",
              }}
            ></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={state}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              style={{
                fontSize: "2rem",
                marginTop: "-2px",
                // backgroundColor: "blue",
              }}
            >
              <MenuItem value={""}>주간</MenuItem>
              <MenuItem value={"Month"}>월간</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </StyledBox>
  );
};

const mapStateToProps = (state) => {
  return {
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    highlightState: state.recommend.highlight.highlightState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateMonthOrWeek: (monthOrWeek) =>
      dispatch({
        type: actionTypes.UPDATE_MONTH_OR_WEEK,
        monthOrWeek: monthOrWeek,
      }),

    onResetSelectedOn: () =>
      dispatch({
        type: actionTypes.RESET_SELECTED_ON,
      }),
    onUpdateShowLD: (LD) =>
      dispatch({
        type: actionTypes.UPDATE_SHOW_LD,
        showLD: LD,
      }),
    onResetSelectedOn: (LD) =>
      dispatch({
        type: actionTypes.RESET_SELECTED_ON,
        showLD: LD,
      }),
    onUpdateHighlightState: () =>
      dispatch({
        type: actionTypes.UPDATE_HIGHLIGHT_STATE,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectBox);
