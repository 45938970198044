import { useEffect } from "react";
import {
  bapMainList,
  gukMainList,
  gimchiMainList,
  banchanMainList,
  dessertMainList,
} from "./MajorClass";

import { connect } from "react-redux";
import * as actionTypes from "../../../data/recommendation/actionTypes";

const countMenu = (menuArr) => {
  var count = {};

  for (var i = 0; i < menuArr.length; i++) {
    var num = menuArr[i];
    if (num === "") {
      continue;
    }
    count[num] = count[num] ? count[num] + 1 : 1;

    // Create items array
    var items = Object.keys(count).map(function (key) {
      return [key, count[key]];
    });

    // Sort the array based on the second element
    items.sort(function (first, second) {
      return second[1] - first[1];
    });

    // erase count 1
    items = items.filter((x) => x[1] !== 1);
    items = items.map((x) => x[0] + "(" + String(x[1]) + ")" + " ");
  }

  return items;
};

const Duplicates = (props) => {
  const Lunch = `${String(props.year)}-${String(props.month)}-L`;
  const Dinner = `${String(props.year)}-${String(props.month)}-D`;

  const lunchData = props.monthData[Lunch];
  const dinnerData = props.monthData[Dinner];

  let splited = [];

  for (var day of props.displayingDays) {
    if (props.showLD === "L" || props.showLD === "LD") {
      splited.push(String(lunchData[day]).split("-"));
    }
    if (props.showLD === "D" || props.showLD === "LD") {
      splited.push(String(dinnerData[day]).split("-"));
    }
  }

  const reducer = (accumulator, currentValue) =>
    accumulator.concat(currentValue);
  const allArr = splited.reduce(reducer, []);

  // split menu according to MajorClass
  let bapArray = [];
  let gukArray = [];
  let gimchiArray = [];
  let banchanArray = [];
  let dessertArray = [];

  if (allArr.length > 2) {
    for (var i = 0; i < allArr.length; i++) {
      for (var j = 0; j < bapMainList.length; j++) {
        if (allArr[i].slice(-bapMainList[j].length) === bapMainList[j]) {
          bapArray.push(allArr[i]);
          break;
        }
      }
      for (var j = 0; j < gukMainList.length; j++) {
        if (allArr[i].slice(-gukMainList[j].length) === gukMainList[j]) {
          gukArray.push(allArr[i]);
          break;
        }
      }
      for (var j = 0; j < gimchiMainList.length; j++) {
        if (allArr[i].slice(-gimchiMainList[j].length) === gimchiMainList[j]) {
          gimchiArray.push(allArr[i]);
          break;
        }
      }
      for (var j = 0; j < banchanMainList.length; j++) {
        if (
          allArr[i].slice(-banchanMainList[j].length) === banchanMainList[j]
        ) {
          banchanArray.push(allArr[i]);
          break;
        }
      }
      for (var j = 0; j < dessertMainList.length; j++) {
        if (
          allArr[i].slice(-dessertMainList[j].length) === dessertMainList[j]
        ) {
          dessertArray.push(allArr[i]);
          break;
        }
      }
    }
  }
  let bapArr = bapArray;
  let gukArr = gukArray;
  let gimchiArr = gimchiArray;
  let banchanArr = banchanArray;
  let dessertArr = dessertArray;

  // erase null
  banchanArr = banchanArr.filter((x) => x != null);

  const stat = [
    countMenu(bapArr),
    countMenu(gukArr),
    countMenu(gimchiArr),
    countMenu(banchanArr),
    countMenu(dessertArr),
  ];

  // rerendering
  useEffect(() => {}, [props.highlightState]);
  useEffect(() => {
    if (props.highlightState) {
      props.onUpdateHighlight(stat);
    } else {
      props.onUpdateHighlight([]);
    }
  }, [stat]);
  return "";
};

const mapStateToProps = (state) => {
  return {
    currentYear: state.recommend.calendar.currentYear,
    year: state.recommend.calendar.year,
    currentMonth: state.recommend.calendar.currentMonth,
    month: state.recommend.calendar.month,
    currentWeek: state.recommend.calendar.currentWeek,
    monthData: state.recommend.user.monthData,
    displayingDays: state.recommend.selection.displayingDays,
    showLD: state.recommend.calendar.showLD,
    highlightState: state.recommend.highlight.highlightState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateHighlight: (stat) =>
      dispatch({
        type: actionTypes.UPDATE_HIGHLIGHT,
        highlight: stat,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Duplicates);
