export const TEST = "TEST";

export const MENU_LIST = "MENU_LIST";
export const GET_MENU_LIST = "GET_MENU_LIST";

export const SET_MENU_IMAGE = "SET_MENU_IMAGE";
export const DELETE_MENU_IMAGE = "DELETE_MENU_IMAGE";
export const MENU_IMAGE_SRC = "MENU_IMAGE_SRC";

export const MENU_PREVIEW_SRC = "MENU_PREVIEW_SRC";
