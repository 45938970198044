import axios from "../../axios";
import * as actionTypes from "../../actionTypes";

export const postMonthData = (monthData, amountData) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_LOADING, loading: "saving" });
    const state = getState();
    const month = state.recommend.calendar.month;
    const year = state.recommend.calendar.year;
    const Lunch = `${year}-${month}-L`;
    const Dinner = `${year}-${month}-D`;
    const showLD = state.recommend.calendar.showLD;
    const displayingDays = state.recommend.selection.displayingDays;
    const lunchData = {};
    const dinnerData = {};

    for (const i in displayingDays) {
      const idx = displayingDays[i];
      lunchData[idx] = monthData[Lunch][idx];
      dinnerData[idx] = monthData[Dinner][idx];
    }

    let currentMonthData = {};

    if (showLD === "LD") {
      currentMonthData = {
        [Lunch]: lunchData,
        [Dinner]: dinnerData,
      };
    } else if (showLD === "L") {
      currentMonthData = { [Lunch]: lunchData };
    } else if (showLD === "D") {
      currentMonthData = { [Dinner]: dinnerData };
    }

    axios
      .post("monthdata", { userId: 1, monthdata: currentMonthData, amountData })
      .then((response) => {
        alert("식단 데이터가 저장되었습니다");
        dispatch({ type: actionTypes.UPDATE_LOADING, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const recSelectedDays = (numDays) => {
  return async (dispatch, getState) => {
    const state = getState();
    const config = state.recommend.user.config;
    const month = state.recommend.calendar.month;
    const year = state.recommend.calendar.year;
    const monthData = state.recommend.user.monthData;
    const amountData = state.recommend.user.amountData;
    const selectedDays = state.recommend.selection.selectedDays;
    const showLD = state.recommend.calendar.showLD;
    // const startDownload = state.recommend.user.startDownload;

    const response = await axios.get("recommend", {
      params: {
        sidedish_num: config.sidedish_num,
        dessert_num: config.dessert_num,
        numDays: numDays,
        calories: config.target_calories,
        price: config.meal_price,
      },
    });
    const data = response.data;
    const Lunch = `${year}-${month}-L`;
    const Dinner = `${year}-${month}-D`;

    let lunchMonthData = monthData[Lunch];
    let lunchAmountData = amountData ? amountData[Lunch] : {};
    let dinnerMonthData = monthData[Dinner];
    let dinnerAmountData = amountData ? amountData[Dinner] : {};

    let newMonthData = {};
    let newAmountData = {};
    let lunchLength = 0;

    if (showLD === "L") {
      const lunchDays = selectedDays["L"];
      lunchLength = lunchDays.length;

      for (var i = 0; i < lunchLength; i++) {
        lunchMonthData[lunchDays[i]] = data["menuList"][String(i + 1)];
        lunchAmountData[lunchDays[i]] = data["amountList"][String(i + 1)];
      }

      newMonthData = {
        ...monthData,
        [Lunch]: lunchMonthData,
      };

      newAmountData = {
        ...amountData,
        [Lunch]: lunchAmountData,
      };
    }
    if (showLD === "D") {
      const dinnerDays = selectedDays["D"];
      const dinnerLength = dinnerDays.length;

      for (var i = 0; i < dinnerLength; i++) {
        dinnerMonthData[dinnerDays[i]] = data["menuList"][String(i + 1)];
        dinnerAmountData[dinnerDays[i]] = data["amountList"][String(i + 1)];
      }

      newMonthData = {
        ...monthData,
        [Dinner]: dinnerMonthData,
      };

      newAmountData = {
        ...amountData,
        [Dinner]: dinnerAmountData,
      };
    }
    if (showLD === "D") {
      const dinnerDays = selectedDays["D"];
      const dinnerLength = dinnerDays.length;

      if (showLD === "LD") {
        const lunchDays = selectedDays["L"];
        const dinnerDays = selectedDays["D"];
        const lunchLength = lunchDays.length;
        const dinnerLength = dinnerDays.length;

        for (var i = 0; i < lunchLength; i++) {
          lunchMonthData[lunchDays[i]] = data["menuList"][String(i + 1)];
          lunchAmountData[lunchDays[i]] = data["amountList"][String(i + 1)];
        }

        for (var i = 0; i < dinnerLength; i++) {
          dinnerMonthData[dinnerDays[i]] =
            data["menuList"][String(i + lunchLength + 1)];
          dinnerAmountData[dinnerDays[i]] =
            data["amountList"][String(i + lunchLength + 1)];
        }

        newMonthData = {
          ...monthData,
          [Lunch]: lunchMonthData,
          [Dinner]: dinnerMonthData,
        };

        newAmountData = {
          ...amountData,
          [Lunch]: lunchAmountData,
          [Dinner]: dinnerAmountData,
        };
      }

      dispatch({ type: actionTypes.UPDATE_MONTHDATA, monthData: newMonthData });
      dispatch({
        type: actionTypes.UPDATE_AMOUNT_DATA,
        amountData: newAmountData,
      });

      dispatch({
        type: actionTypes.UPDATE_START_DOWNLOAD,
      });
    }
  };
};
export const recMonthData = (BLD) => {
  return async (dispatch, getState) => {
    const state = getState();
    const config = state.recommend.user.config;
    const year = state.recommend.calendar.year;
    const month = state.recommend.calendar.month;

    const response = await axios.get("recommend", {
      params: {
        affiliation_type: config.affiliation_type,
        sidedish_num: config.sidedish_num,
        dessert_num: config.dessert_num,
        calories: config.calories,
        special: config.special,
        price: config.price,
        numDays: 31,
        userEmail: state.recommend.user.userEmail,
      },
    });
    const data = response.data;
    const identifier = `${year}-${month}-${BLD}`;

    const newMonthData = {
      ...state.recommend.user.monthData,
      [identifier]: data["menuList"],
    };
    const newAmountData = {
      ...state.recommend.user.amountData,
      [identifier]: data["amountList"],
    };

    dispatch({ type: actionTypes.UPDATE_MONTHDATA, monthData: newMonthData });
    dispatch({
      type: actionTypes.UPDATE_AMOUNT_DATA,
      amountData: newAmountData,
    });

    dispatch({
      type: actionTypes.UPDATE_START_DOWNLOAD,
    });
  };
};

export const ContinueHandler = () => {
  return async (dispatch, getState) => {
    const state = getState();

    let newMenuList = state.recommend.selection.menuList;
    let newAmountList = state.recommend.selection.amountList;

    const LD =
      state.recommend.calendar.monthOrWeek === "week"
        ? state.recommend.selection.selectedLD
        : state.recommend.calendar.showLD;

    newMenuList[newMenuList.length] = `(${Math.trunc(
      state.recommend.calendar.totalNutrition.calories
    )}kcal)`;
    const identifier = `${state.recommend.calendar.year}-${
      state.recommend.calendar.month
    }-${LD === "L" || LD === "LD" ? "L" : "D"}`;
    const currentMonthData = state.recommend.user.monthData[identifier];
    const currentAmountData = state.recommend.user.amountData[identifier];

    currentMonthData[state.recommend.selection.selectedDay] = newMenuList.join(
      "-"
    );

    const newAmountJson = {};
    for (var i = 0; i < newAmountList.length; i++) {
      newAmountJson[newMenuList[i]] = Number(newAmountList[i]);
    }

    currentAmountData[state.recommend.selection.selectedDay] = newAmountJson;
    const newMonthData = {
      ...state.recommend.user.monthData,
      [String(identifier)]: currentMonthData,
    };

    const newAmountData = {
      ...state.recommend.user.amountData,
      [String(identifier)]: currentAmountData,
    };

    dispatch({ type: actionTypes.UPDATE_MODIFY_STATE });
    dispatch({ type: actionTypes.UPDATE_MONTHDATA, monthData: newMonthData });
    dispatch({
      type: actionTypes.UPDATE_AMOUNT_DATA,
      amountData: newAmountData,
    });
    dispatch({
      type: actionTypes.UPDATE_START_DOWNLOAD,
    });
  };
};

export async function DownloadCSV(
  dayToMenu,
  currentMonth,
  week,
  showLD,
  monthOrWeek
) {
  const response = await axios.post(`download`, {
    data: {
      dayToMenu: dayToMenu,
      currentMonth: currentMonth,
      week: week,
      showLD: showLD,
      monthOrWeek: monthOrWeek,
    },
  });
  return response["data"];
}

export async function getComponentInfo(menuName) {
  let componentInfo = {};
  await axios
    .get("component", { params: { menuName: menuName } })
    .then((data) => {
      componentInfo = data["data"];
    })
    .catch((e) => console.log(e));
  return componentInfo;
}

export async function changeOneFood(targetFood, menuList) {
  let received = {};
  let newFoodName = "";
  let nutrition = {};
  const response = await axios
    .post(
      "replace",
      JSON.stringify({ targetFood: targetFood, menuList: menuList })
    )
    .then((response) => {
      received = JSON.parse(response.data);
      console.log(received);
      newFoodName = received["newFoodName"];
      nutrition = received["nutrition"];
    })
    .catch((e) => console.log(e));
  return [newFoodName, nutrition];
}
