export const inventoryTabs = {
  MANAGING_INGREDIENTS: "MANAGING_INGREDIENTS",
  TOTAL_INGREDIENTS: "TOTAL_INGREDIENTS",
  MONTH_USE: "MONTH_USE",
};

export const DragItemTypes = {
  INGREDIENT_CARD: "INGREDIENT_CARD",
  ingredientCardCategoryOf: (categoryId) => `INGREDIENT_CARD_${categoryId}`,
};

export const IngredientCardOrderingMode = {
  CUSTOM: { name: "CUSTOM", label: "사용자 정의 정렬" },
  CURRENT_STOCK: { name: "CURRENT_STOCK", label: "재고량 적은 순 정렬" },
};

export const DASHBOARD_ONLY = [
  10,
  11,
  15,
  22,
  8,
  9,
  12,
  13,
  14,
  18,
  19,
  20,
  23,
  24,
  27,
  28,
  29,
  30,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
];
export const SHOW_POINT_MANAGEMENT = [11, 22];
