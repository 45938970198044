import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "data";

import { dashboardColor } from "theme/colors";
import { BorderedBox } from "components/Styled";

import TitleBox from "./TitleBox";
import NutritionInfo from "./NutritionInfo";
import Menu from "./Menu";
import LeftOvers from "./LeftOvers";
import SearchBox from "./SearchBox";
import moment from "moment";

const nuviLogo = process.env.PUBLIC_URL + "/images/nuviLogo_narrow.svg";
const fullScreenImg = process.env.PUBLIC_URL + "/icons/full_screen.svg";
const smallScreenImg = process.env.PUBLIC_URL + "/icons/small_screen.svg";

export default function DashboardComp() {
  const dispatch = useDispatch();
  const menuList = useSelector(selectors.dashboard.getMenuList);

  const [itemCalories, setItemCalories] = useState({});
  const [date, setDate] = useState(new Date());
  const [bld, setBld] = useState({ name: "중식", value: "L" });
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isInFakeView, setIsInFakeView] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [showSodium, setShowSodium] = useState(true);

  const handleDate = (date) => {
    setDate(date);
  };

  const handleBld = (bld) => {
    setBld(bld);
  };

  const handleEditMode = (boolean) => {
    setIsInEditMode(boolean);
  };

  const handleFakeView = (boolean) => {
    setIsInFakeView(boolean);
  };

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleSodium = () => {
    setShowSodium(!showSodium);
  };

  const calorieHandler = (idx, value) => {
    let obj = itemCalories;
    obj[idx] = value;
    setItemCalories(obj);
  };

  useEffect(() => {
    const data = {
      bld: bld.value,
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
    };
    dispatch(actions.dashboard.getMenuList(data));
  }, [date, bld, isInFakeView, isInEditMode, showSodium]);

  return (
    <ColumnWrapper>
      {!fullScreen && (
        <SearchBox
          setItemCalories={setItemCalories}
          handleDate={handleDate}
          handleBld={handleBld}
          handleEditMode={handleEditMode}
          handleFakeView={handleFakeView}
          handleSodium={handleSodium}
          date={date}
          bld={bld}
          isInEditMode={isInEditMode}
          isInFakeView={isInFakeView}
          showSodium={showSodium}
        />
      )}
      <DashboardContainer fullScreen={fullScreen}>
        <ToggleScreen
          src={fullScreen ? smallScreenImg : fullScreenImg}
          alt="toggleScreen"
          onClick={handleFullScreen}
        />
        <DashBoardInnerContainer>
          <FirstSubCotainer>
            <FirstInnerContainer>
              <TitleBox date={date} />
            </FirstInnerContainer>
            <SecondInnerContainer>
              <NutritionInfo
                menuList={menuList}
                itemCalories={itemCalories}
                isInFakeView={isInFakeView}
              />
            </SecondInnerContainer>
          </FirstSubCotainer>
          <SecondSubCotainer>
            <Menu
              itemCalories={itemCalories}
              calorieHandler={calorieHandler}
              isInFakeView={isInFakeView}
              isInEditMode={isInEditMode}
              showSodium={showSodium}
            />
          </SecondSubCotainer>
          {/* <ThirdSubCotainer>
            <LeftOvers />
          </ThirdSubCotainer> */}
        </DashBoardInnerContainer>
      </DashboardContainer>
      {/* <NuviLabelConatiner>
        <NuviLabel style={{ color: "gray", fontWeight: 400 }}>
          together with
        </NuviLabel>
        <NuviLabel> NUVI labs</NuviLabel>
        <NuviLogo src={nuviLogo} alt="logo" />
      </NuviLabelConatiner> */}
    </ColumnWrapper>
  );
}

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  border-radius: 10px;

  background-color: ${dashboardColor.light_blue};
  position: relative;

  height: ${({ fullScreen }) => (fullScreen ? "100vh" : "100%;")};

  width: ${({ fullScreen }) => (fullScreen ? "100%" : "100%;")};

  @media (max-width: 100px) {
    width: 100%;
    flex-direction: column;
    overflow: scroll;
  }
`;

const DashBoardInnerContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 10px;
  width: 100%;
  @media (max-width: 1120px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
  }
`;

const FirstSubCotainer = styled(BorderedBox)`
  display: flex;
  flex-direction: column;
  position: relative;

  flex: 1;
  background-color: ${dashboardColor.light_blue};
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  align-items: center;
  max-height: 100vh;
  overflow: hide;
  @media (max-width: 1120px) {
    width: 100%;
  }
`;

const FirstInnerContainer = styled.div`
  width: 95%;
`;

const SecondInnerContainer = styled.div`
  width: 100%;
  height: auto;
`;

const SecondSubCotainer = styled(BorderedBox)`
  display: flex;
  flex: 3;
  background-color: none;
  max-height: 100vh;
  @media (max-width: 1120px) {
    margin-top: 30px;
    width: 95%;
  }
`;

const ThirdSubCotainer = styled(BorderedBox)`
  display: flex;
  flex: 1;
`;

const NuviLabelConatiner = styled.div`
  display: flex;
  align-self: flex-end;
  font-size: 23px;
  color: ${dashboardColor.gray_8};

  font-weight: 300;
  align-items: center;
`;

const NuviLabel = styled.div`
  margin: 5px;
  font-weight: bold;
  font-size: 1.5rem;
  color: ${dashboardColor.blue};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 97vh;
  font-family: Noto Sans KR, sans-serif;

  background-color: none;
  justify-content: center;

  padding: 0px;
`;

const ToggleScreen = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  cursor: pointer;
  @media (max-width: 1120px) {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    cursor: pointer;
    z-index: 1;
  }
`;

const NuviLogo = styled.img`
  background-color: none;
`;
