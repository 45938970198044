import axios from "data/recommendation/axios";

export const getIngredientTable = (pageNum, itemNum) => {
  const url = `/ingredient/${pageNum}/${itemNum}`;
  const promise = axios.get(url);
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};

export const getFoodTable = (pageNum, itemNum) => {
  const url = `/food/${pageNum}/${itemNum}`;
  const promise = axios.get(url);
  const dataPromise = promise.then((response) => response.data);
  return dataPromise;
};
