export const UPDATE_HIGHLIGHT = "UPDATE_HIGHLIGHT";
export const UPDATE_HIGHLIGHT_STATE = "UPDATE_HIGHLIGHT_STATE";
export const INCREASE_WEEK = "INCREASE_WEEK";
export const DECREASE_WEEK = "DECREASE_WEEK";
export const INCREASE_LAST_WEEK = "INCREASE_LAST_WEEK";
export const DECREASE_FIRST_WEEK = "DECREASE_FIRST_WEEK";
export const INCREASE_MONTH = "INCREASE_MONTH";
export const DECREASE_MONTH = "DECREASE_MONTH";
export const INCREASE_YEAR = "INCREASE_YEAR";
export const DECREASE_YEAR = "DECREASE_YEAR";
export const UPDATE_MONTHDATA = "UPDATE_MONTHDATA";
export const UPDATE_AMOUNT_DATA = "UPDATE_AMOUNT_DATA";
export const UPDATE_WEEK = "UPDATE_WEEK";
export const UPDATE_MONTH = "UPDATE_MONTH";
export const UPDATE_MONTH_DIFF = "UPDATE_MONTH_DIFF";
export const UPDATE_YEAR = "UPDATE_YEAR";
export const UPDATE_MODIFY_STATE = "UPDATE_MODIFY_STATE";
export const UPDATE_VERSION = "UPDATE_VERSION";
export const UPDATE_SELECTED_DAY = "UPDATE_SELECTED_DAY";
export const UPDATE_SELECTED_DAYS = "UPDATE_SELECTED_DAYS";
export const UPDATE_SELECTED_LD = "UPDATE_SELECTED_LD";
export const UPDATE_SHOW_LD = "UPDATE_SHOW_LD";
export const UPDATE_MENU_TYPE = "UPDATE_MENU_TYPE";
export const RESET_SELECTED_ON = "RESET_SELECTED_ON";
export const RESET_SELECTED_OFF = "RESET_SELECTED_OFF";
export const UPDATE_SHOW_CALENDAR = "UPDATE_SHOW_CALENDAR";
export const UPDATE_ALL_SELECTED = "UPDATE_ALL_SELECTED";
export const UPDATE_DATE_LIST = "UPDATE_DATE_LIST";
export const RESET_DISPLAYING_DAYS = "RESET_DISPLAYING_DAYS";
export const UPDATE_DISPLAYING_DAYS = "UPDATE_DISPLAYING_DAYS";
export const UPDATE_SELECT_STATE = "UPDATE_SELECT_STATE";
export const UPDATE_USER_NAME = "UPDATE_USER_NAME";
export const UPDATE_MENULIST = "UPDATE_MENULIST";
export const UPDATE_AMOUNT_LIST = "UPDATE_AMOUNT_LIST";
export const UPDATE_TOTAL_NUTRITION = "UPDATE_TOTAL_NUTRITION";
export const UPDATE_MONTH_OR_WEEK = "UPDATE_MONTH_OR_WEEK";
export const UPDATE_CONFIG = "UPDATE_CONFIG";
export const UPDATE_INITIALIZED = "UPDATE_INITIALIZED";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const UPDATE_START_DOWNLOAD = "UPDATE_START_DOWNLOAD";
export const UPDATE_USER_INPUT_DATA = "UPDATE_USER_INPUT_DATA";
