import * as AT from "data/rootActionTypes";

export const test = payload => ({
  type: AT.TEST,
  payload
});

export const setUserList = payload => ({
  type: AT.USER_LIST,
  payload
});

export const getUserList = payload => ({
  type: AT.GET_USER_LIST,
  payload
});

export const getIndividualPoint = payload => ({
  type: AT.GET_INDIVIDUAL_POINT,
  payload
});

export const setIndividualPoint = payload => ({
  type: AT.INDIVIDUAL_POINT,
  payload
});

export const updatePoint = payload => ({
  type: AT.UPDATE_POINT,
  payload
});

export const getSKUserParticipation = payload => ({
  type: AT.GET_SK_USER_PARTICIPATION,
  payload
});

export const setSKUserParticipation = payload => ({
  type: AT.SK_USER_PARTICIPATION,
  payload
});
