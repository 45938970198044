import React, { useState } from "react";
import { Formik, Form, useField } from "formik";

import styled from "styled-components";

// axios
import { url } from "api/client";
import axios from "axios";

const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [disabled, setDisabled] = useState(true);

  const handleDisabled = () => {
    setDisabled(!disabled);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        type="checkbox"
        onClick={handleDisabled}
        style={{ marginRight: "5px" }}
      />
      <StyledInput
        {...field}
        {...props}
        style={{ width: "200px" }}
        value={field.value || ""}
        disabled={disabled}
      />
    </div>
  );
};

const SettingBox = ({
  checked,
  resetCheckedItems,
  handleScannerModalOpen,
  scannerSettings,
  getScannerSettings,
  getJsonScannerList,
}) => {
  if (!checked[0]) {
    return (
      <SettingContainer>
        <Title>스캐너 세팅</Title>
        <div>스캐너를 선택해주세요</div>
      </SettingContainer>
    );
  } else if (!scannerSettings && checked[0]) {
    return (
      <SettingContainer>
        <Title>스캐너 세팅</Title>
        <div>s3에 스캐너 설정값이 존재하지 않습니다</div>
        {/* <div>
          <button styled={{ width: "50%" }} onClick={handleScannerModalOpen}>
            설정값 추가하기
          </button>
        </div> */}
      </SettingContainer>
    );
  }

  //getScannerSettings로 불러온 정보를 scannerSettings에 저장하고 사용. 하지만, 새로운 스캐너를 조회했을때 정보가없는것도 이전에 state값이 남아있어서 저장된 셋팅정보가없다는 alert와 함께 state값들이 불러와짐.
  const {
    ba,
    endpoint,
    frontcam,
    face,
    realsense,
    save,
    scanner_id,
    time,
    mode,
    ui,
  } = scannerSettings;

  // console.log("this is scanner setting: ", scannerSettings);

  const initialValues = {
    frontcam: {
      resolution_w: "",
      resolution_h: "",
      roi_xmin: "",
      roi_ymin: "",
      roi_xmax: "",
      roi_ymax: "",
    },
    realsense: {
      resolution_w: "",
      resolution_h: "",
      roi_xmin: "",
      roi_ymin: "",
      roi_xmax: "",
      roi_ymax: "",
      depth_detector: {
        left_area_xmin: "",
        left_area_xmax: "",
        right_area_xmin: "",
        right_area_xmax: "",
        top_area_ymin: "",
        top_area_ymax: "",
        bottom_area_ymin: "",
        bottom_area_ymax: "",
        min_count_for_stability: "",
        turn_off_delay_sec: "",
        plate_depth_min: "",
        plate_depth_max: "",
        depth_change_min: "",
        iter_to_init: "",
        check_value_for_reset: "",
        point_min_count: "",
        point_max_count: "",
      },
    },
    face: {
      min_face_size: "",
      mask_off: "",
      delay_to_look_front: "",
      face_view: "",
      db_sync: "",
    },
    time: {
      bld_division: {
        bl: "",
        ld: "",
        dn: "",
      },
      use_off_func: "",
      offtime: "",
    },
    endpoint: {
      call_endpoint: "",
      manager_ip: "",
      breakfast_start: "",
      breakfast_end: "",
      lunch_start: "",
      lunch_end: "",
      dinner_start: "",
      dinner_end: "",
      compress_data: "",
    },
    ba: {
      ba: "",
      force: "",
    },
    scanner_id: {
      scanner_id: "",
      force: "",
    },
    save: {
      compress_data: "",
    },
    mode: {
      identification: "",
    },
    ui: {
      result_to_init_delay: "",
      identification_to_init_delay: "",
      waiting_face_gt_to_init_delay: "",
      display_point: "",
      play_roulette_at_before: "",
      play_roulette_at_after: "",
      default_gif_index: "",
      download_daily_texts: "",
      daycare_random_ui: "",
      src_url: {
        privacy_agreement_face_recognition: "",
        privacy_agreement_rfid: "",
        charge_guide: "",
        wifi_guide: "",
        rfid_guide: "",
        realsense_guide: "",
        gif_zip: "",
        default_gif_zip: "",
        roulette_gif: "",
        green_point_10_gif: "",
        green_point_50_gif: "",
        green_point_100_gif: "",
        green_point_500_gif: "",
        culture_voucher_2000_gif: "",
        culture_voucher_3000_gif: "",
        culture_voucher_5000_gif: "",
        icon_green_level_1: "",
        icon_green_level_2: "",
        icon_green_level_3: "",
        icon_green_level_4: "",
        icon_green_level_5: "",
      },
    },
  };

  const updateScannerSettings = async (values, { resetForm }) => {
    if (window.confirm("설정을 업데이트하시겠습니까?")) {
      let scanners = [],
        checkedDepartments = [];

      // 전체선택된 department가 있을 경우 checkedDepartment에 dep id를 넣는다. (전체 체크일 경우 'all')
      for (let i = 0; i < checked.length; i++) {
        if (checked[i] === "All") {
          checkedDepartments.push(checked[i]);
        } else if (typeof JSON.parse(checked[i]) !== "object") {
          checkedDepartments.push(checked[i]);
        } else {
          scanners.push(checked[i]);
        }
      }
      // if (checkedDepartments.includes("All")) {
      //   checkedDepartments = ["All"];
      // }

      const data = {
        scanners,
        checkedDepartments,
        settings: values,
      };
      // console.log("setting:", data);

      const res = await axios.patch(`${url}/api/scanner/settings`, data, {
        withCredentials: true,
      });
      // console.log("update result: ", res.data);
      alert("변경되었습니다");

      getJsonScannerList();
      getScannerSettings();
      resetForm({});
      // resetCheckedItems();
    }
  };

  return (
    <SettingContainer>
      <Title>스캐너 세팅</Title>
      <div>선택된 스캐너: {scannerSettings?.scanner_id?.scanner_id}</div>
      {scannerSettings ? (
        <Formik
          initialValues={initialValues}
          onSubmit={updateScannerSettings}
          enableReinitialize
        >
          {({ values }) => (
            <StyledForm>
              <SubmitContainer>
                <SubmitButton type="submit">설정 업데이트</SubmitButton>
              </SubmitContainer>
              <GridContainer>
                <MenuCategoryContainer>
                  <MenuCategoryName>frontcam</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>resolution_w</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.resolution_w"
                      placeholder={frontcam.resolution_w}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>resolution_h</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.resolution_h"
                      placeholder={frontcam.resolution_h}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_xmin</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.roi_xmin"
                      placeholder={frontcam.roi_xmin}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_ymin</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.roi_ymin"
                      placeholder={frontcam.roi_ymin}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_xmax</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.roi_xmax"
                      placeholder={frontcam.roi_xmax}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_ymax</MenuName>
                    <InputField
                      type="number"
                      name="frontcam.roi_ymax"
                      placeholder={frontcam.roi_ymax}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>realsense</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>resolution_w</MenuName>
                    <InputField
                      type="number"
                      name="realsense.resolution_w"
                      placeholder={realsense.resolution_w}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>resolution_h</MenuName>
                    <InputField
                      type="number"
                      name="realsense.resolution_h"
                      placeholder={realsense.resolution_h}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_xmin</MenuName>
                    <InputField
                      type="number"
                      name="realsense.roi_xmin"
                      placeholder={realsense.roi_xmin}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_ymin</MenuName>
                    <InputField
                      type="number"
                      name="realsense.roi_ymin"
                      placeholder={realsense.roi_ymin}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_xmax</MenuName>
                    <InputField
                      type="number"
                      name="realsense.roi_xmax"
                      placeholder={realsense.roi_xmax}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>roi_ymax</MenuName>
                    <InputField
                      type="number"
                      name="realsense.roi_ymax"
                      placeholder={realsense.roi_ymax}
                    />
                  </MenuContainer>
                  <SubCategoryContainer>
                    <SubCategoryName>depth_detector</SubCategoryName>
                    <MenuContainer>
                      <MenuName>left_area_xmin</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.left_area_xmin"
                        placeholder={realsense.depth_detector.left_area_xmin}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>left_area_xmax</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.left_area_xmax"
                        placeholder={realsense.depth_detector.left_area_xmax}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>right_area_xmin</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.right_area_xmin"
                        placeholder={realsense.depth_detector.right_area_xmin}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>right_area_xmax</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.right_area_xmax"
                        placeholder={realsense.depth_detector.right_area_xmax}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>top_area_ymin</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.top_area_ymin"
                        placeholder={realsense.depth_detector.top_area_ymin}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>top_area_ymax</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.top_area_ymax"
                        placeholder={realsense.depth_detector.top_area_ymax}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>bottom_area_ymin</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.bottom_area_ymin"
                        placeholder={realsense.depth_detector.bottom_area_ymin}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>bottom_area_ymax</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.bottom_area_ymax"
                        placeholder={realsense.depth_detector.bottom_area_ymax}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>min_count_for_stability</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.min_count_for_stability"
                        placeholder={
                          realsense.depth_detector.min_count_for_stability
                        }
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>turn_off_delay_sec</MenuName>
                      <InputField
                        type="number"
                        step="0.01"
                        name="realsense.depth_detector.turn_off_delay_sec"
                        placeholder={
                          realsense.depth_detector.turn_off_delay_sec
                        }
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>plate_depth_min</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.plate_depth_min"
                        placeholder={realsense.depth_detector.plate_depth_min}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>plate_depth_max</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.plate_depth_max"
                        placeholder={realsense.depth_detector.plate_depth_max}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>depth_change_min</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.depth_change_min"
                        placeholder={realsense.depth_detector.depth_change_min}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>iter_to_init</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.iter_to_init"
                        placeholder={realsense.depth_detector.iter_to_init}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>check_value_for_reset</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.check_value_for_reset"
                        placeholder={
                          realsense.depth_detector.check_value_for_reset
                        }
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>point_min_count</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.point_min_count"
                        placeholder={realsense.depth_detector.point_min_count}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>point_max_count</MenuName>
                      <InputField
                        type="number"
                        name="realsense.depth_detector.point_max_count"
                        placeholder={realsense.depth_detector.point_max_count}
                      />
                    </MenuContainer>
                  </SubCategoryContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>face</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>min_face_size</MenuName>
                    <InputField
                      type="number"
                      name="face.min_face_size"
                      placeholder={face.min_face_size}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>mask_off</MenuName>
                    <InputField
                      type="text"
                      name="face.mask_off"
                      placeholder={face.mask_off}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>delay_to_look_front</MenuName>
                    <InputField
                      type="number"
                      name="face.delay_to_look_front"
                      placeholder={face.delay_to_look_front}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>face_view</MenuName>
                    <InputField
                      type="number"
                      name="face.face_view"
                      placeholder={face.face_view}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>db_sync</MenuName>
                    <InputField
                      type="number"
                      name="face.db_sync"
                      placeholder={face.db_sync}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>time</MenuCategoryName>
                  <SubCategoryContainer>
                    <SubCategoryName>bld division</SubCategoryName>
                    <MenuContainer>
                      <MenuName>bl</MenuName>
                      <MenuValue>
                        기본설정시간: {time.bld_division.bl}
                      </MenuValue>
                      <InputField
                        type="time"
                        name="time.bld_division.bl"
                        placeholder={time.bld_division.bl}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>ld</MenuName>
                      <MenuValue>
                        기본설정시간: {time.bld_division.ld}
                      </MenuValue>

                      <InputField
                        type="time"
                        name="time.bld_division.ld"
                        placeholder={time.bld_division.ld}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>dn</MenuName>
                      <MenuValue>
                        기본설정시간: {time.bld_division.dn}
                      </MenuValue>

                      <InputField
                        type="time"
                        name="time.bld_division.dn"
                        placeholder={time.bld_division.dn}
                      />
                    </MenuContainer>
                  </SubCategoryContainer>
                  <MenuContainer>
                    <MenuName>use_off_func</MenuName>
                    <InputField
                      type="number"
                      name="time.use_off_func"
                      placeholder={time.use_off_func}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>offtime</MenuName>
                    <MenuValue>기본설정시간: {time.offtime}</MenuValue>

                    <InputField
                      type="time"
                      name="time.offtime"
                      placeholder={time.offtime}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>endpoint</MenuCategoryName>
                  <MenuContainer>
                    <MenuContainer>
                      <MenuName>call_endpoint</MenuName>
                      <InputField
                        type="number"
                        name="endpoint.call_endpoint"
                        placeholder={endpoint.call_endpoint}
                      />
                    </MenuContainer>

                    <MenuContainer>
                      <MenuName>manager_ip</MenuName>
                      <InputField
                        type="text"
                        name="endpoint.manager_ip"
                        placeholder={endpoint.manager_ip}
                      />
                    </MenuContainer>

                    <MenuName>breakfast_start</MenuName>
                    <MenuValue>
                      기본설정시간: {endpoint.breakfast_start}
                    </MenuValue>

                    <InputField
                      type="time"
                      name="endpoint.breakfast_start"
                      placeholder={endpoint.breakfast_start}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>breakfast_end</MenuName>
                    <MenuValue>
                      기본설정시간: {endpoint.breakfast_end}
                    </MenuValue>
                    <InputField
                      type="time"
                      name="endpoint.breakfast_end"
                      placeholder={endpoint.breakfast_end}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>lunch_start</MenuName>
                    <MenuValue>기본설정시간: {endpoint.lunch_start}</MenuValue>

                    <InputField
                      type="time"
                      name="endpoint.lunch_start"
                      placeholder={endpoint.lunch_start}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>lunch_end</MenuName>
                    <MenuValue>기본설정시간: {endpoint.lunch_end}</MenuValue>

                    <InputField
                      type="time"
                      name="endpoint.lunch_end"
                      placeholder={endpoint.lunch_end}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>dinner_start</MenuName>
                    <MenuValue>기본설정시간: {endpoint.dinner_start}</MenuValue>
                    <InputField
                      type="time"
                      name="endpoint.dinner_start"
                      placeholder={endpoint.dinner_start}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>dinner_end</MenuName>
                    <MenuValue>기본설정시간: {endpoint.dinner_end}</MenuValue>

                    <InputField
                      type="time"
                      name="endpoint.dinner_end"
                      placeholder={endpoint.dinner_end}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>compress_data</MenuName>
                    <InputField
                      type="text"
                      name="endpoint.compress_data"
                      placeholder={endpoint.compress_data}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>BA</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>ba </MenuName>
                    <InputField name="ba.ba" type="text" placeholder={ba.ba} />
                  </MenuContainer>

                  <MenuContainer>
                    <MenuName>force </MenuName>
                    <InputField
                      name="ba.force"
                      type="number"
                      placeholder={ba.force}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>scanner_id</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>scanner_id</MenuName>
                    <input disabled placeholder={scanner_id.scanner_id} />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>force</MenuName>
                    <InputField
                      name="scanner_id.force"
                      type="number"
                      placeholder={scanner_id.force}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>save</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>compress_data</MenuName>
                    <InputField
                      name="save.compress_data"
                      type="number"
                      placeholder={save.compress_data}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>mode</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>identification</MenuName>
                    <InputField
                      name="mode.identification"
                      placeholder={mode.identification}
                    />
                  </MenuContainer>
                </MenuCategoryContainer>

                <MenuCategoryContainer>
                  <MenuCategoryName>ui</MenuCategoryName>
                  <MenuContainer>
                    <MenuName>result_to_init_delay</MenuName>
                    <InputField
                      name="ui.result_to_init_delay"
                      type="number"
                      placeholder={ui.result_to_init_delay}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>identification_to_init_delay</MenuName>
                    <InputField
                      name="ui.identification_to_init_delay"
                      type="number"
                      placeholder={ui.identification_to_init_delay}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>display_point</MenuName>
                    <InputField
                      name="ui.display_point"
                      type="number"
                      placeholder={ui.display_point}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>play_roulette_at_before</MenuName>
                    <InputField
                      name="ui.play_roulette_at_before"
                      type="number"
                      placeholder={ui.play_roulette_at_before}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>play_roulette_at_after</MenuName>
                    <InputField
                      name="ui.play_roulette_at_after"
                      type="number"
                      placeholder={ui.play_roulette_at_after}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>default_gif_index</MenuName>
                    <InputField
                      name="ui.default_gif_index"
                      type="number"
                      placeholder={ui.default_gif_index}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>download_daily_texts</MenuName>
                    <InputField
                      name="ui.download_daily_texts"
                      type="text"
                      placeholder={ui.download_daily_texts}
                    />
                  </MenuContainer>
                  <MenuContainer>
                    <MenuName>daycare_random_ui</MenuName>
                    <InputField
                      name="ui.daycare_random_ui"
                      type="number"
                      max={1}
                      min={0}
                      placeholder={ui.daycare_random_ui}
                    />
                  </MenuContainer>
                  <SubCategoryContainer>
                    <SubCategoryName>src_url</SubCategoryName>
                    <MenuContainer>
                      <MenuName>privacy_agreement_face_recognition</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.privacy_agreement_face_recognition"
                        placeholder={
                          ui.src_url.privacy_agreement_face_recognition
                        }
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>privacy_agreement_rfid</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.privacy_agreement_rfid"
                        placeholder={ui.src_url.privacy_agreement_rfid}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>charge_guide</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.charge_guide"
                        placeholder={ui.src_url.charge_guide}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>wifi_guide</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.wifi_guide"
                        placeholder={ui.src_url.wifi_guide}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>rfid_guide</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.rfid_guide"
                        placeholder={ui.src_url.rfid_guide}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>realsense_guide</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.realsense_guide"
                        placeholder={ui.src_url.realsense_guide}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>gif_zip</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.gif_zip"
                        placeholder={ui.src_url.gif_zip}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>default_gif_zip</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.default_gif_zip"
                        placeholder={ui.src_url.default_gif_zip}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>roulette_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.roulette_gif"
                        placeholder={ui.src_url.roulette_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>green_point_10_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.green_point_10_gif"
                        placeholder={ui.src_url.green_point_10_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>green_point_50_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.green_point_50_gif"
                        placeholder={ui.src_url.green_point_50_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>green_point_100_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.green_point_100_gif"
                        placeholder={ui.src_url.green_point_100_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>culture_voucher_2000_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.culture_voucher_2000_gif"
                        placeholder={ui.src_url.culture_voucher_2000_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>culture_voucher_3000_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.culture_voucher_3000_gif"
                        placeholder={ui.src_url.culture_voucher_3000_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>culture_voucher_5000_gif</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.culture_voucher_5000_gif"
                        placeholder={ui.src_url.culture_voucher_5000_gif}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>icon_green_level_1</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.icon_green_level_1"
                        placeholder={ui.src_url.icon_green_level_1}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>icon_green_level_2</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.icon_green_level_2"
                        placeholder={ui.src_url.icon_green_level_2}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>icon_green_level_3</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.icon_green_level_3"
                        placeholder={ui.src_url.icon_green_level_3}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>icon_green_level_4</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.icon_green_level_4"
                        placeholder={ui.src_url.icon_green_level_4}
                      />
                    </MenuContainer>
                    <MenuContainer>
                      <MenuName>icon_green_level_5</MenuName>
                      <InputField
                        type="text"
                        name="ui.src_url.icon_green_level_5"
                        placeholder={ui.src_url.icon_green_level_5}
                      />
                    </MenuContainer>
                  </SubCategoryContainer>
                </MenuCategoryContainer>
              </GridContainer>
            </StyledForm>
          )}
        </Formik>
      ) : (
        <div>데이터 없습니다.</div>
      )}
    </SettingContainer>
  );
};

export default SettingBox;

const MenuCategoryContainer = styled.div`
  width: 100%;
  border: 2px solid lightgray;
  margin: 10px;
  padding: 10px;
`;

const MenuCategoryName = styled.h2`
  margin-bottom: 5px;
`;

const SubCategoryContainer = styled.div`
  width: 100%;
  border: 3px dashed lightgray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
`;

const SubCategoryName = styled.div`
  font-size: 1.2rem;
  color: gray;
  margin-bottom: 5px;
`;

const MenuContainer = styled.div`
  border: 1px solid white;
  justify-content: center;
`;

const MenuName = styled.h3``;
const MenuValue = styled.div`
  color: gray;
`;

const SettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  text-align: center;
`;

const StyledForm = styled(Form)`
  width: 100%;
  padding: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;

const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmitButton = styled.button`
  width: 200px;
  height: 2rem;
  margin: 5px;
  padding: 3px;
  border: 1px solid black;
  border-radius: 3px;
  background-color: white;
  font-size: 1.1rem;
  cursor: pointer;
`;

const Title = styled.h2`
  margin-top: 10px;
  margin-left: 10px;
  border: 1px solid white;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 25px;
  border: 1.2px solid gray;
  border-radius: 3px;
  background-color: white;
`;
