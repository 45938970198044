import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { yellow } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import * as actionTypes from "../../../../data/recommendation/actionTypes";
import { convertDuplicates, findDuplicateClass } from "./helper";
import "./index.css";
import Duplicates from "../../Statistics/Duplicates";

const GreenCheckbox = withStyles({
  root: {
    color: yellow[400],
    "&$checked": {
      color: yellow[600],
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    style={{ width: 100, height: 100 }}
    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 30 }} />}
    checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
    color="default"
    {...props}
  />
));

const GetWeekTable = (props) => {
  const days = [];
  const weekDays = [];
  props.commandList.forEach((command) => {
    days.push(command.slice(command.indexOf("(") + 1, command.indexOf(",")));

    if (command.slice(0, 1) === "m") {
      weekDays.push(
        command.slice(command.indexOf("(") + 1, command.indexOf(","))
      );
    }
  });

  const onlyWeekDays = props.week === 0 && weekDays.length === 0;

  const initState = {};
  const fullState = {};
  const fullStateL = {};
  const fullStateD = {};

  for (var day of days) {
    initState[`L${day}`] = false;
    initState[`D${day}`] = false;
    fullState[`L${day}`] = true;
    fullState[`D${day}`] = true;
    fullStateL[`L${day}`] = true;
    fullStateL[`D${day}`] = false;
    fullStateD[`L${day}`] = false;
    fullStateD[`D${day}`] = true;
  }

  const [state, setState] = useState(initState);
  const showLD = props.showLD;
  const monthData = props.monthData;

  useEffect(() => {
    if (props.resetSelected) {
      setState(initState);
      props.onResetSelectedOff();
    }
  }, [props.resetSelected]);

  useEffect(() => {
    if (onlyWeekDays) {
      props.onUpdateWeek(1);
    }
  }, []);

  useEffect(() => {
    if (!onlyWeekDays) {
      for (var i = 0; i < weekDays.length; i++)
        if (!props.displayingDays.includes(Number(weekDays[i]))) {
          props.onUpdateDisplayingDays(Number(weekDays[i]));
        }
    }
  }, [days]);

  // 전체 선택 시 rerendering하게 만들기
  useEffect(() => {
    const copyState = Object.assign({}, fullState);
    if (props.allSelected) {
      if (showLD === "LD") {
        setState((state) => fullState);
      } else if (showLD === "L") {
        setState((state) => Object.assign(copyState, fullStateL));
      } else if (showLD === "D") {
        setState((state) => Object.assign(copyState, fullStateD));
      }
    } else {
      setState((state) => initState);
    }
  }, [props.allSelected]);

  useEffect(() => {}, [props.highlightState]);

  useEffect(() => {
    props.onUpdateSelectState(state);
  }, [state]);

  if (Object.keys(monthData).length === 0) {
    return "loading..";
  }

  const topblock = (date, day) => {
    let fontStyle = {};
    if (date === 0) {
      fontStyle = { color: "white" };
    } else {
      fontStyle = { color: "black" };
    }

    return (
      <div style={fontStyle} className="calendar__top2">
        {date}
        <div style={{ color: "grey", fontSize: "14px" }}>{day}</div>
      </div>
    );
  };

  const handleChange = (event, startDayCount, LD) => {
    const key = `${LD}${startDayCount}`; //LD + String(startDayCount);
    setState((state) => ({ ...state, [key]: event.target.checked }));
    props.onUpdateSelectedDay(startDayCount);
    props.onUpdateSelectedLD(LD);
    if (!state[key] && !props.modifyState) {
      props.onUpdateModifyState();
    }
  };

  const menuDetail = (elem, startDayCount, LD, todayMenu, calories) => {
    return (
      <div style={{ height: "100%" }}>
        <div style={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <GreenCheckbox
                style={{
                  marginTop: "0px",
                  marginRight: "-15px",
                }}
                checked={elem || false}
                onChange={(event) => handleChange(event, startDayCount, LD)}
                name="checkedG"
              />
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "85%",
          }}
        >
          <div style={{ marginTop: "-25px" }}>{todayMenu}</div>
          <div
            style={{
              borderTop: "3px solid lightgrey",
              fontSize: "16px",
              padding: "",
            }}
          >
            {calories}
          </div>
        </div>
      </div>
    );
  };

  const block1 = () => {
    return (
      <div
        style={{
          maxWidth: "250px",
        }}
        className="calendar_week_day2 "
      ></div>
    );
  };

  const block5 = () => {
    return (
      <div
        style={{
          maxWidth: "250px",
        }}
        className="calendar_week_day2 "
      ></div>
    );
  };

  const mlock1 = (startDayCount, dayMenu, duplicateClass, LD) => {
    let todayMenu = dayMenu.replace(/-/gi, "\n").split("\n");
    const menuLength = todayMenu.length;
    let calories = todayMenu.slice(-1);
    calories = calories[0].replace("(", "").replace(")", "");
    calories = Number(calories.slice(0, -4)).toFixed(0) + calories.slice(-4);
    todayMenu = todayMenu.slice(0, -1).map((line, idx) => (
      <span key={idx}>
        {convertDuplicates(line.slice(0, 8), duplicateClass, idx, menuLength)}
        <br />
      </span>
    ));

    const key = LD + String(startDayCount);
    return (
      <div className="calendar_week_day2">
        {menuDetail(state[key], startDayCount, LD, todayMenu, calories)}
      </div>
    );
  };

  let duplicateClass = { 0: [], 1: [], 2: [], 3: [], 4: [] };

  // 중복되는 밥 메뉴가 있는 경우 실행
  for (var i = 0; i < 5; i++) {
    if (props.highlight[0]) {
      duplicateClass[i] = findDuplicateClass(props.highlight[i], i);
    }
  }

  let lunch = "";
  let dinner = "";

  if (showLD === "LD" || showLD === "L") {
    lunch = (
      <>
        {props.commandList.map((x, idx) => (
          <span key={idx}>
            {x[0] === "b"
              ? eval(x)
              : eval(
                  x.slice(0, -8) +
                    '"' +
                    String(
                      props.dayToMenuLunch[x.slice(7, 9).replace(",", "")]
                    ) + //
                    '"' +
                    "," +
                    "duplicateClass" +
                    ",'L')"
                )}
          </span>
        ))}
      </>
    );
  }

  if (showLD === "LD" || showLD === "D") {
    dinner = (
      <>
        {props.commandList.map((x, idx) => (
          <span key={idx}>
            {x[0] === "b"
              ? eval(x)
              : eval(
                  x.slice(0, -8) +
                    '"' +
                    String(
                      props.dayToMenuDinner[x.slice(7, 9).replace(",", "")]
                    ) + //
                    '"' +
                    "," +
                    "duplicateClass" +
                    ",'D')"
                )}
          </span>
        ))}
      </>
    );
    {
    }
  }
  const Lunch = `${String(props.year)}-${String(props.month)}-L`;
  const Dinner = `${String(props.year)}-${String(props.month)}-D`;

  let lunchData = "";
  let dinnerData = "";
  if (Lunch in props.monthData) {
    lunchData = props.monthData[Lunch];
  }

  if (Dinner in props.monthData) {
    dinnerData = props.monthData[Dinner];
  }

  let Duplicate = "";

  if (props.initialized && (lunchData || dinnerData)) {
    Duplicate = <Duplicates monthData={monthData} />;
  }

  let paddedDays = [];
  for (var i = 0; i < 5 - days.length; i++) {
    paddedDays.push(0);
  }

  if (days.includes("1")) {
    paddedDays = paddedDays.concat(days);
  } else {
    paddedDays = days.concat(paddedDays);
  }

  return (
    <div style={{ textAlign: "center", paddingBottom: "400px" }}>
      {topblock(paddedDays[0], "Mon")}
      {topblock(paddedDays[1], "Tus")}
      {topblock(paddedDays[2], "Wed")}
      {topblock(paddedDays[3], "Thu")}
      {topblock(paddedDays[4], "Fri")}
      {Duplicate}
      {lunch}
      {dinner}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    highlight: state.recommend.highlight.highlight,
    highlightState: state.recommend.highlight.highlightState,
    monthData: state.recommend.user.monthData,
    modifyState: state.recommend.selection.modifyState,
    showLD: state.recommend.calendar.showLD,
    resetSelected: state.recommend.selection.resetSelected,
    allSelected: state.recommend.selection.allSelected,
    displayingDays: state.recommend.selection.displayingDays,
    initialized: state.recommend.user.initialized,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    week: state.recommend.calendar.week,
    loading: state.recommend.selection.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateSelectedDay: (day) =>
      dispatch({
        type: actionTypes.UPDATE_SELECTED_DAY,
        selectedDay: day,
      }),

    onUpdateSelectedLD: (LD) =>
      dispatch({
        type: actionTypes.UPDATE_SELECTED_LD,
        selectedLD: LD,
      }),

    onUpdateModifyState: () =>
      dispatch({
        type: actionTypes.UPDATE_MODIFY_STATE,
      }),

    onResetSelectedOff: (type) => {
      dispatch({
        type: actionTypes.RESET_SELECTED_OFF,
        resetSelected: false,
      });
    },

    onUpdateDisplayingDays: (day) =>
      dispatch({
        type: actionTypes.UPDATE_DISPLAYING_DAYS,
        day: day,
      }),

    onUpdateSelectState: (selectState) =>
      dispatch({
        type: actionTypes.UPDATE_SELECT_STATE,
        selectState: selectState,
      }),

    onUpdateWeek: (week) =>
      dispatch({
        type: actionTypes.UPDATE_WEEK,
        week: week,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GetWeekTable);
