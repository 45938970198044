import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import { findMonth, findYear } from "../helper";
import classes from "./index.module.css";
import * as actionTypes from "../../../data/recommendation/actionTypes";
import {
  postMonthData,
  recSelectedDays,
} from "../../../data/recommendation/actions/recommend";

import Download from "../Download";
import Upload from "../Upload";

const Buttons = (props) => {
  const currentMonth = props.month;
  const currentYear = props.year;
  const [Identifier1, Identifier2] = [
    String(currentYear) + "-" + String(currentMonth) + "-" + "L",
    String(currentYear) + "-" + String(currentMonth) + "-" + "D",
  ];

  const [lunchMonthData, setLunchMonthData] = useState({});
  const [dinnerMonthData, setDinnerMonthData] = useState({});

  const displayingDays = props.displayingDays;

  useEffect(() => {
    if (Identifier1 in props.monthData) {
      setLunchMonthData(props.monthData[Identifier1]);
    }
    if (Identifier2 in props.monthData) {
      setDinnerMonthData(props.monthData[Identifier2]);
    }
  }, [
    displayingDays,
    Object.keys(lunchMonthData),
    Object.keys(dinnerMonthData),
  ]);

  const recommendSelectedDays = (props) => {
    const { selectedDays, modifyState } = props;
    props.onUpdateLoading("recommending");
    const numDays = selectedDays.L.length + selectedDays.D.length;
    if (modifyState) {
      props.onUpdateModifyState();
    }
    props.onRecSelectedDays(numDays).then(() => {
      props.onUpdateLoading(false);
    });
  };

  const DownloadButton = <Download classNameProp={classes.btn} />;
  const UploadButton = (
    <Upload
      classNameProp={classes.btn}
      year={currentYear}
      month={currentMonth}
      userId={1}
    />
  );

  const clickButtons = (
    <div className={classes.position}>
      <button
        className={classes.btn}
        onClick={() => props.onUpdateAllSelected()}
      >
        전체 선택
      </button>
      <div />
      <button
        className={classes.btn}
        onClick={() => props.onUpdateHighlightState()}
      >
        식단 검수
      </button>
      <button
        className={classes.btn}
        onClick={() => props.onPostMonthData(props.monthData, props.amountData)}
      >
        식단 저장
      </button>
      <button
        className={classes.btn}
        onClick={() => recommendSelectedDays(props)}
      >
        랜덤 추천
      </button>
      {DownloadButton}
      {UploadButton}
      <div />
    </div>
  );
  // }

  return (
    <ThemeProvider
      theme={{
        palette: {
          blue: "#4b77d4",
          gray: "#495057",
          pink: "#f06595",
        },
      }}
    >
      <p />
      {clickButtons}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    monthData: state.recommend.user.monthData,
    amountData: state.recommend.user.amountData,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    year: state.recommend.calendar.year,
    selectedDays: state.recommend.selection.selectedDays,
    modifyState: state.recommend.selection.modifyState,
    displayingDays: state.recommend.selection.displayingDays,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostMonthData: (monthData, amountData) =>
      dispatch(postMonthData(monthData, amountData)),

    onRecSelectedDays: (numDays) => dispatch(recSelectedDays(numDays)),
    onUpdateAllSelected: () =>
      dispatch({
        type: actionTypes.UPDATE_ALL_SELECTED,
      }),
    onUpdateHighlightState: () =>
      dispatch({
        type: actionTypes.UPDATE_HIGHLIGHT_STATE,
      }),

    onUpdateModifyState: () =>
      dispatch({
        type: actionTypes.UPDATE_MODIFY_STATE,
      }),
    onUpdateLoading: (loading) =>
      dispatch({
        type: actionTypes.UPDATE_LOADING,
        loading: loading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
