import { put, call } from "redux-saga/effects";

import { actions } from "data";
import api from "api";

export function* test(action) {
  try {
    console.log("test");
  } catch (e) {
    console.log(e.message);
  }
}

export function* getMenuList(action) {
  try {
    const res = yield api.dashboardApi.getMenuList(action.payload);
    if (res.data[0]) {
      yield put(actions.dashboard.setMenuList(res.data[0]));
      yield put(actions.dashboard.setMenuImageSrc(res.data[0].menuImage));
    } else {
      yield put(actions.dashboard.setMenuList(null));
      yield put(actions.dashboard.setMenuImageSrc(null));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* setMenuImage(action) {
  try {
    const res = yield api.dashboardApi.setMenuImage(action.payload);
    yield put(actions.dashboard.setMenuImageSrc(res.data));
  } catch (e) {
    console.log(e.message);
  }
}

export function* deleteMenuImage(action) {
  try {
    const res = yield api.dashboardApi.deleteMenuImage(action.payload);
    // 사진이 삭제되었습니다 모달 넣기
  } catch (e) {
    console.log(e.message);
  }
}
