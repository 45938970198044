import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  cell1: {
    padding: "0px",
    textAlign: "center",
    width: "100px",
    zIndex: 5,
  },
  cell2: {
    textAlign: "center",
    padding: "10px 0px",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
}));

const DetailsTable = (props) => {
  const classes = useStyles();
  const componentInfo = props.componentInfo;
  const totalAmount = {
    serving_amount: 0,
    calories: 0,
    carbohydrate_g: 0,
    protein_g: 0,
    fat_g: 0,
  };
  const ratio = {};
  const componentInfoKeys = Object.keys(componentInfo);
  const totalAmountKeys = Object.keys(totalAmount);
  for (var i = 0; i < componentInfoKeys.length; i++) {
    if (componentInfo[componentInfoKeys[i]]["unit_amount"]) {
      ratio[componentInfoKeys[i]] =
        Math.round(
          (componentInfo[componentInfoKeys[i]]["serving_amount"] /
            componentInfo[componentInfoKeys[i]]["unit_amount"]) *
            100
        ) / 100;
    } else {
      ratio[componentInfoKeys[i]] = 1;
    }

    totalAmount["serving_amount"] += Number(
      componentInfo[componentInfoKeys[i]]["serving_amount"]
    );
    for (var j = 1; j < totalAmountKeys.length; j++) {
      totalAmount[totalAmountKeys[j]] += Number(
        componentInfo[componentInfoKeys[i]][totalAmountKeys[j]] *
          ratio[componentInfoKeys[i]]
      );
    }
  }

  for (var j = 0; j < totalAmountKeys.length; j++) {
    totalAmount[totalAmountKeys[j]] =
      Math.round(totalAmount[totalAmountKeys[j]] * 100) / 100;
  }

  const componentRows = componentInfoKeys.map((key) => (
    <TableRow key={key}>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {componentInfo[key]["ingredient_name"]}
      </TableCell>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {componentInfo[key]["serving_amount"]}
        <span style={{ fontSize: "10px" }}></span>
      </TableCell>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {Math.round(componentInfo[key]["calories"] * ratio[key] * 100) / 100}
        <span style={{ fontSize: "10px" }}></span>
      </TableCell>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {Math.round(componentInfo[key]["carbohydrate_g"] * ratio[key] * 100) /
          100}
        <span style={{ fontSize: "10px" }}></span>
      </TableCell>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {Math.round(componentInfo[key]["protein_g"] * ratio[key] * 100) / 100}
        <span style={{ fontSize: "10px" }}></span>
      </TableCell>
      <TableCell className={classes.cell1} style={{ padding: "0px" }}>
        {Math.round(componentInfo[key]["fat_g"] * ratio[key] * 100) / 100}
        <span style={{ fontSize: "10px" }}></span>
      </TableCell>
    </TableRow>
  ));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>재료명</b>
          </TableCell>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>기준량</b>
            <span style={{ fontSize: "10px" }}>(g)</span>
          </TableCell>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>열량</b>
            <span style={{ fontSize: "10px" }}>(kcal)</span>
          </TableCell>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>탄수화물</b>
            <span style={{ fontSize: "10px" }}>(g)</span>
          </TableCell>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>단백질</b>
            <span style={{ fontSize: "10px" }}>(g)</span>
          </TableCell>
          <TableCell className={classes.cell1} style={{ padding: "0px" }}>
            <b>지방</b>
            <span style={{ fontSize: "10px" }}>(g)</span>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {componentRows}
        <TableRow>
          <TableCell className={classes.cell2}>
            <b>총량</b>
          </TableCell>
          <TableCell className={classes.cell2}>
            <b>{totalAmount[totalAmountKeys[0]]}</b>
          </TableCell>
          <TableCell className={classes.cell2}>
            <b>{totalAmount[totalAmountKeys[1]]}</b>
          </TableCell>
          <TableCell className={classes.cell2}>
            <b>{totalAmount[totalAmountKeys[2]]}</b>
          </TableCell>
          <TableCell className={classes.cell2}>
            <b>{totalAmount[totalAmountKeys[3]]}</b>
          </TableCell>
          <TableCell className={classes.cell2}>
            <b>{totalAmount[totalAmountKeys[4]]}</b>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default DetailsTable;
