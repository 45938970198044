import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SettingBox from "./SettingBox";
import api from "api";
import { apiClient } from "api/client";
import { Container, InnerContainer } from "pages/MasterPages/StyledComps";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import AddDepartmentModal from "./AddDepartmentModal";
import AddScannerModal from "./AddScannerModal";

export default function ScannerManagement() {
  const [filteredData, setFilteredData] = useState([]);

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [departmentModalOpen, setDepartmentModalOpen] = useState(false);
  const [scannerModalOpen, setScannerModalOpen] = useState(false);
  const [engDepartmentName, setEngDepartmentName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [scannerSettings, setScannerSettings] = useState(null);
  const [mayEnter, setMayEnter] = useState(true);
  const [text, setText] = useState("");
  const [scannerId, setScannerId] = useState("");
  const [departmentId,setDepartmentId] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [jsonDepartmentList, setJsonDepartmentList] = useState("");
  const key = "letmego";

  const confirmEnter = () => {
    if (text === key) {
      setMayEnter(true);
    }
  };
  
  const handleFilteredData = (data) => {
    setFilteredData(data);
  }

  const getJsonDepartmentList = async () => {
    const res = await apiClient.get(`/api/scanner/department/json/list`);
    setJsonDepartmentList(res.data);
  };

  const getJsonScannerList = async () => {
    const res = await apiClient.get(`/api/scanner/json/list`);
    handleFilteredData(res.data);
  };
  const getScannerSettings = async () => {
    setScannerSettings(null);
    const res = await apiClient.post(`/api/scanner/settings`, checked);

    //에러가 있을 때, action 취해주는 방식으로 바꾸기
    if (res.data.errorMessage) {
    } else {
      setScannerSettings(res.data);
    }
  };
  
  const deleteScanner = async () => {
    if(window.confirm("정말로 삭제하시겠습니까?")){
      const data = {
        scannerId,
        serialNumber,
        departmentId,
      };
      const res = await apiClient.post('api/scanner/delete', data);
      alert(res.data);
      getJsonScannerList();
      setChecked([]);
      setScannerId(null);
      setSerialNumber(null);
      setDepartmentId(null);
    }else{
      alert("삭제가 취소되었습니다.")
    }
  }

  const resetCheckedItems = () => {
    setChecked([]);
  };
  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleEngDepartmentNameChange = (e) => {
    setEngDepartmentName(e.target.value);
  };

  const handleEngDepartmentName = (name) => {
    setEngDepartmentName(name);
  };

  const handleDepartmentName = (name) => {
    setDepartmentName(name);
  };

  const handleDepartmentNameChange = (e) => {
    setDepartmentName(e.target.value);
  };
  const onCheckScanner = (checked) => {
    // checked의 element는 stringify된 object임
    setChecked(checked);
  };
  const handleDepartmentModalOpen = () => {
    setDepartmentModalOpen(!departmentModalOpen);
  };
  const handleScannerModalOpen = () => {
    setScannerModalOpen(!scannerModalOpen);
    getJsonScannerList();
  };
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDeleteButton = () => {
    if (checked[0]){
      return (
        <StyledButton onClick={deleteScanner}>삭제하기</StyledButton>
      )
    }
  }

  useEffect(() => {
    getJsonScannerList();
  }, []);

  useEffect(() => {
    if (checked[0]) {
      getScannerSettings();
      const checkedObj = JSON.parse(checked[0]);
      setDepartmentId(checked[0]);
      const {id, serialNumber, departmentId} = checkedObj;
        if(id && serialNumber){
          setScannerId(id);
          setSerialNumber(serialNumber);
          setDepartmentId(departmentId);
        }
    }
  }, [checked]);

  if (mayEnter) {
    return (
      <Container style={{ padding: "30px", paddingTop: "0px" }}>
        <InnerContainer style={{ border: "none" }}>
          <CheckBoxTreeContainer>
          <Title>기관/스캐너 관리</Title>

            <ButtonContainer>
              <StyledButton
                onClick={handleDepartmentModalOpen}
                style={{ width: "130px" }}
              >
                기관 추가
              </StyledButton>

              <StyledButton
                onClick={handleScannerModalOpen}
                style={{ width: "130px" }}
              >
                스캐너 추가
              </StyledButton>
              {handleDeleteButton()}

            </ButtonContainer>
            <Title>스캐너 목록</Title>
            {filteredData[0] ?             <CheckboxTree
              key={Math.random()}
              iconsClass="fa4"
              nodes={filteredData}
              checked={checked}
              expanded={expanded}
              onCheck={(checked) => onCheckScanner(checked)}
              onExpand={(expanded) => setExpanded(expanded)}
              checkModel="all"
            /> : <div>loading...</div>}

          </CheckBoxTreeContainer>
          <SettingBox
            handleScannerModalOpen={handleScannerModalOpen}
            checked={checked}
            resetCheckedItems={resetCheckedItems}
            scannerSettings={scannerSettings}
            getScannerSettings={getScannerSettings}
            getJsonScannerList={getJsonScannerList}
          />
        </InnerContainer>

        {/* 기관 추가 모달 */}
        <ModalWrapper
          visible={departmentModalOpen}
          onClick={handleDepartmentModalOpen}
        >
          <ModalInner onClick={stopPropagation}>
            <AddDepartmentModal
              handleDepartmentModalOpen={handleDepartmentModalOpen}
              handleEngDepartmentNameChange={handleEngDepartmentNameChange}
              handleDepartmentNameChange={handleDepartmentNameChange}
              engDepartmentName={engDepartmentName}
              departmentName={departmentName}
              handleDepartmentName={handleDepartmentName}
              handleEngDepartmentName={handleEngDepartmentName}
              getJsonDepartmentList={getJsonDepartmentList}
              jsonDepartmentList={jsonDepartmentList}
            />
          </ModalInner>
        </ModalWrapper>

        {/* 스캐너 추가 모달 */}
        <ModalWrapper
          visible={scannerModalOpen}
          onClick={handleScannerModalOpen}
        >
          <ModalInner onClick={stopPropagation}>
            <AddScannerModal
              handleScannerModalOpen={handleScannerModalOpen}
              getJsonScannerList={getJsonScannerList}
              getJsonDepartmentList={getJsonDepartmentList}
              jsonDepartmentList={jsonDepartmentList}
            />
          </ModalInner>
        </ModalWrapper>
      </Container>
    );
  } else {
    return (
      <div>
        <h2>working</h2>
        <input type="password" onChange={handleChange} />
        <button type="button" onClick={confirmEnter}>
          go to work
        </button>
      </div>
    );
  }
}

const CheckBoxTreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgray;
  flex: 1;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  gap: 5px;
`;

const Title = styled.h2`
  margin: 10px;
  border: 1px solid white;
`;
const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 410px;
  height: 850px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: white;
  border: 3px solid black;
  font-size: 1.5rem;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 30px;
  flex: 1;
  border: 1px solid gray;
  border-radius: 5px;
`;