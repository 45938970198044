import * as AT from "data/rootActionTypes";

export const test = payload => ({
  type: AT.TEST,
  payload
});

export const setMenuList = payload => ({
  type: AT.MENU_LIST,
  payload
});

export const getMenuList = payload => ({
  type: AT.GET_MENU_LIST,
  payload
});

export const setMenuImage = payload => ({
  type: AT.SET_MENU_IMAGE,
  payload
});

export const deleteMenuImage = payload => ({
  type: AT.DELETE_MENU_IMAGE,
  payload
});

export const setMenuImageSrc = payload => ({
  type: AT.MENU_IMAGE_SRC,
  payload
});

export const setMenuPreviewSrc = payload => ({
  type: AT.MENU_PREVIEW_SRC,
  payload
});