export const TOGGLE_INVENTORY_TAB = "TOGGLE_INVENTORY_TAB";

export const SET_CURRENT_INGREDIENT = "SET_CURRENT_INGREDIENT";
export const SET_INGREDIENTS = "SET_INGREDIENTS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_UNITS = "SET_UNITS";

export const SET_CURRENT_SEARCHING_CATEGORY = "SET_CURRENT_SEARCHING_CATEGORY";
export const SET_CURRENT_SEARCHING_INGREDIENT =
  "SET_CURRENT_SEARCHING_INGREDIENT";

export const LOAD_MANAGING_PAGE = "LOAD_MANAGING_PAGE";

export const LOAD_CURRENT_INGREDIENT = "LOAD_CURRENT_INGREDIENT";

export const LOAD_INGREDIENT_LOGS = "LOAD_INGREDIENT_LOGS";

export const SET_CURRENT_INGREDIENT_LOGS = "SET_CURRENT_INGREDIENT_LOGS";

export const LOAD_TOTAL_PAGE = "LOAD_TOTAL_PAGE";

export const LOAD_INGREDIENTS_OF_CATEOGRIES = "LOAD_INGREDIENTS_OF_CATEOGRIES";

export const SET_INGREDIENTS_OF_CATEGORIES = "SET_INGREDIENTS_OF_CATEGORIES";

export const ADD_INGREDIENT_LOG = "ADD_INGREDIENT_LOG";

export const ADD_INGREDIENT = "ADD_INGREDIENT";

export const EDIT_INGREDIENT_LOG = "EDIT_INGREDIENT_LOG";

export const DELETE_INGREDIENT_LOG = "DELETE_INGREDIENT_LOG";

export const DELETE_INGREDIENT = "DELETE_INGREDIENT";

export const LOAD_INGREDIENT_LOGS_FOR_DETAIL_GRAPH =
  "LOAD_INGREDIENT_LOGS_FOR_DETAIL_GRAPH";

export const SET_INGREDIENT_LOGS_FOR_DETAIL_GRAPH =
  "SET_INGREDIENT_LOGS_FOR_DETAIL_GRAPH";

export const MOVE_INGREDIENT_CARD = "MOVE_INGREDIENT_CARD";

export const SET_CURRENT_INGREDIENT_CARD_ORDERING_MODE =
  "SET_CURRENT_INGREDIENT_CARD_ORDERING_MODE";

export const SET_INGREDIENT_ETC_LOGS_PER_DATES =
  "SET_INGREDIENT_ETC_LOGS_PER_DATES";

export const LOAD_INGREDIENT_ETC_LOGS_PER_DATES =
  "LOAD_INGREDIENT_ETC_LOGS_PER_DATES";

export const ADD_INGREDIENT_ETC_LOGS_PER_DATES =
  "ADD_INGREDIENT_ETC_LOGS_PER_DATES";
export const EDIT_INGREDIENT_ETC_LOGS_PER_DATES =
  "EDIT_INGREDIENT_ETC_LOGS_PER_DATES";
export const DELETE_INGREDIENT_ETC_LOGS_PER_DATES =
  "DELETE_INGREDIENT_ETC_LOGS_PER_DATES";

export const SET_CURRENT_SEARCHING_ETC_INGREDIENT =
  "SET_CURRENT_SEARCHING_ETC_INGREDIENT";

export const SET_STOCK_COST_INFO = "SET_STOCK_COST_INFO";

export const SET_MONTH_USE_INGREDIENTS = "SET_MONTH_USE_INGREDIENTS";

export const LOAD_MONTH_USE_INGREDIENTS = "LOAD_MONTH_USE_INGREDIENTS";
