import React from "react";

export const convertDuplicates = (menu, duplicateClass, idx, menuLength) => {
  const colorPicker = {
    0: ["#ff9100", "#40c4ff", "#18ffff"],
    1: ["#ff66ff", "#ffff99", "#66ffcc"],
    2: ["#ff6699", "#ccccff", "#cccc00"],
    3: ["#ffccbc", "#ffab91", "#ccff33", "#c6ffb3"],
    4: ["#ccffff", "#cc99ff", "#9999ff", "#ffccff"]
  };

  if (duplicateClass[0].includes(menu)) {
    const index = duplicateClass[0].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[0][index] }}>{menu}</b>;
  } else if (duplicateClass[1].includes(menu)) {
    const index = duplicateClass[1].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[1][index] }}>{menu}</b>;
  } else if (duplicateClass[2].includes(menu)) {
    const index = duplicateClass[2].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[2][index] }}>{menu}</b>;
  } else if (duplicateClass[3].includes(menu)) {
    const index = duplicateClass[3].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[3][index] }}>{menu}</b>;
  } else if (duplicateClass[4].includes(menu)) {
    const index = duplicateClass[4].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[4][index] }}>{menu}</b>;
  }
  if (idx + 1 == menuLength) {
    return <b>{menu}</b>;
  }
  return menu;
};

export const findDuplicateClass = (highlight, idx) => {
  let result = [];
  for (var i = 0; i < highlight.length; i++) {
    result.push(highlight[i].slice(0, highlight[i].indexOf("(")));
  }
  return result;
};

export const getDayToIdxWeek = (commandList, startIndex) => {
  const dayToIdx = {};
  let menuDays = commandList.filter(function(el) {
    return el[0] != "b";
  });

  menuDays = menuDays.map(x => x.slice(7, 9).replace(",", ""));

  // 첫 주
  if (["0", "1", "2", "3"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = idx + 1;
    });
  } else if (["4", "5", "6", "7", "8", "9", "10"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = startIndex + idx;
    });
  } else if (["11", "12", "13", "14", "15", "16", "17"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = startIndex + idx;
    });
  } else if (["18", "19", "20", "21", "22", "23", "24"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = startIndex + idx;
    });
  } else if (["25", "26", "27", "28", "29"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = startIndex + idx;
    });
  } else if (["30", "31"].includes(menuDays[0])) {
    menuDays.map((x, idx) => {
      dayToIdx[x] = startIndex + idx;
    });
  }

  return dayToIdx;
};
