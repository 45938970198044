import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "data";

import { BorderedBox, Title } from "components/Styled";
import { dashboardColor } from "theme/colors";

import nutritionist from "./nutritionist.png";

export default function NutritionInfoComp({
  menuList,
  itemCalories,
  isInFakeView,
}) {
  let nutritionFacts = {
    calories: 0,
    carbohydrate_g: 0,
    protein_g: 0,
    fat_g: 0,
    vitamin_a_ug_re: 0,
    vitamin_b1_mg: 0,
    vitamin_b2_mg: 0,
    vitamin_c_mg: 0,
    calcium_mg: 0,
    iron_mg: 0,
    sodium_mg: 0,
  };

  if (menuList) {
    for (let key in nutritionFacts) {
      nutritionFacts[key] = Number(menuList.menuInfo[key].toFixed(2));
    }
  }

  let total = 0;
  let modifiedRatio = 1;

  for (let k in itemCalories) {
    total += itemCalories[k];
  }
  if (total !== 0) {
    modifiedRatio = total / nutritionFacts.calories;
  }

  return (
    <NutritionInfo>
      <AbsoluteImage src={nutritionist} alt="nutritionist" />
      <NutritionTitle>영양정보</NutritionTitle>
      <NutritionTable>
        <TableContaner>
          <TableItemContainer>
            <TableItem>탄수화물</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.carbohydrate_g * modifiedRatio).toFixed(
                0
              )} g`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>단백질</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.protein_g * modifiedRatio).toFixed(0)} g`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>지방</TableItem>
            <TableItemRight>{`${(nutritionFacts.fat_g * modifiedRatio).toFixed(
              0
            )} g`}</TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>비타민 A</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.vitamin_a_ug_re * modifiedRatio).toFixed(
                2
              )} RE`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>비타민 B1</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.vitamin_b1_mg * modifiedRatio).toFixed(
                2
              )} mg`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>비타민 B2</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.vitamin_b2_mg * modifiedRatio).toFixed(
                2
              )} mg`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>비타민 C</TableItem>
            <TableItemRight>
              {`${(nutritionFacts.vitamin_c_mg * modifiedRatio).toFixed(2)} mg`}
            </TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>칼슘</TableItem>
            <TableItemRight>{`${(
              nutritionFacts.calcium_mg * modifiedRatio
            ).toFixed(2)} mg`}</TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>철분</TableItem>
            <TableItemRight>{`${(
              nutritionFacts.iron_mg * modifiedRatio
            ).toFixed(2)} mg`}</TableItemRight>
          </TableItemContainer>
          <TableItemContainer>
            <TableItem>나트륨</TableItem>
            <TableItemRight>{`${(
              nutritionFacts.sodium_mg * modifiedRatio
            ).toFixed(2)} mg`}</TableItemRight>
          </TableItemContainer>
        </TableContaner>
      </NutritionTable>
      <CalorieBox>
        {menuList ? (
          <>
            <CalorieValue>
              {isInFakeView ? total : Math.round(nutritionFacts.calories)}
            </CalorieValue>
            <CalorieUnit>Kcal</CalorieUnit>
          </>
        ) : null}
      </CalorieBox>
    </NutritionInfo>
  );
}

const NutritionInfo = styled(BorderedBox)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 96%;
  background-color: none;

  height: 100%;

  @media (max-width: 1120px) {
    width: 96%;
    font-size: 1rem;
    padding: 0px;
  }
`;

const NutritionTitle = styled(Title)`
  margin: 1% 10%;
  font-size: 2rem;
  font-weight: bold;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 1.5rem;
    margin: 0%;
    margin-bottom: 5px;
    padding: 10px;
    padding-left: 20px;
  }
`;

const AbsoluteImage = styled.img`
  position: absolute;
  top: -12vw;
  right: -2vw;
  width: 16vw;
`;

const NutritionTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const TableContaner = styled.div`
  width: 100%;
  border: 3px solid none;
`;

const TableItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: ${({ index }) =>
    `solid rgba(43,136,240,${index * 0.1 + 0.03})`};
  margin-bottom: 3px;
  padding: 3px;
  border-bottom: 1px solid ${dashboardColor.blue};
`;

const TableItem = styled.div`
  font-size: 1.2rem;
  font-wieght: 500;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

const TableItemRight = styled.div`
  font-size: 1.2rem;
  font-wieght: 500;
  text-align: right;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 0.8rem;
  }
`;

const CalorieBox = styled.div`
  display: flex;
  align-items: center;
  border: 3px solid none;
`;

const CalorieValue = styled.div`
  font-weight: 700;
  font-size: 4rem;
  color: ${dashboardColor.blue};
  background-color: none;
  @media (max-width: 1120px) {
    width: 100%;
    font-size: 2rem;
  }
`;
const CalorieUnit = styled.div`
  font-weight: 300;
  font-size: 1.5rem;
  color: ${dashboardColor.gray_8};
  font-weight: bold;
  margin-left: 15px;
  background-color: none;

  @media (max-width: 1120px) {
    width: 100%;
    font-size: 1rem;
  }
`;
