import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "data";

import styled from "styled-components";
import moment from "moment";

const sortOptions = [
  { id: "date", name: "날짜순" },
  { id: "name", name: "이름순" }
];

export default function UpdatePoint({ tabHandler }) {
  const dispatch = useDispatch();
  const user = useSelector(selectors.user.getUserSession);
  const points = useSelector(selectors.point.getIndividualPoint);
  const departmentName = user.Department.name;

  const [sortOption, setSortOption] = useState("date");
  const [sumPoint, setSumPoint] = useState(0);

  const getIndividualPoint = () => {
    dispatch(actions.point.getIndividualPoint());
  };

  const handleSortOptions = e => {
    setSortOption(e.target.value);
  };

  const sortFunction = (a, b) => {
    switch (sortOption) {
      case "name":
        return b.name - a.name;
      default:
        return b.id - a.id;
    }
  };

  useEffect(() => {
    getIndividualPoint();
  }, []);

  useEffect(() => {
    if (points[0]) {
      let total = points
        .filter(el => el.point < 0 && el.name !== "관리자")
        .reduce((a, c) => a + c.point, 0);
      setSumPoint(total);
    }
  }, []);

  return (
    <ColumnWrapper>
      <ModeChangeButton onClick={tabHandler}>
        개인 포인트 조회/사용
      </ModeChangeButton>

      <Title>{departmentName}</Title>
      <Title>전체 포인트 사용내역</Title>
      <SubTitle> 총 사용 포인트: {sumPoint}P </SubTitle>

      <StyledSelect onChange={handleSortOptions}>
        {sortOptions.map((el, idx) => (
          <option key={idx} value={el.id}>
            {el.name}
          </option>
        ))}
      </StyledSelect>

      <PointRow style={{ backgroundColor: "whitesmoke" }}>
        <Text style={{ fontWeight: "bold" }}>사용일자</Text>
        <Text style={{ fontWeight: "bold" }}>이름</Text>
        <Text style={{ fontWeight: "bold" }}>포인트</Text>
      </PointRow>

      {points[0] &&
        points
          .filter(el => el.point < 0 && el.name !== "관리자")
          .sort(sortFunction)
          .map((el, idx) => {
            return (
              <PointRow key={idx}>
                <Text>{moment(el.createdAt).format("YYYY.M.D")}</Text>
                <Text>{el.name}</Text>
                <Text>{el.point}P</Text>
              </PointRow>
            );
          })}
    </ColumnWrapper>
  );
}

const ColumnWrapper = styled.div`
  width: 100%;
  min-height: 97vh;
  height: 100%;
  font-family: Noto Sans KR, sans-serif;

  background-color: none;
  justify-content: center;

  padding: 0px;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  padding: 30px;
`;

const Title = styled.div`
font-size: 30px;
font-weight: bold;
margin: 10px 50px;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 15px;
  margin-bottom: 30px;
`;

const PointRow = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50vw;
  min-height: 2rem;
  background-color: none;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  position: relative;
  display: flex;

 
  @media (max-width: 500px){
    width: 95%;
    flex-direction: column;
  }
`;

const Text = styled.div`
  min-width: 150px;
  background-color: none;
  font-size: 1rem;
`;

const StyledSelect = styled.select`
  min-width: 200px;
  float: auto;
  margin: 10px;
`;

const ModeChangeButton = styled.button`
  min-width: 200px;
  min-height: 2rem;
  border: 1px solid black;
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: white;
  border-radius: 20px;
`;
