const colors = {
  blue_1: "#4786E9",
  blue_2: "#78C5F4",
  blue_3: "#A9C3ED",
  blue_4: "#E0EFFF",
  green_1: "#74E0B1",
  green_2: "#A2E3C7",
  green_deep_1: "#57AC87",
  green_deep_2: "#4C7765",
  gray_1: "#797A7D",
  gray_2: "#E2E4EA",
  pink: "#EF6686",
  yellow: "#FCC613"
};

export const dashboardColor = {
  blue: "#2b88f0",
  light_blue: "#EFF6FF",
  light_green: "#3fe2ad",
  red: "#c96767",
  gray_4: "#4D4D4D",
  gray_6: "#666",
  gray_8: "#808080",
  orange: "#F1BB77"
};

export default colors;
