import { useState, useEffect } from "react";
import api from "api";

export const useDepartmentList = () => {
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    api.departmentApi.getDepartmentList().then((res) => {
      const sortedList = res.data.sort((a, b) =>
        a.name < b.name ? -1 : a.name > b.name ? 1 : 0
      );
      setDepartmentList(sortedList);
    });
  }, []);

  return departmentList;
};

export default useDepartmentList;
