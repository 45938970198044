import React from "react";
import "./index.css";

const GetHtmlScript = (monthVal, year) => {
  let month = "0" + String(((Number(monthVal) - 1) % 12) + 1);
  month = month.slice(-2);
  const day = new Date(year, month - 1, 1);
  const setDate = new Date(year, month - 1, 1);

  //이번 달의 첫째 날을 구합니다.
  const firstDayName = setDate.getDay();

  //이번 달의 마지막 날을 구합니다.
  const lastDay = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();
  //지난 달의 마지막 날을 구합니다.
  const prevLastDay = new Date(day.getFullYear(), day.getMonth(), 0).getDate();

  //매월 일수가 달라지므로 이번 달 날짜 개수를 세기 위한 변수를 만들고 초기화 합니다.
  let startDayCount = 1;
  let lastDayCount = 1;
  let getHtml = "";
  //1~6주차를 위해 6번 반복합니다.
  //1~6주차를 위해 6번 반복합니다.
  for (let i = 0; i < 6; i++) {
    //일요일~토요일을 위해 7번 반복합니다.
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDayName) {
        if (j === 0) {
        } else if (j === 6) {
        } else {
          getHtml += `block5(${prevLastDay}, ${firstDayName}, ${j})|`;
        }
      } else if (i === 0 && j === firstDayName) {
        if (j === 0) {
          startDayCount += 1;
        } else if (j === 6) {
          startDayCount += 1;
        } else {
          getHtml += `mlock1(${startDayCount}, dayMenu)|`;
          startDayCount += 1;
        }
      } else if (i === 0 && j > firstDayName) {
        if (j === 0) {
          startDayCount += 1;
        } else if (j === 6) {
          startDayCount += 1;
        } else {
          getHtml += `mlock1(${startDayCount}, dayMenu)|`;
          startDayCount += 1;
        }
      } else if (i > 0 && startDayCount <= lastDay) {
        if (j === 0) {
          startDayCount += 1;
        } else if (j === 6) {
          startDayCount += 1;
        } else {
          getHtml += `mlock1(${startDayCount}, dayMenu)|`;
          startDayCount += 1;
        }
      } else if (startDayCount > lastDay) {
        if (j === 0) {
          lastDayCount += 1;
        } else if (j === 6) {
          lastDayCount += 1;
        } else {
          getHtml += `block1(${lastDayCount})|`;
          lastDayCount += 1;
        }
      }
    }
  }

  return getHtml;
};

export const GetMonthCommands = (month, year) => {
  const calendarData = GetHtmlScript(month, year);
  if (calendarData === "") {
    return [];
  }
  const functionList = calendarData.split("|").map((x) => x.slice(0, 6));
  const inputList = calendarData
    .split("|")
    .map((x) => x.slice(6).split(",").map(String));
  let commandList = functionList.map((x, idx) => x + inputList[idx]);
  commandList = commandList.filter(function (el) {
    return el != "";
  });

  return commandList;
};

export const convertDuplicates = (menu, duplicateClass, idx, menuLength) => {
  const colorPicker = {
    0: ["#ff9100", "#40c4ff", "#18ffff"],
    1: ["#ff66ff", "#ffff99", "#66ffcc"],
    2: ["#ff6699", "#ccccff", "#cccc00"],
    3: ["#ffccbc", "#ffab91", "#ccff33", "#c6ffb3"],
    4: ["#ccffff", "#cc99ff", "#9999ff", "#ffccff"],
  };

  if (duplicateClass[0].includes(menu)) {
    const index = duplicateClass[0].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[0][index] }}>{menu}</b>;
  } else if (duplicateClass[1].includes(menu)) {
    const index = duplicateClass[1].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[1][index] }}>{menu}</b>;
  } else if (duplicateClass[2].includes(menu)) {
    const index = duplicateClass[2].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[2][index] }}>{menu}</b>;
  } else if (duplicateClass[3].includes(menu)) {
    const index = duplicateClass[3].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[3][index] }}>{menu}</b>;
  } else if (duplicateClass[4].includes(menu)) {
    const index = duplicateClass[4].indexOf(menu);
    return <b style={{ backgroundColor: colorPicker[4][index] }}>{menu}</b>;
  }
  if (idx + 1 == menuLength) {
    return <b>{menu}</b>;
  }
  return menu;
};

export const findDuplicateClass = (highlight, idx) => {
  let result = [];
  for (var i = 0; i < highlight.length; i++) {
    result.push(highlight[i].slice(0, highlight[i].indexOf("(")));
  }
  return result;
};
