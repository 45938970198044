import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import ReadBox from "./ReadBox";
import EditBox from "./EditBox";

import { MenuBox, MenuTitle } from "pages/MasterPages/StyledComps";

const ResultBox = ({ customFoodList, menuInfo }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { submitForm } = useFormikContext();

  const handleEditMode = () => {
    if (isEditMode) {
      if (window.confirm("수정사항이 취소됩니다. 나가시겠습니까?")) {
        setIsEditMode(!isEditMode);
      } else {
        return null;
      }
    } else {
      menuInfo.id
        ? setIsEditMode(!isEditMode)
        : alert("수정할 메뉴정보가 없습니다");
    }
  };

  useEffect(() => {
    submitForm();
  }, [isEditMode]);

  return (
    <MenuBox>
      <MenuTitle> 메뉴 조회 결과</MenuTitle>
      <ButtonContainer>
        <StyledButton onClick={handleEditMode}>
          {isEditMode ? "취소" : "수정"}
        </StyledButton>
      </ButtonContainer>

      <ContentContainer>
        {!isEditMode ? (
          <ReadBox customFoodList={customFoodList} menuInfo={menuInfo} />
        ) : (
          <EditBox
            customFoodList={customFoodList}
            menuInfo={menuInfo}
            setIsEditMode={setIsEditMode}
          />
        )}
      </ContentContainer>
    </MenuBox>
  );
};

export default ResultBox;

const ButtonContainer = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  background-color: none;
  margin-bottom: 10px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled.button`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid black;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;
