import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { DownloadCSV } from "../../../data/recommendation/actions/recommend";
import * as actionTypes from "../../../data/recommendation/actionTypes";

const Download = ({
  monthData,
  displayingDays,
  year,
  month,
  showLD,
  week,
  monthOrWeek,
  classNameProp,
  userEmail,
  startDownload,
  onUpdateStartDownload,
}) => {
  const [downloadData, setDownloadData] = useState([]);
  const currentMonth = month;
  const currentYear = year;
  const [Identifier1, Identifier2] = [
    String(currentYear) + "-" + String(currentMonth) + "-" + "L",
    String(currentYear) + "-" + String(currentMonth) + "-" + "D",
  ];
  useEffect(() => {
    setCSVLink();
  }, [startDownload, displayingDays]);

  const dayToMenuLunch = {};
  const dayToMenuDinner = {};

  let dayToMenu = {};

  if (!userEmail || !displayingDays) {
    return null;
  }
  function setCSVLink() {
    const lunchMonthData = monthData[Identifier1];
    const dinnerMonthData = monthData[Identifier2];

    const days = Object.values(displayingDays);
    if (displayingDays && lunchMonthData && dinnerMonthData) {
      for (var i = 0; i < days.length; i++) {
        const day = days[i];
        dayToMenuLunch[day] = lunchMonthData[day];
        dayToMenuDinner[day] = dinnerMonthData[day];
      }
    }

    if (showLD == "LD") {
      dayToMenu = { L: dayToMenuLunch, D: dayToMenuDinner };
    } else if (showLD == "L") {
      dayToMenu = dayToMenuLunch;
    } else if (showLD == "D") {
      dayToMenu = dayToMenuDinner;
    }

    DownloadCSV(dayToMenu, currentMonth, week, showLD, monthOrWeek).then(
      (result) => {
        setDownloadData(result);
      }
    );
  }
  let csvFileName = "";
  if (monthOrWeek == "week") {
    csvFileName = `${currentMonth}월_${showLD}_${week + 1}주차_식단표.csv`;
  } else if (monthOrWeek == "month") {
    csvFileName = `${currentMonth}월_${showLD}_월간식단표.csv`;
  }

  return (
    <CSVLink data={downloadData} filename={csvFileName} target="_blank">
      <button className={classNameProp} onClick={() => onUpdateStartDownload()}>
        다운로드
      </button>
    </CSVLink>
  );
};

const mapStateToProps = (state) => {
  return {
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    monthData: state.recommend.user.monthData,
    displayingDays: state.recommend.selection.displayingDays,
    showLD: state.recommend.calendar.showLD,
    week: state.recommend.calendar.week,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    userEmail: state.recommend.user.userEmail,
    startDownload: state.recommend.user.startDownload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateStartDownload: () =>
      dispatch({
        type: actionTypes.UPDATE_START_DOWNLOAD,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
