import React from "react";
import styled from "styled-components";

import CombineNewNuviFood from "./CombineNewNuviFood";
import CreateNewNuviFood from "./CreateNewNuviFood";

const AddNuviFoodModal = ({ handleModalOpen, modalOpen }) => {
  const handleKeyPress = e => {
    if (e.key === "Escape" && modalOpen) {
      handleModalOpen();
    }
  };

  document.onkeydown = handleKeyPress;

  return (
    <ModalContainer>
      <MenuContainer>
        <Title>누비푸드 추가</Title>
        <StyledButton onClick={handleModalOpen}>X</StyledButton>
      </MenuContainer>
      <MakerContainer>
        <CombineNewNuviFood />
        <CreateNewNuviFood />
      </MakerContainer>
    </ModalContainer>
  );
};

export default AddNuviFoodModal;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MakerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  width: 100%;
  background-color: none;
  font-size: 35px;
  font-weight: bold;
  padding: 10px;
`;

const StyledButton = styled.button`
  width: 50px;
  height: 50px;
  align-self: center;
  margin 1px;
  border-radius: 30px;
  border: 1px solid white;
  font-size: 30px;
  background-color: white;
  cursor: pointer;

`;
