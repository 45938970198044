import * as sagas from "./sagas";
import * as AT from "data/rootActionTypes";
import { takeEvery } from "redux-saga/effects";

export default function*() {
  yield takeEvery(AT.TEST, sagas.test);
  yield takeEvery(AT.GET_MENU_LIST, sagas.getMenuList);
  yield takeEvery(AT.SET_MENU_IMAGE, sagas.setMenuImage);
  yield takeEvery(AT.DELETE_MENU_IMAGE, sagas.deleteMenuImage);

}
