export const TEST = "TEST";

export const USER_LIST = "USER_LIST";
export const GET_USER_LIST = "GET_USER_LIST";

export const INDIVIDUAL_POINT = "INDIVIDUAL_POINT";
export const GET_INDIVIDUAL_POINT = "GET_INDIVIDUAL_POINT";

export const UPDATE_POINT = "UPDATE_POINT";

export const SK_USER_PARTICIPATION = "SK_USER_PARTICIPATION";
export const GET_SK_USER_PARTICIPATION = "GET_SK_USER_PARTICIPATION";


