import React, { useState } from "react";
import styled from "styled-components";

import {
  Container,
  InnerContainer,
  LeftContainer
} from "pages/MasterPages/StyledComps";

import SearchBox from "./SearchBox";
import ResultBox from "./ResultBox";
import MiniDashBox from "./MiniDashBox";
import NavigationBar from "../NavigationBar";
import AddNuviFoodModal from "pages/MasterPages/AddNuviFoodModal";

export default function NuviFoodInfo({ tabHandler }) {
  const [nuviSearchResult, setNuviSearchResult] = useState([]);
  const [selectedNuviFood, setSelectedNuviFood] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const selectNuviFood = food => {
    setSelectedNuviFood(food);
  };

  const handleNuviSearchResult = array => {
    setNuviSearchResult(array);
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const navigationOptions = [
    { name: "누비푸드 관리", to: "/nuviFoodInfo" },
  ];

  return (
    <Container style={{ padding: "30px", paddingTop: "0px" }}>
      <NavigationBar options={navigationOptions} />
      <InnerContainer>
        <LeftContainer>
          <SearchBox
            handleNuviSearchResult={handleNuviSearchResult}
            handleModalOpen={handleModalOpen}
          />
          <ResultBox
            nuviSearchResult={nuviSearchResult}
            selectNuviFood={selectNuviFood}
          />
        </LeftContainer>
        <MiniDashBox selectedNuviFood={selectedNuviFood} />
      </InnerContainer>

      <ModalWrapper visible={modalOpen}>
        <ModalInner>
          <AddNuviFoodModal
            modalOpen={modalOpen}
            handleModalOpen={handleModalOpen}
          />
        </ModalInner>
      </ModalWrapper>
    </Container>
  );
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${props => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 95vw;
  height: 98vh;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: white;
  border: 3px solid black;
  font-size: 0.8rem;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
