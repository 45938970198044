import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "data/recommendation/axios";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import * as actionTypes from "data/recommendation/actionTypes";
import { actions } from "data";
import Colors from "theme/colors";
import "./index.css";

const Upload = ({ year, month, userId, onUpdateLoading, classNameProp }) => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("파일 선택하기");
  const dispatch = useDispatch();

  useEffect(() => {
    if (showUploadModal) {
      dispatch(
        actions.modal.setModal({
          modalType: "EMPTY",
          modalProps: {},
        })
      );
    }
  }, [showUploadModal]);

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const updateShowUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const submitFile = async () => {
    onUpdateLoading("submitfile");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("userId", userId);
    formData.append("year", year);
    formData.append("month", month);

    // 서버의 upload API 호출
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const errorMessage = (message) => {
      return alert(`업로드에 실패하였습니다. \n ${message}`);
    };

    await axios
      .post("/upload", formData, config)
      .then(() => {
        onUpdateLoading(false);
        alert("업로드가 완료되었습니다");
        closeModal();
      })
      .catch((e) => errorMessage(e.response.data))
      .then(() => {
        onUpdateLoading(false);
        closeModal();
      });
  };

  const closeModal = () => {
    dispatch(actions.modal.clearModal());
    setShowUploadModal(false);
    setFileName("파일 선택하기");
    setFile(null);
  };

  const modal = showUploadModal ? (
    <Modal>
      <div class="box">
        <input
          type="file"
          name="file-2[]"
          id="file-2"
          className="inputfile inputfile-2"
          multiple=""
          onChange={onChange}
        />
        <label for="file-2">
          <i class="fa fa-paperclip"></i>
          <span>{fileName}</span>
        </label>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button onClick={submitFile}>제출</Button>
        <Button onClick={() => closeModal()}>닫기</Button>
      </div>
    </Modal>
  ) : (
    ""
  );

  return (
    <div>
      {modal}
      <button className={classNameProp} onClick={updateShowUploadModal}>
        업로드
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    monthData: state.recommend.user.monthData,
    amountData: state.recommend.user.amountData,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    month: state.recommend.calendar.month,
    year: state.recommend.calendar.year,
    selectedDays: state.recommend.selection.selectedDays,
    modifyState: state.recommend.selection.modifyState,
    displayingDays: state.recommend.selection.displayingDays,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateLoading: (loading) =>
      dispatch({
        type: actionTypes.UPDATE_LOADING,
        loading: loading,
      }),
  };
};

const Button = styled.div`
  background: #599eff;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 10px 25px;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin: -10px 5px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  &:hover {
    color: black;
  }
`;

const Modal = styled.div`
  z-index: 1000;
  height: 200px;
  width: 400px;
  position: fixed;
  top: 30%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Colors.gray_1};
  background-color: white;
  border-radius: 10px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
