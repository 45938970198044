import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import * as actionTypes from "data/recommendation/actionTypes";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledBox = styled.div`
  background: #ffffff;
  color: #white;
  font-size: 25px !important;
  // font-family: "NanumGothicBold", cursive;
  margin: 0 auto;
  margin-top: 0rem;
  text-align: left bottom;
  vertical-align: bottom;
  z-index: 100;
  border: 1px black;
`;

const SelectBox = (props) => {
  useEffect(() => {
    if (props.monthOrWeek === "week") {
      setMeal("");
    } else {
      setMeal("");
    }
  }, [props.monthOrWeek]);

  const classes = useStyles();
  const [meal, setMeal] = React.useState("");
  const [meal2, setMeal2] = React.useState("");
  const handleChange = (event) => {
    if (props.highlightState) {
      props.onUpdateHighlightState();
    }

    if (props.monthOrWeek === "week") {
      setMeal(event.target.value);
      if (event.target.value === 10) {
        props.onUpdateShowLD("L");
      } else if (event.target.value === 20) {
        props.onUpdateShowLD("D");
      } else if (event.target.value === "") {
        props.onUpdateShowLD("LD");
      }
    } else {
      setMeal2(event.target.value);
      if (event.target.value === "" || event.target.value === 10) {
        props.onUpdateShowLD("L");
        // props.onUpdateVersion(1);
      } else if (event.target.value === 20) {
        props.onUpdateShowLD("D");
        // props.onUpdateVersion(2);
      }
      props.onResetSelectedOn();
    }
  };

  let selection = "";

  if (props.monthOrWeek === "week") {
    selection = (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={meal}
        onChange={handleChange}
        displayEmpty
        disableUnderline
        style={{
          marginTop: "-2px",
          fontSize: "2rem",
        }}
      >
        <MenuItem value={""}>중 · 석식</MenuItem>
        <MenuItem value={10}>중식</MenuItem>
        <MenuItem value={20}>석식</MenuItem>
      </Select>
    );
  } else {
    selection = (
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={meal2}
        onChange={handleChange}
        displayEmpty
        disableUnderline
        style={{
          marginTop: "-2px",
          fontSize: "2rem",
        }}
      >
        <MenuItem value={""}>중식</MenuItem>
        <MenuItem value={20}>석식</MenuItem>
      </Select>
    );
  }

  return (
    <StyledBox>
      <div
        style={{
          color: "black",
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              style={{
                textAlign: "center",
              }}
            ></InputLabel>
            {selection}
          </FormControl>
        </div>
      </div>
    </StyledBox>
  );
};

const mapStateToProps = (state) => {
  return {
    showLD: state.recommend.calendar.showLD,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
    highlightState: state.recommend.highlight.highlightState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateShowLD: (LD) =>
      dispatch({
        type: actionTypes.UPDATE_SHOW_LD,
        showLD: LD,
      }),

    onResetSelectedOn: () =>
      dispatch({
        type: actionTypes.RESET_SELECTED_ON,
      }),

    onUpdateHighlightState: () =>
      dispatch({
        type: actionTypes.UPDATE_HIGHLIGHT_STATE,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectBox);
