import React, { useState } from "react";
import styled from "styled-components";

import { useFoodGroups } from "hooks";
import { Title, MiniDashContainer } from "pages/MasterPages/StyledComps";

import NuviFoodEditModal from "./nuvi-food-edit-modal";

export default function MiniDashBox({ selectedNuviFood }) {
  const [modalOpen, setModalOpen] = useState(false);

  const foodGroups = useFoodGroups();

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const handleFoodGroupName = (foodGroupId) => {
    for (let i = 0; i < foodGroups.length; i++) {
      if (foodGroups[i].id === foodGroupId) {
        return foodGroups[i].groupName;
      }
    }
    return "없음";
  };

  if (selectedNuviFood) {
    return (
      <MiniDashContainer>
        <NuviFoodEditModal
          open={modalOpen}
          onClose={handleModalOpen}
          selectedNuviFood={selectedNuviFood}
        />
        <Title>세부정보</Title>
        <EditContainer>
          <ItemContainer>
            <ItemLabel>누비푸드이름</ItemLabel>
            <ItemValue>{selectedNuviFood.foodName}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>제공량(g)</ItemLabel>
            <ItemValue>{selectedNuviFood.servingAmount}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>칼로리(kcal)</ItemLabel>
            <ItemValue>{selectedNuviFood.calories}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>탄수화물(g)</ItemLabel>
            <ItemValue>{selectedNuviFood.carbohydrate_g}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>단백질(g)</ItemLabel>
            <ItemValue>{selectedNuviFood.protein_g}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>지방(g)</ItemLabel>
            <ItemValue>{selectedNuviFood.fat_g}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>비타민 A(ug RE)</ItemLabel>
            <ItemValue>{selectedNuviFood.vitamin_a_ug_re}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>비타민 B1(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.vitamin_b1_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>비타민 B2(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.vitamin_b2_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>비타민 C(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.vitamin_c_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>칼슘(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.calcium_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>철분(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.iron_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>나트륨(mg)</ItemLabel>
            <ItemValue>{selectedNuviFood.sodium_mg}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>출처</ItemLabel>
            <ItemValue>{selectedNuviFood.source}</ItemValue>
          </ItemContainer>
          <ItemContainer>
            <ItemLabel>식품군</ItemLabel>
            <ItemValue>
              {handleFoodGroupName(selectedNuviFood.foodGroupId)}
            </ItemValue>
          </ItemContainer>
        </EditContainer>
        <ButtonContainer>
          <EditButton type="button" onClick={handleModalOpen}>
            수정하기
          </EditButton>
        </ButtonContainer>
      </MiniDashContainer>
    );
  } else {
    return (
      <MiniDashContainer>
        <Title>세부정보</Title>
        <div>선택된 누비푸드가 없습니다</div>
      </MiniDashContainer>
    );
  }
}

const ItemContainer = styled.div`
  width: 100%;
  border: 1px solid white;
  display: flex;
  padding: 2px;
  margin: 2px;
`;

const ItemLabel = styled.div`
  width: 100%;
  border: 1px solid white;
  border-right: 2px solid lightgray;
  margin-right: 10px;
  font-size: 25px;
  flex: 1;
`;

const ItemValue = styled.div`
  width: 100%;
  border: 1px solid white;
  flex: 2;
  font-size: 25px;
`;

const EditContainer = styled.div`
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  min-height: 50vh;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

const EditButton = styled.button`
  width: 80px;
  height: 40px;
  cursor: pointer;
`;