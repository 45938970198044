import { apiClient } from "../client";

export const api = {
  registerTraditional: async userRegisterInfo => {
    return await apiClient.post(
      "/api/admin/auth/register/traditional",
      userRegisterInfo
    );
  },
  registerSocial: async userRegisterInfo => {
    return await apiClient.post("/api/admin/auth/register/social", userRegisterInfo);
  },

  loginTraditional: async userLoginInfo => {
    return await apiClient.post("/api/admin/auth/login/traditional", userLoginInfo);
  },

  loginSocial: async userLoginInfo => {
    return await apiClient.post("/api/admin/auth/login/social", userLoginInfo);
  },

  checkEmail: async emailAddress => {
    return await apiClient.post("/api/admin/auth/checkEmail", emailAddress);
  },
  logout: async () => {
    return await apiClient.get("/api/admin/auth/logout");
  }
};