export const bapMainList = [
  "밥",
  "필라프",
  "덮밥",
  "영양밥",
  "잡채밥",
  "불백",
  "김밥",
  "컵밥",
  "쌀밥",
  "라이스",
  "밥버거",
  "흰죽",
  "주먹밥",
  "회덮밥",
  "하이라이스",
  "카레라이스",
  "초밥",
  "수수밥",
  "콩밥",
  "짜장밥",
  "짬뽕밥",
  "오므라이스",
  "비빔밥",
  "볶음밥"
];
export const gukMainList = [
  "탕",
  "국",
  "면",
  "죽",
  "알탕",
  "깨죽",
  "콩죽",
  "팥죽",
  "잣죽",
  "모밀",
  "백숙",
  "냉면",
  "전골",
  "육수",
  "국물",
  "라면",
  "국수",
  "장국",
  "냉국",
  "국밥",
  "냉국",
  "닭죽",
  "버거",
  "카레",
  "짬뽕",
  "떡국",
  "우동",
  "찌개",
  "라멘",
  "냉면",
  "우동",
  "스프",
  "설렁탕",
  "콩국수",
  "호박죽",
  "감자죽",
  "고기죽",
  "추어탕",
  "버섯죽",
  "새우죽",
  "소라죽",
  "수수죽",
  "야채죽",
  "전복죽",
  "매운탕",
  "칼국수",
  "쌀국수",
  "육개장",
  "냉모밀",
  "씨리얼",
  "오믈렛",
  "수제비",
  "닭개장",
  "리조또",
  "만두국",
  "시리얼",
  "삼계탕",
  "미역국",
  "된장국",
  "청국장",
  "감자탕",
  "햄버거",
  "된장국",
  "짜글이",
  "파스타",
  "부대찌개",
  "두루치기",
  "스파게티",
  "김치찌개",
  "된장찌개",
  "비지찌개",
  "옥수수죽"
];
export const gimchiMainList = ["김치", "배추김치", "오이지", "깍두기"];
export const banchanMainList = [
  "묵",
  "찜",
  "쌈",
  "전",
  "회",
  "롤",
  "김",
  "닭",
  "떡",
  "윙",
  "젓",
  "꼬치",
  "피자",
  "완자",
  "튀김",
  "냉채",
  "간장",
  "소스",
  "만두",
  "치킨",
  "볶음",
  "절임",
  "조림",
  "에그",
  "고기",
  "순대",
  "된장",
  "자반",
  "야채",
  "커리",
  "너겟",
  "고추",
  "까스",
  "쌈무",
  "함박",
  "폭립",
  "오리",
  "가지",
  "오이",
  "야끼",
  "탕수",
  "버섯",
  "절이",
  "피클",
  "감자",
  "두부",
  "지짐",
  "갈비",
  "범벅",
  "방어",
  "삼치",
  "참치",
  "쌈장",
  "숙회",
  "채소",
  "계란",
  "고추",
  "양념",
  "가스",
  "새우",
  "까스",
  "말이",
  "멸치",
  "장어",
  "초장",
  "곤약",
  "숙회",
  "나물",
  "맛김",
  "찜닭",
  "자반",
  "생채",
  "텐더",
  "맛탕",
  "잡채",
  "무침",
  "꼬치",
  "수육",
  "폭찹",
  "강정",
  "구이",
  "아구찜",
  "양배추",
  "새우젓",
  "소세지",
  "갈비찜",
  "단무지",
  "취나물",
  "장아찌",
  "계란찜",
  "갈비찜",
  "탕수육",
  "바베큐",
  "커틀렛",
  "후라이",
  "브리또",
  "탕수육",
  "새우볼",
  "아몬드",
  "고추장",
  "떡볶이",
  "베이글",
  "발효유",
  "크로켓",
  "바게트",
  "백설기",
  "요플레",
  "겉절이",
  "오징어",
  "삼겹살",
  "탕평채",
  "깐풍기",
  "닭다리",
  "부침개",
  "부꾸미",
  "베이컨",
  "닭갈비",
  "군만두",
  "김치전",
  "부추전",
  "드레싱",
  "겉절이",
  "불고기",
  "사라다",
  "부추전",
  "또띠아",
  "돈가스",
  "닭강정",
  "미트볼",
  "떡갈비",
  "양념장",
  "장조림",
  "떡볶이",
  "초무침",
  "물만두",
  "주물럭",
  "샐러드",
  "무나물",
  "커틀릿",
  "고등어",
  "돈까스",
  "그라탕",
  "닭도리탕",
  "꿔바로우",
  "동그랑땡",
  "후라이드",
  "마요네즈",
  "간장조림",
  "마카로니",
  "클로렐라",
  "꼬치구이",
  "차돌박이",
  "돼지갈비",
  "두부김치",
  "오리고기",
  "웨지감자",
  "머스터드",
  "양념치킨",
  "오징어채",
  "마파두부",
  "팽이버섯",
  "포테이토",
  "숙주나물",
  "오징어링",
  "오향장육",
  "비빔냉면",
  "가라아게",
  "파전",
  "부침",
  "너비아니",
  "도토리묵",
  "돼지고기",
  "쿼사디아",
  "생선까스",
  "계란말이",
  "표고버섯",
  "초고추장",
  "스크램블",
  "무말랭이",
  "메추리알"
];
export const dessertMainList = [
  "차",
  "빵",
  "바",
  "칩",
  "즙",
  "물",
  "감",
  "배",
  "식빵",
  "사탕",
  "견과",
  "망고",
  "유과",
  "메론",
  "과자",
  "멜론",
  "전병",
  "화채",
  "과일",
  "키위",
  "치즈",
  "포도",
  "딸기",
  "샌드",
  "식혜",
  "와플",
  "단감",
  "젤리",
  "초코",
  "참외",
  "송편",
  "사과",
  "스틱",
  "호떡",
  "찰떡",
  "건빵",
  "절편",
  "우유",
  "숭늉",
  "라떼",
  "커피",
  "케익",
  "파이",
  "쿠키",
  "빙수",
  "푸딩",
  "두유",
  "주스",
  "도넛",
  "머핀",
  "음료",
  "마카롱",
  "바나나",
  "오렌지",
  "인절미",
  "꽈배기",
  "타르트",
  "츄러스",
  "누룽지",
  "시리얼",
  "복숭아",
  "요거트",
  "토마토",
  "음료수",
  "아이스",
  "닭꼬치",
  "청포도",
  "초콜릿",
  "초코칩",
  "옥수수",
  "브래드",
  "드킹크",
  "치즈볼",
  "고구마",
  "케이크",
  "도너츠",
  "핫케익",
  "고로케",
  "에이드",
  "토스트",
  "핫도그",
  "브레드",
  "쉐이크",
  "스무디",
  "샤베트",
  "슬러시",
  "후레이크",
  "브라우니",
  "미숫가루",
  "아이스바",
  "크로와상",
  "요구르트",
  "치즈스틱",
  "퀘사디아",
  "카스테라",
  "요쿠르트",
  "샌드위치",
  "야쿠르트",
  "브로콜리",
  "파프리카",
  "블루베리",
  "파인애플"
];
