import { all, fork } from "redux-saga/effects";

import userSaga from "data/user/sagaRegister";
import adminSaga from "data/admins/sagaRegister";
import inventorySaga from "data/inventory/sagaRegister";
import modalSaga from "data/modal/sagaRegister";
import dashboardSaga from "data/dashboard/sagaRegister";
import pointSaga from "data/point/sagaRegister";

export default function*() {
  yield all([
    fork(userSaga),
    fork(adminSaga),
    fork(inventorySaga),
    fork(modalSaga),
    fork(dashboardSaga),
    fork(pointSaga)
  ]);
}
