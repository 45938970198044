import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Formik, Form } from "formik";

import styled from "styled-components";

import { apiClient } from "api/client";

import {
  useNuviFoodMainCategories,
  useNuviFoodSubCategories,
  useDepartmentList,
  useFoodGroups,
} from "hooks";

import { nuviSortFunction } from "pages/MasterPages/helper";

import "../pagination.css";

import Emoji from "components/Basics/Emoji";

export default function CombineNewNuviFood() {
  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();

  const departmentList = useDepartmentList();
  const foodGroups = useFoodGroups();

  const [keyword, setKeyword] = useState("");
  const [nuviFoodSearchResult, setNuviFoodSearchResult] = useState([]);
  const [selectedNuviFoodList, setSelectedNuviFoodList] = useState([]);

  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);

  // 음식 검색 시 대/중분류
  const [nuviFoodMainCategoryId, setNuviFoodMainCategoryId] = useState(null);
  const [nuviFoodSubCategoryId, setNuviFoodSubCategoryId] = useState(null);

  // 최종 결과물의 대/중분류
  const [nuviMainCategoryId, setNuviMainCategoryId] = useState(null);
  const [nuviSubCategoryId, setNuviSubCategoryId] = useState(null);

  const [departmentId, setDepartmentId] = useState(null);
  const [foodGroupId, setFoodGroupId] = useState(null);

  const [foodName, setFoodName] = useState("");
  const [source, setSource] = useState("");
  const [date, setDate] = useState(null);
  const [bld, setBld] = useState(null);

  const handleFoodName = (e) => {
    setFoodName(e.target.value);
  };

  const handleSource = (e) => {
    setSource(e.target.value);
  };

  const handleDate = (e) => {
    setDate(e.target.value);
  };

  const handleBld = (e) => {
    setBld(e.target.value);
  };

  const BLD_OPTIONS = [
    { label: "조식", value: "B" },
    { label: "중식", value: "L" },
    { label: "석식", value: "D" },
  ];

  // 음식 검색 시 대/중분류 핸들링
  const handleNuviFoodMainCategoryId = (e) => {
    setNuviFoodMainCategoryId(e.target.value);
  };

  const handleNuviFoodSubCategoryId = (e) => {
    setNuviFoodSubCategoryId(e.target.value);
  };

  // 최종 결과물 대/중분류 핸들링
  const handleNuviMainCategoryId = (e) => {
    setNuviMainCategoryId(e.target.value);
  };

  const handleNuviSubCategoryId = (e) => {
    setNuviSubCategoryId(e.target.value);
  };

  const handleFoodGroupId = (e) => {
    setFoodGroupId(e.target.value);
  };

  const handleDepartmentId = (e) => {
    setDepartmentId(e.target.value);
  };

  const handleKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const searchNuviFood = async () => {
    const res = await apiClient.get(`/api/food/nuvi-food/list`, {
      params: {
        foodName: keyword,
        nuviFoodMainCategoryId,
        nuviFoodSubCategoryId,
      },
    });
    setNuviFoodSearchResult(res.data);
    document.getElementById("keyword").value = "";
    setKeyword("");
  };

  const searchOnPressEnter = (e) => {
    if (e.key !== "Enter") {
      return;
    } else {
      searchNuviFood();
    }
  };

  const handleDepartmentName = (depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  };

  const onSubmitValues = async (values) => {
    const {
      source,
      foodName,
      servingAmount,
      calories,
      carbohydrate_g,
      protein_g,
      fat_g,
      sodium_mg,
      foodMainCategoryId,
      foodSubCategoryId,
      nuviFoodMainCategoryId,
      nuviFoodSubCategoryId,
      foodGroupId,
      departmentId,
      date,
      bld,
    } = finalResult;
    if (
      source !== "" &&
      foodName !== "" &&
      servingAmount !== "" &&
      calories !== "" &&
      carbohydrate_g !== "" &&
      protein_g !== "" &&
      fat_g !== "" &&
      sodium_mg !== "" &&
      foodMainCategoryId !== "" &&
      foodSubCategoryId !== "" &&
      nuviFoodMainCategoryId !== "" &&
      nuviFoodSubCategoryId !== "" &&
      departmentId &&
      date &&
      bld
    ) {
      if (window.confirm("누비푸드에 추가하시겠습니까?")) {
        makeNewNuviFood();
      }
    } else {
      alert("추가할 음식정보를 모두 기입해주세요");
    }
  };

  const makeNewNuviFood = async () => {
    try {
      const res = await apiClient.post(`/api/food/nuvi-food`, finalResult);
      alert("누비푸드가 추가되었습니다");

      await setSelectedNuviFoodList([]);

      setNuviFoodSearchResult([]);

      document.getElementById("nuviMainCategoryId").selectedIndex = 0;
      setNuviFoodMainCategoryId(null);
      document.getElementById("nuviSubCategoryId").selectedIndex = 0;
      setNuviFoodSubCategoryId(null);
      document.getElementById("foodGroupId").selectedIndex = 0;
      setFoodGroupId(null);
      document.getElementById("source").value = "";
      setSource("");
      document.getElementById("foodName").value = "";
      setFoodName("");
      document.getElementById("departmentId").value = "";
      setDepartmentId(null);
      document.getElementById("date").value = "";
      setDate(null);
      document.getElementById("bld").value = "";
      setBld(null);
    } catch (e) {
      alert(e.message);
    }
  };

  const addToSelectedNuviFoodList = (nuviFood) => {
    const nuviFoodWithDefaultRatio = { ...nuviFood, ratio: 1 };

    const index = selectedNuviFoodList
      .map((el) => JSON.stringify(el))
      .indexOf(JSON.stringify(nuviFoodWithDefaultRatio));

    if (index === -1) {
      setSelectedNuviFoodList([
        ...selectedNuviFoodList,
        nuviFoodWithDefaultRatio,
      ]);
    } else {
      alert("이미 추가한 항목입니다");
    }
  };

  const deleteFromSelectedNuviFoodList = (nuviFood) => {
    const index = selectedNuviFoodList.indexOf(nuviFood);
    const updatedList = selectedNuviFoodList.slice();
    updatedList.splice(index, 1);
    setSelectedNuviFoodList(updatedList);
  };

  let defaultValues = {
    source: "",
    foodName: "",
    servingAmount: null,
    calories: null,
    carbohydrate_g: null,
    protein_g: null,
    fat_g: null,
    sodium_mg: null,
    vitamin_a_ug_re: null,
    vitamin_b1_mg: null,
    vitamin_b2_mg: null,
    vitamin_c_mg: null,
    calcium_mg: null,
    iron_mg: null,
  };

  let finalResult = selectedNuviFoodList.reduce((a, c) => {
    let newObj = {};
    const ratio = Number(c.ratio);
    newObj["servingAmount"] = a.servingAmount + c.servingAmount * ratio;
    newObj["calories"] = a.calories + c.calories * ratio;
    newObj["carbohydrate_g"] = a.carbohydrate_g + c.carbohydrate_g * ratio;
    newObj["protein_g"] = a.protein_g + c.protein_g * ratio;
    newObj["fat_g"] = a.fat_g + c.fat_g * ratio;
    newObj["sodium_mg"] = a.sodium_mg + c.sodium_mg * ratio;
    newObj["vitamin_a_ug_re"] = a.vitamin_a_ug_re + c.vitamin_a_ug_re * ratio;
    newObj["vitamin_b1_mg"] = a.vitamin_b1_mg + c.vitamin_b1_mg * ratio;
    newObj["vitamin_b2_mg"] = a.vitamin_b2_mg + c.vitamin_b2_mg * ratio;
    newObj["vitamin_c_mg"] = a.vitamin_c_mg + c.vitamin_c_mg * ratio;
    newObj["calcium_mg"] = a.calcium_mg + c.calcium_mg * ratio;
    newObj["iron_mg"] = a.iron_mg + c.iron_mg * ratio;
    return newObj;
  }, defaultValues);

  finalResult["foodName"] = foodName.trim();
  finalResult["source"] = source;
  finalResult["foodMainCategoryId"] = mainCategoryId;
  finalResult["foodSubCategoryId"] = subCategoryId;
  finalResult["nuviFoodMainCategoryId"] = nuviMainCategoryId;
  finalResult["nuviFoodSubCategoryId"] = nuviSubCategoryId;
  finalResult["foodGroupId"] = foodGroupId;
  finalResult["departmentId"] = departmentId;
  finalResult["date"] = date;
  finalResult["bld"] = bld;

  // pagination
  const [currentPage, setCurrentPage] = useState(0);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const PER_PAGE = 5;
  const offset = currentPage * PER_PAGE;
  const currentPageData = nuviFoodSearchResult
    .sort(nuviSortFunction)
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer
        key={idx}
        onClick={() => addToSelectedNuviFoodList(el)}
      >
        <ResultRow>{el.id}</ResultRow>
        <ResultRow style={{ flex: 2 }}>{el.foodName}</ResultRow>
        <ResultRow>{el.servingAmount}</ResultRow>
        <ResultRow>{el.calories}</ResultRow>
        <ResultRow style={{ flex: 1.5 }}>
          {handleDepartmentName(el.departmentId)}
        </ResultRow>
        <ResultRow>{el.source}</ResultRow>
      </ResultRowContainer>
    ));

  const pageCount = Math.ceil(nuviFoodSearchResult.length / PER_PAGE);

  return (
    <Maker>
      <MenuTitle>조합해서 추가하기</MenuTitle>
      <Formik>
        <StyledForm>
          <StepContainer>
            <MenuName>누비푸드 검색</MenuName>

            <SearchBarContainer>
              <StyledSelect
                onChange={handleNuviFoodMainCategoryId}
                style={{ height: "30px" }}
              >
                <option value="">없음</option>
                {nuviFoodMainCategories.map(({ id, categoryName }) => (
                  <option value={id}>{categoryName}</option>
                ))}
              </StyledSelect>

              <StyledSelect
                onChange={handleNuviFoodSubCategoryId}
                style={{ height: "30px" }}
              >
                <option value="">없음</option>
                {nuviFoodSubCategories
                  .filter(
                    (subCategory) =>
                      subCategory.nuviFoodMainCategoryId ===
                      Number(nuviFoodMainCategoryId)
                  )
                  .map(({ id, categoryName }) => (
                    <option value={id}>{categoryName}</option>
                  ))}
              </StyledSelect>

              <StyledTextInput
                id="keyword"
                type="text"
                onChange={handleKeyword}
                placeholder="  조합할 누비푸드를 검색하세요"
                onKeyPress={searchOnPressEnter}
              />
              <StyledButton
                id="searchButton"
                type="button"
                onClick={searchNuviFood}
                style={{ width: "120px" }}
              >
                검색
              </StyledButton>
            </SearchBarContainer>
          </StepContainer>

          <StepContainer>
            <MenuName>검색결과</MenuName>
            <ResultContainer>
              <ResultRowNameContainer>
                <ResultRow>id</ResultRow>
                <ResultRow style={{ flex: 2 }}>누비푸드이름</ResultRow>
                <ResultRow>제공량(g)</ResultRow>
                <ResultRow>칼로리(kcal)</ResultRow>
                <ResultRow style={{ flex: 1.5 }}>기관</ResultRow>
                <ResultRow >출처</ResultRow>
              </ResultRowNameContainer>
              {currentPageData}
              <ReactPaginate
                previousLabel={"← 이전"}
                nextLabel={"다음 →"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </ResultContainer>
          </StepContainer>

          <StepContainer style={{ transition: "0.5s" }}>
            <MenuName>조합결과</MenuName>
            <ResultContainer>
              <ResultRowNameContainer>
                <ResultRow>출처</ResultRow>
                <ResultRow style={{ flex: 2 }}>누비푸드이름</ResultRow>
                <ResultRow>제공량(g)</ResultRow>
                <ResultRow>칼로리(kcal)</ResultRow>
                <ResultRow>비율</ResultRow>
                <ResultRow>{"삭제"}</ResultRow>
              </ResultRowNameContainer>
              {selectedNuviFoodList.map((nuviFood, idx) => (
                <CombinationElement
                  key={idx}
                  nuviFood={nuviFood}
                  selectedNuviFoodList={selectedNuviFoodList}
                  deleteFromSelectedNuviFoodList={
                    deleteFromSelectedNuviFoodList
                  }
                  setSelectedNuviFoodList={setSelectedNuviFoodList}
                />
              ))}
            </ResultContainer>
          </StepContainer>

          <StepContainer>
            <MenuName>최종결과</MenuName>
            <ResultContainer>
              <CombinationResult
                selectedNuviFoodList={selectedNuviFoodList}
                mainCategoryId={mainCategoryId}
                subCategoryId={subCategoryId}
                handleFoodName={handleFoodName}
                handleSource={handleSource}
                finalResult={finalResult}
              />

              <ResultRowContainer
                style={{
                  padding: "12px",
                  cursor: "default",
                }}
              >
                <ResultRow>기관</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <StyledSelect id="departmentId" onChange={handleDepartmentId}>
                    <option value={""}>선택</option>
                    {departmentList.map((department, idx) => (
                      <option key={idx} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </StyledSelect>
                </ResultRow>

                <ResultRow>일자</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <input id="date" type="date" onChange={handleDate} />
                </ResultRow>

                <ResultRow>BLD</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <StyledSelect id="bld" onChange={handleBld}>
                    <option value="">없음</option>
                    {BLD_OPTIONS.map(({ value, label }) => (
                      <option value={value}>{label}</option>
                    ))}
                  </StyledSelect>
                </ResultRow>
              </ResultRowContainer>

              <ResultRowContainer
                style={{
                  padding: "12px",
                  cursor: "default",
                }}
              >
                <ResultRow>대분류</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <StyledSelect
                    id="nuviMainCategoryId"
                    onChange={handleNuviMainCategoryId}
                  >
                    <option value={""}>선택</option>
                    {nuviFoodMainCategories.map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.categoryName}
                      </option>
                    ))}
                  </StyledSelect>
                </ResultRow>

                <ResultRow>중분류</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <StyledSelect
                    id="nuviSubCategoryId"
                    onChange={handleNuviSubCategoryId}
                  >
                    <option value={""}>선택</option>
                    {nuviFoodSubCategories
                      .filter(
                        // 대분류에 속하는 중분류만 표출
                        (subCategory) =>
                          subCategory.nuviFoodMainCategoryId ===
                          Number(nuviMainCategoryId)
                      )
                      .map((el, idx) => (
                        <option key={idx} value={el.id}>
                          {el.categoryName}
                        </option>
                      ))}
                  </StyledSelect>
                </ResultRow>

                <ResultRow>식품군</ResultRow>
                <ResultRow style={{ flex: 2 }}>
                  <StyledSelect id="foodGroupId" onChange={handleFoodGroupId}>
                    <option value={""}>없음</option>
                    {foodGroups.map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.groupName}
                      </option>
                    ))}
                  </StyledSelect>
                </ResultRow>
              </ResultRowContainer>

              <StyledButton type="button" onClick={onSubmitValues}>
                누비푸드에 추가하기
              </StyledButton>
            </ResultContainer>
          </StepContainer>
        </StyledForm>
      </Formik>
    </Maker>
  );
}

function CombinationElement({
  nuviFood,
  selectedNuviFoodList,
  setSelectedNuviFoodList,
  deleteFromSelectedNuviFoodList,
}) {
  const [ratio, setRatio] = useState(1);

  const handleRatio = (e) => {
    const ratio = e.target.value;
    const index = selectedNuviFoodList.indexOf(nuviFood);
    const updatedNuviFood = { ...nuviFood, ratio };
    const updatedList = selectedNuviFoodList.slice();
    updatedList.splice(index, 1, updatedNuviFood);
    setSelectedNuviFoodList(updatedList);
    setRatio(ratio);
  };

  return (
    <ResultRowContainer style={{ cursor: "default" }}>
      <ResultRow >{nuviFood.source}</ResultRow>
      <ResultRow style={{ flex: 2 }}>{nuviFood.foodName}</ResultRow>
      <ResultRow>{(nuviFood.servingAmount * ratio).toFixed(2)}</ResultRow>
      <ResultRow>{(nuviFood.calories * ratio).toFixed(2)}</ResultRow>
      <ResultRow>
        <StyledInput
          type="number"
          onChange={handleRatio}
          defaultValue={1}
          step="0.1"
          min="0"
        />
      </ResultRow>
      <ResultRow
        onClick={() => deleteFromSelectedNuviFoodList(nuviFood)}
        style={{ cursor: "pointer" }}
      >
        <Emoji symbol="🗑️" label="bin" />
      </ResultRow>
    </ResultRowContainer>
  );
}

function CombinationResult({ handleFoodName, handleSource, finalResult }) {
  return (
    <React.Fragment>
      <ResultRowContainer style={{ cursor: "default" }}>
        <ResultRow>누비푸드이름</ResultRow>
        <ResultRow style={{ flex: 2, height: "28px" }}>
          <StyledInput
            id="foodName"
            type="text"
            onChange={handleFoodName}
            placeholder="누비푸드 이름을 입력해주세요"
          />
        </ResultRow>

        <ResultRow>출처</ResultRow>
        <ResultRow style={{ flex: 2, height: "28px" }}>
          <StyledInput
            id="source"
            type="text"
            onChange={handleSource}
            placeholder="출처를 입력해주세요"
          />
        </ResultRow>
      </ResultRowContainer>

      <ResultRowContainer style={{ cursor: "default" }}>
        <ResultRow>제공량:</ResultRow>
        <ResultRow style={{ flex: 2, height: "28px" }}>
          {finalResult.servingAmount
            ? finalResult.servingAmount.toFixed(2)
            : null}
          g
        </ResultRow>
        <ResultRow>열량:</ResultRow>
        <ResultRow style={{ flex: 2, height: "28px" }}>
          {finalResult.calories ? finalResult.calories.toFixed(2) : null}kcal
        </ResultRow>
      </ResultRowContainer>
    </React.Fragment>
  );
}

const StyledInput = styled.input`
  width: 100%;
  height: 25px;
`;

const Maker = styled.div`
  flex: 1;
  background-color: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StyledForm = styled(Form)`
  flex: 1;
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  gap: 10px;
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ResultRowContainer = styled.div`
  width: 90%;
  background-color: white;

  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 2px;
  border: 1px solid black;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 20px;
  text-align: center;
  padding: 2px;
  margin: 3px;
  font-size: 16px;
  flex: 1;

  // border: 1px solid red;
`;

const MenuTitle = styled.div`
  width: 100%;
  background-color: none;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 20px;
`;

const SearchBarContainer = styled.div`
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  gap: 10px;
`;

const StyledTextInput = styled.input`
  width: 300px;
  height: 35px;
  border: 1px solid black;
  border-radius: 5px;
`;

const StyledButton = styled.button`
  width: 150px;
  height: 35px;
  margin: 20px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;

const MenuName = styled.div`
  width: 100%;
  font-size: 22px;
  padding: 5px;
`;

const StyledSelect = styled.select`
  width: 100px;
  height: auto;
`;

const StepContainer = styled.div`
  width: 97%;
`;
