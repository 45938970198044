import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { actions } from "data";
import Colors from "theme/colors";

export default function MakeCustomFoodModal({ nuviFood, onConfirm }) {
  const dispatch = useDispatch();
  const [foodName, setFoodName] = useState("");

  const handleFoodName = e => {
    setFoodName(e.target.value);
  };
  return (
    <Modal>
      <Text>음식이름을 설정해주세요</Text>
      <input type="text" onChange={handleFoodName} />
      <ButtonsContainer>
        <Button
          onClick={() => {
            const newCustomFood = {
              ...nuviFood,
              foodName
            };
            onConfirm(newCustomFood);
            dispatch(actions.modal.clearModal());
          }}
        >
          확인
        </Button>
        <Button
          onClick={() => {
            dispatch(actions.modal.clearModal());
          }}
        >
          취소
        </Button>
      </ButtonsContainer>
    </Modal>
  );
}

const Modal = styled.div`
  width: 300px;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid ${Colors.gray_1};
  border-radius: 10px;
`;

const Text = styled.div`
  color: ${Colors.blue_1};
  width: 230px;
  font-size: 16px;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin: 10px;
`;

const Button = styled.div`
  background-color: ${Colors.blue_1};
  border: 1px solid ${Colors.gray_1};
  border-radius: 10px;
  color: white;
  font-size: 16px;

  width: 100px;
  height: 60px;
  margin: 10px 0;
  text-align: center;
  line-height: 60px;
  cursor: pointer;

`;
