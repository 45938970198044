import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import DetailsTable from "../DetailsTable";
import * as actionTypes from "data/recommendation/actionTypes";
import {
  getComponentInfo,
  changeOneFood,
} from "../../../../data/recommendation/actions/recommend";
import { actions } from "data";

import Colors from "theme/colors";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  cell1: {
    padding: "0px",
    textAlign: "center",
    width: "100px",
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
}));

const FoodItem = ({
  nutritionInfo,
  onUpdateMenuList,
  onUpdateAmountList,
  onUpdateTotalNutrition,
  updateNutrition,
}) => {
  const foodItemInfo = [];
  const initState = [];
  const dispatch = useDispatch();
  const classes = useStyles();

  for (var i = 0; i < nutritionInfo.length; i++) {
    if (
      (nutritionInfo[i].name[0] === "(" &&
        nutritionInfo[i].name[nutritionInfo[i].name.length - 1] === ")") ||
      nutritionInfo[i].name[nutritionInfo[i].name.length - 1] === "l"
    ) {
      continue;
    }

    if (i === 0) {
      foodItemInfo.push({ isEditMode: true, ...nutritionInfo[i] });
      initState.push({ id: i, isEditMode: true, ...nutritionInfo[i] });
    } else {
      foodItemInfo.push({ isEditMode: false, ...nutritionInfo[i] });
      initState.push({ id: i, isEditMode: false, ...nutritionInfo[i] });
    }
  }
  const [refresh, setRefresh] = useState(true);
  const [rows, setRows] = useState(initState);
  const [previous, setPrevious] = useState({});
  const [previousRow, setPreviousRow] = useState([]);
  const [editting, setEditting] = useState(false);
  const [quantity, setQuantity] = useState({
    amount: 0,
    calories: 0,
    carbohydrate_g: 0,
    fat_g: 0,
    protein_g: 0,
  });
  const [showComponent, setShowComponent] = useState(false);
  const [componentInfo, setComponentInfo] = useState({});
  useEffect(() => {
    if (showComponent) {
      dispatch(
        actions.modal.setModal({
          modalType: "EMPTY",
          modalProps: {},
        })
      );
    }
  }, [showComponent]);

  useEffect(() => {
    console.log(refresh);
  }, [refresh]);

  useEffect(() => {
    setRows(foodItemInfo);
  }, [nutritionInfo]);

  const menuList = rows.map((row) => row.name);
  const amountList = rows.map((row) => row.amount);
  useEffect(() => {
    onUpdateMenuList(menuList);
  }, [menuList]);

  useEffect(() => {
    onUpdateAmountList(amountList);
  }, [amountList]);

  let newComponentInfo = {};
  async function getFoodComponent(foodName) {
    await getComponentInfo(foodName).then((data) => {
      newComponentInfo = JSON.parse(JSON.parse(data));
      setComponentInfo(newComponentInfo);
    });

    setShowComponent(true);
  }

  async function showFoodComponent(foodName) {
    await getFoodComponent(foodName);
  }

  const onToggleEditMode = (id) => {
    if (editting) {
      return;
    }
    setEditting(true);
    setPreviousRow(rows[id]);
    setRows(
      rows.map((row) => {
        if (row.id === id) {
          setPrevious((state) => ({ ...state, [id]: row.name }));
          setQuantity({
            name: row.name,
            amount: row.amount,
            calories: row.calories,
            carbohydrate_g: row.carbohydrate_g,
            fat_g: row.fat_g,
            protein_g: row.protein_g,
          });
          return { ...row, isEditMode: !row.isEditMode, id };
        }
        return row;
      })
    );
  };

  const getTotalNutrition = (rows) => {
    const keys = Object.keys(rows);
    const totalNutrition = {
      amount: 0,
      calories: 0,
      carbohydrate_g: 0,
      protein_g: 0,
      fat_g: 0,
    };
    for (var i = 0; i < keys.length; i++) {
      if ("calories" in rows[keys[i]]) {
        totalNutrition["calories"] += Number(rows[keys[i]]["calories"]);
        totalNutrition["carbohydrate_g"] += Number(
          rows[keys[i]]["carbohydrate_g"]
        );
        totalNutrition["protein_g"] += Number(rows[keys[i]]["protein_g"]);
        totalNutrition["fat_g"] += Number(rows[keys[i]]["fat_g"]);
        totalNutrition["amount"] += Number(rows[keys[i]]["amount"]);
      }
    }
    for (var i = 0; i < Object.keys(totalNutrition).length; i++) {
      totalNutrition[Object.keys(totalNutrition)[i]] =
        Math.round(totalNutrition[Object.keys(totalNutrition)[i]] * 100) / 100;
    }

    return totalNutrition;
  };

  const onReplaceOneFood = (id, menuList) => {
    setRefresh(false);
    changeOneFood(menuList[id], menuList).then((foodInfo) => {
      const [foodName, foodNutrition] = foodInfo;
      if (!foodName) {
        return;
      }
      const newRows = rows.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            name: foodName,
            amount: foodNutrition.amount,
            calories: foodNutrition.calories,
            carbohydrate_g: foodNutrition.carbohydrate_g,
            protein_g: foodNutrition.protein_g,
            fat_g: foodNutrition.fat_g,
          };
        }
        return row;
      });

      onUpdateTotalNutrition(getTotalNutrition(newRows));
      setRows(newRows);
    });
    setRefresh(true);
  };

  const onToggleConfirm = (id) => {
    setEditting(false);
    updateRowName(rows, id);
    updateRowAmount(rows, id);
    setPrevious({});
  };

  const updateRowName = (rows, id) => {
    let isNameChanged = false;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (quantity.name !== row.name) {
          isNameChanged = true;
        }

        return { ...row, isEditMode: !row.isEditMode, id };
      }
      return row;
    });
    setRows(newRows);

    if (isNameChanged) {
      updateNutrition(menuList);
    }
  };

  const updateRowAmount = (rows, id) => {
    let isAmountChanged = false;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (quantity.amount !== row.amount) {
          isAmountChanged = true;
        }

        return { ...row, isEditMode: !row.isEditMode, id };
      }
      return row;
    });
    setRows(newRows);
    if (isAmountChanged) {
      onUpdateTotalNutrition(getTotalNutrition(newRows));
    }
  };

  const onChangeName = (e, row) => {
    const newName = e.target.value;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, name: newName };
      }
      return row;
    });
    setRows(newRows);
  };

  const onChangeAmount = (e, row) => {
    const newAmount = e.target.value;
    const { id } = row;
    const ratio = newAmount / quantity.amount;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          amount: newAmount,
          calories: (ratio * quantity.calories).toFixed(2),
          carbohydrate_g: (ratio * quantity.carbohydrate_g).toFixed(2),
          fat_g: (ratio * quantity.fat_g).toFixed(2),
          protein_g: (ratio * quantity.protein_g).toFixed(2),
        };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    setEditting(false);
    const oldRows = rows.map((row) => {
      if (row.id === id) {
        return previous[id] ? { ...row, name: previous[id], id } : row;
      }
      return row;
    });
    setRows(() => {
      return oldRows.map((row) => {
        if (row.id === id) {
          return { ...previousRow, isEditMode: !row.isEditMode, id };
        }
        return row;
      });
    });
    setPrevious({});
  };

  const closeComponent = () => {
    dispatch(actions.modal.clearModal());
    setShowComponent(false);
  };

  const modal = showComponent ? (
    <Modal>
      <CompTable>
        <DetailsTable componentInfo={componentInfo} />
        <Button>
          <button onClick={() => closeComponent()}>닫기</button>
        </Button>
      </CompTable>
    </Modal>
  ) : (
    ""
  );

  if (!refresh) {
    return "Loading..";
  }

  const cells = rows.map((row, idx) => (
    <TableRow key={idx}>
      <TableCell style={{ width: "60px", padding: 0 }}>
        {modal}
        {rows.filter((el) => el.id === row.id)[0].isEditMode ? (
          <div key={idx}>
            <IconButton
              aria-label="done"
              onClick={() => onToggleConfirm(row.id)}
            >
              <DoneIcon />
            </IconButton>
            <IconButton aria-label="revert" onClick={() => onRevert(row.id)}>
              <RevertIcon />
            </IconButton>
          </div>
        ) : (
          <div key={idx} style={{ display: "flex", flexDirection: "column" }}>
            <IconButton
              aria-label="delete"
              onClick={() => onToggleEditMode(row.id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label=""
              onClick={() => onReplaceOneFood(row.id, menuList)}
            >
              <RefreshIcon />
            </IconButton>
          </div>
        )}
      </TableCell>
      <TableCell
        style={{
          padding: "0px",
          textAlign: "center",
          width: "80px",
        }}
      >
        {rows[row.id] && rows[row.id].isEditMode ? (
          <Input
            style={{ width: "80px" }}
            value={row.name}
            name={row.name}
            onChange={(e) => onChangeName(e, row)}
            className={classes.input}
            key={row.id}
          />
        ) : (
          <p
            style={{ width: "80px", fontSize: "15px" }}
            onClick={() => showFoodComponent(row.name)}
          >
            {row.name}
          </p>
        )}
      </TableCell>

      <TableCell
        style={{
          padding: "0px",
          textAlign: "center",
          width: "40px",
        }}
      >
        {rows[row.id] && rows[row.id].isEditMode ? (
          <Input
            style={{ width: "40px" }}
            value={row.amount}
            name={String(row.amount)}
            onChange={(e) => onChangeAmount(e, row)}
            className={classes.input}
            key={row.id}
          />
        ) : (
          <p style={{ width: "60px", fontSize: "15px" }}>{row.amount}</p>
        )}
      </TableCell>
      <TableCell className={classes.cell1} style={{ fontSize: "30px" }}>
        <p style={{ width: "60px", fontSize: "15px" }}>{row.calories}</p>
      </TableCell>
      <TableCell className={classes.cell1}>
        <p style={{ width: "60px", fontSize: "15px" }}>{row.carbohydrate_g}</p>
      </TableCell>
      <TableCell className={classes.cell1}>
        <p style={{ width: "60px", fontSize: "15px" }}>{row.protein_g}</p>
      </TableCell>
      <TableCell className={classes.cell1}>
        <p style={{ width: "60px", fontSize: "15px" }}>{row.fat_g}</p>
      </TableCell>
    </TableRow>
  ));

  return cells;
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateMenuList: (menuList) =>
      dispatch({
        type: actionTypes.UPDATE_MENULIST,
        menuList,
      }),

    onUpdateAmountList: (amountList) =>
      dispatch({
        type: actionTypes.UPDATE_AMOUNT_LIST,
        amountList,
      }),

    onUpdateTotalNutrition: (totalNutrition) =>
      dispatch({
        type: actionTypes.UPDATE_TOTAL_NUTRITION,
        totalNutrition: totalNutrition,
      }),
  };
};

const Button = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  right: 10px;
  font-size: 20px;
`;

const Modal = styled.div`
  width: 550px;
  position: fixed;
  top: 35%;
  left: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${Colors.gray_1};
  background-color: white;
  border-radius: 10px;
`;

const CompTable = styled.div`
  padding: 20px;
  border: 1px solid ${Colors.gray_1};
  border-radius: 10px;
`;

export default connect("", mapDispatchToProps)(FoodItem);
