import { apiServer } from "config";

const env = process.env.REACT_APP_NODE_ENV
  ? process.env.REACT_APP_NODE_ENV
  : process.env.NODE_ENV;

export let url;

switch (env) {
  case "production":
    url = apiServer.production;
    break;
  case "staged":
    url = apiServer.staged;
    break;
  default:
    url = apiServer.development;
}

export const nuviSortFunction = (a, b) => {
  /*
  1순위: 식약처
  2순위: 농촌진흥청
  3순위: 기관 가나다순
  순위 같을 경우 음식명 가나다순
  */
  const getSourceIndex = source => {
    if (source === "식약처") {
      return 1;
    }
    if (source === "농촌진흥청") {
      return 2;
    } else {
      return 3;
    }
  };
  // 소스 식약처>농촌진흥청>기타 순 정렬. 동일한 경우 음식이름 가나다순 정렬.
  let aIndex = getSourceIndex(a.source);
  let bIndex = getSourceIndex(b.source);

  if (aIndex === bIndex) {
    if (a.source === b.source) {
      return a.foodName < b.foodName ? -1 : a.foodName > b.foodName ? 1 : 0;
    } else {
      return a.source < b.source ? -1 : a.source > b.source ? 1 : 0;
    }
  } else {
    return aIndex - bIndex;
  }
};

export const customSortFunction = (a, b, departmentId) => {
  /*
  1. 내 기관과 음식 기관이 동일한 음식
  2. 기관이 동일한 경우 최근 날짜 우선
  */
   departmentId = Number(departmentId);
  // 내 기관과 음식 기관이 동일한 음식 우선 소팅
  if (
    a.User.departmentId === departmentId &&
    b.User.departmentId !== departmentId
  ) {
    return -1;
  } else if (
    a.User.departmentId !== departmentId &&
    b.User.departmentId === departmentId
  ) {
    return 1;
  }

  /* a, b기관 동일한 경우 최근 날짜 우선으로 소팅 */
  if (a.User.departmentId === b.User.departmentId) {
    if (!a.recentMenuInfo && !b.recentMenuInfo) {
      return a.foodName < b.foodName ? -1 : a.foodName > b.foodName ? 1 : 0;
    }
    if (a.recentMenuInfo && b.recentMenuInfo) {
      if (a.recentMenuInfo.menuDate === b.recentMenuInfo.menuDate) {
        return a.foodName < b.foodName ? -1 : a.foodName > b.foodName ? 1 : 0;
      } else {
        return (
          new Date(b.recentMenuInfo.menuDate) -
          new Date(a.recentMenuInfo.menuDate)
        );
      }
    }
    // 메뉴정보 둘 중 하나만 있는 경우
    return a.recentMenuInfo ? -1 : 1;
  } else {
    // 기관 동일하지 않을 경우
    return a.User.departmentId - b.User.departmentId;
  }
};