import * as sagas from "./sagas";
import * as AT from "data/rootActionTypes";
import { takeEvery } from "redux-saga/effects";

export default function*() {
  yield takeEvery(AT.TEST, sagas.test);
  yield takeEvery(AT.GET_USER_LIST, sagas.getUserList);
  yield takeEvery(AT.UPDATE_POINT, sagas.updatePoint);
  yield takeEvery(AT.GET_INDIVIDUAL_POINT, sagas.getIndividualPoint);
  yield takeEvery(AT.GET_SK_USER_PARTICIPATION, sagas.getSKUserParticipation);
};
