import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { actions } from "data";
import { useDepartmentList } from "hooks";

import styled from "styled-components";
import ReactPaginate from "react-paginate";

import { apiClient } from "api/client";

import { nuviSortFunction, customSortFunction } from "pages/MasterPages/helper";

export default function ResultBox ({
  searchMode,
  customSearchResult,
  nuviSearchResult,
  handleNuviSearchResult,
  handleCustomSearchResult,
  handleModalOpen,
}) {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const departmentList = useDepartmentList();
  const { departmentId, date, bld } = values;

  const handleDepartmentLabel = (departmentId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === Number(departmentId)) {
        return departmentList[i].name;
      }
    }
  };

  const addToCustomFoodList = (customFood) => {
    const index = values.customFoodList.indexOf(customFood);
    if (index === -1) {
      setFieldValue("customFoodList", [...values.customFoodList, customFood]);
    } else {
      alert("이미 추가된 음식입니다");
    }
  };

  const makeNewCustomFood = async (newCustomFood) => {
    try {
      const { id, foodName } = newCustomFood;
      const data = {
        foodName,
        nuviFoodId: id,
        nuviUserId: 1,
        departmentId,
      };
      const res = await apiClient.post(`/api/food/custom-food`, data);
      if (res.data.alreadyExists) {
        alert(res.data.message);
      } else {
        const createdCustomFood = res.data;
        await addToCustomFoodList(createdCustomFood);
        await dispatch(
          actions.modal.modalUpAndGo({
            contents: `${foodName}이/가 커스텀푸드에 추가되었습니다`,
          })
        );
      }
    } catch (e) {
      dispatch(actions.modal.modalUpAndGo({ contents: e.message }));
    }
  };

  const setNewCustomFoodName = (nuviFood) => {
    dispatch(
      actions.modal.setModal({
        modalType: "MAKE_CUSTOM_FOOD",
        modalProps: {
          nuviFood,
          onConfirm: makeNewCustomFood,
        },
      })
    );
  };

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const nuviPageCount = Math.ceil(nuviSearchResult.length / PER_PAGE);
  const customPageCount = Math.ceil(customSearchResult.length / PER_PAGE);

  const currentPageNuviSearchResult = nuviSearchResult
    .sort(nuviSortFunction)
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer key={idx} onClick={() => setNewCustomFoodName(el)}>
        <ResultRow>{el.source}</ResultRow>
        <ResultRow>{el.foodName}</ResultRow>
        <ResultRow>{el.servingAmount}</ResultRow>
        <ResultRow>{el.calories}</ResultRow>
      </ResultRowContainer>
    ));

  const currentPageCustomSearchResult = customSearchResult
    // .sort((a, b) => customSortFunction(a, b, departmentId))
    .slice(offset, offset + PER_PAGE)
    .map((el, idx) => (
      <ResultRowContainer key={idx} onClick={() => addToCustomFoodList(el)}>
        <ResultRow>
          {el.recentMenuInfo ? el.recentMenuInfo.menuDate : "정보없음"}
        </ResultRow>
        <ResultRow>
          {el.recentMenuInfo ? el.recentMenuInfo.bld : "정보없음"}
        </ResultRow>
        <ResultRow>{el.foodName}</ResultRow>
        <ResultRow>{el.id}</ResultRow>
        <ResultRow>{el.ratio}</ResultRow>
        <ResultRow>{(el.calories * el.ratio).toFixed(2)}</ResultRow>
      </ResultRowContainer>
    ));

  useEffect(() => {
    handleNuviSearchResult([]);
    handleCustomSearchResult([]);
  }, [departmentId, date, bld]);

  return (
    <ResultContainer>
      <Title>검색 결과</Title>
      {searchMode === "nuviFood" && (
        <StyledButton type="button" onClick={handleModalOpen}>
          누비푸드 추가하기
        </StyledButton>
      )}

      {searchMode === "customFood" && customSearchResult[0] && (
        <>
          <ResultRowNameContainer>
            <ResultRow width="100px">날짜</ResultRow>
            <ResultRow width="100px">BLD</ResultRow>
            <ResultRow width="100px">고객음식이름</ResultRow>
            <ResultRow width="100px">누비푸드아이디</ResultRow>
            <ResultRow width="100px">비율</ResultRow>
            <ResultRow width="100px">칼로리(kcal) </ResultRow>
          </ResultRowNameContainer>
          {currentPageCustomSearchResult}

          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={customPageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      )}
      {searchMode === "nuviFood" && nuviSearchResult[0] && (
        <>
          <ResultRowNameContainer>
            <ResultRow>출처</ResultRow>
            <ResultRow>누비푸드이름</ResultRow>
            <ResultRow>제공량(g)</ResultRow>
            <ResultRow>칼로리(kcal)</ResultRow>
          </ResultRowNameContainer>
          {currentPageNuviSearchResult}
          <ReactPaginate
            previousLabel={"← Previous"}
            nextLabel={"Next →"}
            pageCount={nuviPageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      )}
    </ResultContainer>
  );
};

const ResultContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: none;
`;

const ResultRowContainer = styled.div`
  width: 95%;
  background-color: none;

  display: flex;
  justify-content: space-around;
  padding: 2px;
  marign: 3px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 3px;
`;

const Title = styled.div`
  width: 100%;
  background-color: none;
  font-size: 30px;
  font-weight: 300;
  padding: 10px;
`;

const StyledButton = styled.button`
  width: 150px;
  height: 35px;
  margin: 15px;
  border: 1px solid black;

  align-self: flex-end;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;
