import React, { useState, useEffect } from "react";

import { apiClient } from "api/client";

import styled, { keyframes } from "styled-components";

import { useFormikContext } from "formik";

import { MenuBox, MenuTitle } from "pages/MasterPages/StyledComps";

const SamplePlateImg = null;
const defaultTrayImage = { filename: "empty", imageSrc: SamplePlateImg };

const PlateBox = ({ menuInfo }) => {
  const { values } = useFormikContext();
  const { departmentId, date, bld } = values;

  const [trayImageArray, setTrayImageArray] = useState([]);
  const [trayImage, setTrayImage] = useState(defaultTrayImage);
  const [trayIndex, setTrayIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textToCopy, setTextToCopy] = useState("copy me");

  const getTrayImages = async () => {
    setIsLoading(true);
    const res = await apiClient.get(`/api/menu/tray-images`, {
      params: {
        departmentId,
        date,
        bld,
      },
    });
    if (typeof res.data === "object" && res.data.trayImages[0]) {
      setTrayImageArray(res.data.trayImages);
      setTrayImage(res.data.trayImages[trayIndex]);
    } else {
      setTrayImageArray([]);
      setTrayImage(defaultTrayImage);
    }
    setTrayIndex(0);
    setIsLoading(false);
  };

  const copyText = () => {
    // if (trayImage.filename) {
    //   navigator.clipboard.writeText(trayImage.filename);
    // }
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const handleNextImage = () => {
    if (trayIndex < trayImageArray.length - 1) {
      setTrayIndex(trayIndex + 1);
    }
  };

  const handlePreviousImage = () => {
    if (trayIndex > 0) {
      setTrayIndex(trayIndex - 1);
    }
  };

  const handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === "ArrowRight") {
      e.preventDefault();
      handleNextImage();
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      handlePreviousImage();
    } else if (e.key === "Escape" && modalOpen) {
      handleModalOpen();
    } else if (e.key === "Enter" && !modalOpen && trayImageArray[0]) {
      handleModalOpen();
    } else {
      return;
    }
  };

  useEffect(() => {
    if (departmentId !== "" && bld !== "") {
      getTrayImages();
    }
  }, [departmentId, bld, date]);

  useEffect(() => {
    if (trayImageArray[0]) {
      setTrayImage(trayImageArray[trayIndex]);
    }
  }, [trayIndex]);

  document.onkeydown = handleKeyPress;

  return (
    <MenuBox flex={1.5}>
      <MenuTitle>{`메뉴 이미지 (${trayIndex + 1}/${
        trayImageArray.length
      })`}</MenuTitle>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <ContentContainer>
          <ImageContainer>
            <StyledImage src={trayImage.imageSrc} onClick={handleModalOpen} />
          </ImageContainer>
          <ButtonContainer>
            <Button onClick={handlePreviousImage}>{"<"}</Button>
            <Button onClick={handleNextImage}>{">"}</Button>
          </ButtonContainer>
        </ContentContainer>
      )}

      <ModalWrapper visible={modalOpen}>
        <ModalInner handleModalOpen={handleModalOpen}>
          <ImageInfoContainer style={{ justifyContent: "flex-start" }}>
            <h2 style={{ marginLeft: "20px" }}>
              {`${trayIndex + 1}/${trayImageArray.length}`}
            </h2>
          </ImageInfoContainer>
          <ImageContainer>
            <Button onClick={handlePreviousImage}>{"<"}</Button>
            <StyledImage
              src={trayImage.imageSrc}
              onClick={handleModalOpen}
              width="1200px"
            />
            <Button onClick={handleNextImage}>{">"}</Button>
          </ImageContainer>
          <ImageInfoContainer>
            <FileText onClick={copyText}>
              {trayImage ? trayImage.filename : "noname"}
            </FileText>
          </ImageInfoContainer>
        </ModalInner>
      </ModalWrapper>
    </MenuBox>
  );
};

function LoadingDots() {
  return (
    <Container>
      <DotWrapper>
        <Dot delay="0s" />
        <Dot delay=".1s" />
        <Dot delay=".2s" />
      </DotWrapper>
    </Container>
  );
}

export default PlateBox;

const FileText = styled.h2``;

const ContentContainer = styled.div`
  width: 100%;
  border: 1px solid none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  width: 300px;
  border: 1px solid none;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Button = styled.button`
  width: 100px;
  height: 50px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  font-size: 40px;
  border: 1px solid none;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  min-height: 300px;
  width: 100%;
`;

const ImageInfoContainer = styled(ImageContainer)`
  justify-content: space-around;
  min-height: 0px;
`;

const StyledImage = styled.img`
  border-radius: 10px;
  cursor: pointer;
  width: ${({ width }) => (width ? width : "80%")};
`;

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 1400px;
  height: 900px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  background-color: white;
  border: 3px solid black;
  font-size: 0.8rem;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
`;

const Container = styled.div`
  height: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

const Dot = styled.div`
  background-color: blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;
