import React from "react";
import { useFormikContext } from "formik";

import styled from "styled-components";
import { MiniDashContainer } from "pages/MasterPages/StyledComps";

import Emoji from "components/Basics/Emoji";

const bldLabel = {
  B: "조식",
  L: "중식",
  D: "석식",
};

export default function MiniDashBox({ departmentList }) {
  const { values, setFieldValue } = useFormikContext();
  const { departmentId, bld, date, customFoodList } = values;

  const handleDepartmentLabel = (departmentId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === Number(departmentId)) {
        return departmentList[i].name;
      }
    }
  };

  const removeFromCustomFoodList = (customFood) => {
    const index = customFoodList.indexOf(customFood);
    let updatedCustomFoodList = customFoodList.slice();
    updatedCustomFoodList.splice(index, 1);
    setFieldValue("customFoodList", updatedCustomFoodList);
  };

  const totalCalories = customFoodList
    .reduce((a, c) => a + c.calories * c.ratio, 0)
    .toFixed(0);

  const totalServingAmount = customFoodList
    .reduce((a, c) => a + c.servingAmount * c.ratio, 0)
    .toFixed(0);


  return (
    <MiniDashContainer>
      <Title>
        추가될 메뉴 정보<SubTitle>추가될 메뉴 정보를 확인하세요</SubTitle>
      </Title>

      <Text>
        {handleDepartmentLabel(departmentId)} {date} {bldLabel[bld]}  메뉴
      </Text>
      <ResultContainer>
        <UpperContainer>
          <ResultRowNameContainer>
            <ResultRow>고객음식이름</ResultRow>
            <ResultRow>칼로리(kcal)</ResultRow>
            <ResultRow>제공량(g)</ResultRow>
            <ResultRow>비율</ResultRow>
            <ResultRow>{""}</ResultRow>
          </ResultRowNameContainer>

          {customFoodList.map((el, idx) => (
            <ResultRowContainer key={idx}>
              <ResultRow>{el.foodName}</ResultRow>
              <ResultRow>{(el.calories * el.ratio).toFixed(2)}</ResultRow>
              <ResultRow>{(el.servingAmount * el.ratio).toFixed(2)}</ResultRow>
              <ResultRow>{el.ratio}</ResultRow>
              <ResultRow
                onClick={() => removeFromCustomFoodList(el)}
                style={{ cursor: "pointer" }}
              >
                <Emoji symbol="🗑️" label="bin" />
              </ResultRow>
            </ResultRowContainer>
          ))}
        </UpperContainer>

        {customFoodList[0] && (
          <>
            <BottomContainer>
              <Text>총 칼로리: {totalCalories}kcal</Text>
              <Text>총 제공량: {totalServingAmount}g</Text>
            </BottomContainer>
            <StyledButton type="submit">메뉴만들기</StyledButton>
          </>
        )}
      </ResultContainer>
    </MiniDashContainer>
  );
}

const ResultContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ResultRowContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 2px;
  margin: 3px;
  border: 1px solid black;
  border-radius: 5px;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  width: 100%;
  cursor: default;
  background-color: #f0f0f0;
  &:hover {
    background-color: #f0f0f0;
  }
  &:active {
    background-color: #f0f0f0;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 3px;
`;

const Title = styled.div`
  width: 100%;
  background-color: none;
  font-size: 30px;
  font-weight: 300;
  padding: 10px;
`;

const SubTitle = styled.span`
  width: 100%;
  background-color: none;
  font-size: 25px;
  font-weight: 300;
  padding: 10px;
  color: green;
`;

const Text = styled.div`
  width: 100%;
  background-color: none;
  font-size: 35px;
  text-align: center;
  padding: 10px;
  padding-bottom: 0px;
`;

const StyledButton = styled.button`
  width: 100%;
  margin-top: 20px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid black;
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;

const UpperContainer = styled.div`
  width: 100%;
  min-height: 50vh;
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
