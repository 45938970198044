import { apiClient } from "../client";

export const api = {
  getDepartmentList: async () => {
    return await apiClient.get("/api/department/list").catch((error) => {
      console.log("error.message", error.message);
      throw new Error(error.message);
    });
  },
  getCornerList: async (params) => {
    return await apiClient
      .get("/api/department/corner-list", {
        params,
      })
      .catch((error) => {
        console.log("error.message", error.message);
        throw new Error(error.message);
      });
  },
};
