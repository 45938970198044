import React from "react";
import styled from "styled-components";

import ReactExport from "react-export-excel";
import moment from "moment";

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

export default function ExcelExportButton({ data }) {
  const excelData = [data];
  const { menuDate, departmentName, bld } = data;
  const today = moment(new Date()).format("YYYY_MMDD_HHMM");

  return (
    <ExcelFile
      filename={`${menuDate}_${departmentName}_${bld}_영양소 정보_downloaded_at_${today}`}
      element={<CsvExportButton>영양정보 다운로드</CsvExportButton>}
    >
      <ExcelSheet data={excelData} name="menuinfo">
        <ExcelColumn value="id" label="메뉴아이디" />
        <ExcelColumn value="menuDate" label="일자" />
        <ExcelColumn value="bld" label="BLD" />
        <ExcelColumn value="departmentName" label="기관명" />
        <ExcelColumn value="calories" label="칼로리(kcal)" />
        <ExcelColumn value="servingAmount" label="제공량(g)" />
        <ExcelColumn value="carbohydrate_g" label="탄수화물(g)" />
        <ExcelColumn value="protein_g" label="단백질(g)" />
        <ExcelColumn value="fat_g" label="지방(g)" />
        <ExcelColumn value="vitamin_a_ug_re" label="비타민 A(ug re)" />
        <ExcelColumn value="vitamin_b1_mg" label="비타민 B1(mg)" />
        <ExcelColumn value="vitamin_b2_mg" label="비타민 B2(mg)" />
        <ExcelColumn value="vitamin_c_mg" label="비타민 C(mg)" />
        <ExcelColumn value="sodium_mg" label="나트륨(mg)" />
        <ExcelColumn value="calcium_mg" label="칼슘(mg)" />
        <ExcelColumn value="iron_mg" label="철분(mg)" />
      </ExcelSheet>
    </ExcelFile>
  );
}

const CsvExportButton = styled.div`
  cursor: pointer;
  margin: 3px;
  padding: 12px;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.gray_2};
  color: black;
  text-decoration: none;
  float: right;
`;
