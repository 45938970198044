import * as actionTypes from "../actionTypes";

const initialState = {
  menuList: [],
  amountList: [],
  selectState: {},
  selectedDay: 0,
  selectedDays: { L: [], D: [] },
  selectedLD: "",
  allSelected: false,
  displayingDays: [],
  resetSelected: false,
  modifyState: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_DAY:
      return {
        ...state,
        selectedDay: action.selectedDay,
      };

    case actionTypes.RESET_SELECTED_ON:
      return {
        ...state,
        resetSelected: true,
        displayingDays: [],
      };

    case actionTypes.RESET_SELECTED_OFF:
      return {
        ...state,
        selectedDays: { L: [], D: [] },
        allSelected: false,
        resetSelected: false,
      };

    case actionTypes.UPDATE_ALL_SELECTED:
      return {
        ...state,
        allSelected: !state.allSelected,
      };

    case actionTypes.UPDATE_MODIFY_STATE:
      return {
        ...state,
        modifyState: !state.modifyState,
      };

    case actionTypes.UPDATE_SELECTED_LD:
      return {
        ...state,
        selectedLD: action.selectedLD,
      };

    case actionTypes.RESET_DISPLAYING_DAYS:
      return {
        ...state,
        displayingDays: [],
      };

    case actionTypes.UPDATE_DISPLAYING_DAYS:
      if (!state.displayingDays.includes(action.day)) {
        return {
          ...state,
          displayingDays: [...state.displayingDays, action.day],
        };
      } else {
        return state;
      }

    case actionTypes.UPDATE_SELECT_STATE:
      const Lunch = [];
      const Dinner = [];

      for (var key in action.selectState) {
        if (action.selectState[key] === false) {
          continue;
        }
        if (key[0] === "L") {
          Lunch.push(key.slice(1));
        } else if (key[0] === "D") {
          Dinner.push(key.slice(1));
        }
      }

      return {
        ...state,
        selectState: action.selectState,
        selectedDays: { L: Lunch, D: Dinner },
      };

    case actionTypes.UPDATE_MENULIST:
      return {
        ...state,
        menuList: action.menuList,
      };

    case actionTypes.UPDATE_AMOUNT_LIST:
      return {
        ...state,
        amountList: action.amountList,
      };

    case actionTypes.UPDATE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};
