import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import * as actionTypes from "data/recommendation/actionTypes";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const StyledBox = styled.div`
  background: #ffffff;
  color: #white;
  font-size: 2rem;
  // font-family: "NanumGothicBold", cursive;
  margin: 0 auto;
  margin-top: 0rem;
  text-align: left bottom;
  vertical-align: bottom;
  z-index: 100;
  border: 1px black;
`;

const SelectBox = (props) => {
  const classes = useStyles();
  const [week, setWeek] = React.useState("");

  useEffect(() => {
    if (props.week === 1) {
      setWeek(1);
    }
  }, [props.week]);

  const handleChange = (event) => {
    if (props.highlightState) {
      props.onUpdateHighlightState();
    }

    setWeek(event.target.value);
    if (event.target.value === "") {
      setWeek("");
      props.onUpdateWeek(0);
    } else if (event.target.value === 1) {
      setWeek(1);
      props.onUpdateWeek(1);
    } else if (event.target.value === 2) {
      setWeek(2);
      props.onUpdateWeek(2);
    } else if (event.target.value === 3) {
      setWeek(3);
      props.onUpdateWeek(3);
    } else if (event.target.value === 4) {
      setWeek(4);
      props.onUpdateWeek(4);
    }
    props.onResetSelectedOn();
  };

  return (
    <StyledBox>
      <div
        style={{
          color: "black",
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <FormControl className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-label"
              style={{
                textAlign: "center",
              }}
            ></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={week}
              onChange={handleChange}
              displayEmpty
              disableUnderline
              style={{
                fontSize: "2rem",
                marginTop: "-2px",
              }}
            >
              <MenuItem value={""}>1주차</MenuItem>
              <MenuItem value={1}>2주차</MenuItem>
              <MenuItem value={2}>3주차</MenuItem>
              <MenuItem value={3}>4주차</MenuItem>
              <MenuItem value={4}>5주차</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </StyledBox>
  );
};

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    highlightState: state.recommend.highlight.highlightState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateWeek: (week) =>
      dispatch({
        type: actionTypes.UPDATE_WEEK,
        week: week,
      }),
    onResetSelectedOn: () =>
      dispatch({
        type: actionTypes.RESET_SELECTED_ON,
      }),
    onUpdateHighlightState: () =>
      dispatch({
        type: actionTypes.UPDATE_HIGHLIGHT_STATE,
      }),
    //RESET_SELECTED_ON
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectBox);
