import produce from "immer";

import * as AT from "data/rootActionTypes";

const Initial_State = {
  [AT.TEST]: null,
  [AT.MENU_LIST]: null,
  [AT.MENU_IMAGE_SRC]: null, // 식판 사진 url db에서 긁어오기
  [AT.MENU_PREVIEW_SRC]: null, // 식판 미리보기 이미지
};

const dashboard = produce((draft, action) => {
  switch (action.type) {
    case AT.TEST:
      draft[AT.TEST] = action.payload;
      break;
    case AT.MENU_LIST:
      draft[AT.MENU_LIST] = action.payload;
      break;
    case AT.MENU_IMAGE_SRC:
      draft[AT.MENU_IMAGE_SRC] = action.payload;
      break;
    case AT.MENU_PREVIEW_SRC:
      draft[AT.MENU_PREVIEW_SRC] = action.payload;
      break;
    default:
      return;
  }
}, Initial_State);

export default dashboard;
