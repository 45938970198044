import React, { useState } from "react";

import styled from "styled-components";

import UpdatePoint from "./UpdatePoint";
import ReadPoint from "./ReadPoint";

const types = ["read", "update"];

export default function Point() {
  const [activeTab, setActiveTab] = useState(types[0]);

  const handleActiveTab = () => {
    switch (activeTab) {
      case "read":
        setActiveTab("update");
        break;
      default:
        setActiveTab("read");
        return;
    }
  };

  return (
    <Container>
      {activeTab === "read" ? (
        <ReadPoint tabHandler={handleActiveTab} />
      ) : (
        <UpdatePoint tabHandler={handleActiveTab} />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 5%;
`;
