import React from "react";
import { withRouter } from "react-router-dom";

import InfoBox from "./InfoBox";
import Buttons from "./Buttons/index.js";
import GetTable from "./GetTable";

import classes from "./index.module.css";

const Recommend = () => {
  return (
    <div className={classes.MenuTable}>
      <InfoBox />
      <Buttons />
      <GetTable />
    </div>
  );
};

export default withRouter(Recommend);
