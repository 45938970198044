import React, { useMemo, useCallback, useReducer, useEffect } from "react";

import { apiClient } from "api/client";

import styled from "styled-components";

import {
  useDepartmentList,
  useNuviFoodMainCategories,
  useNuviFoodSubCategories,
  useFoodGroups,
} from "hooks";

import { Modal } from "@material-ui/core";

export default function NuviFoodEditModal(props) {
  const departmentList = useDepartmentList();

  const nuviFoodMainCategories = useNuviFoodMainCategories();
  const nuviFoodSubCategories = useNuviFoodSubCategories();
  const foodGroups = useFoodGroups();

  const { selectedNuviFood, onClose } = props;
  const {
    id: nuviFoodId,
    foodName,
    source,
    foodGroupId: foodGroupIdDefault,
    nuviFoodMainCategoryId: nuviFoodMainCategoryIdDefault,
    nuviFoodSubCategoryId: nuviFoodSubCategoryIdDefault,
    departmentId,
    date,
    bld,
    calories,
    carbohydrate_g,
    fat_g,
    protein_g,
    calcium_mg,
    iron_mg,
    servingAmount,
    sodium_mg,
    vitamin_a_ug_re,
    vitamin_b1_mg,
    vitamin_b2_mg,
    vitamin_c_mg,
  } = selectedNuviFood;

  // form
  const initialFormState = {
    nuviFoodId,
    foodGroupId: foodGroupIdDefault,
    nuviFoodMainCategoryId: nuviFoodMainCategoryIdDefault,
    nuviFoodSubCategoryId: nuviFoodSubCategoryIdDefault,
    calories,
    carbohydrate_g,
    fat_g,
    protein_g,
    calcium_mg,
    iron_mg,
    servingAmount,
    sodium_mg,
    vitamin_a_ug_re,
    vitamin_b1_mg,
    vitamin_b2_mg,
    vitamin_c_mg,
  };

  const [formState, setFormValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialFormState
  );

  const handleFormChange = (e, key) => {
    const { value } = e.target;
    console.log("## this is value: ", value);
    setFormValues({
      [key]: value,
    });
  };

  const resetFormValues = () => {
    setFormValues(initialFormState);
  };

  const handleClose = () => {
    resetFormValues();
    onClose();
  };

  console.log("## formstate: ", formState);

  // dept label
  const handleDepartmentName = useCallback((depId) => {
    for (let i = 0; i < departmentList.length; i++) {
      if (departmentList[i].id === depId) {
        return departmentList[i].name;
      }
    }
    return null;
  }, []);

  const departmentName = useMemo(() => handleDepartmentName(departmentId), [
    departmentId,
    handleDepartmentName,
  ]);

  // delete food
  const deleteNuviFood = async () => {
    const nuviFoodId = selectedNuviFood.id;
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      const res = await apiClient.delete(`/api/food/nuvi-food/${nuviFoodId}`);
      const result = res.data.success;
      if (result) {
        alert(`${selectedNuviFood.foodName}이/가 삭제되었습니다`);
        onClose();
      }
    } else {
      console.log("cancel");
    }
  };

  // update food
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (window.confirm("수정하시겠습니까?")) {
      const res = await apiClient.patch(`/api/food/nuvi-food`, formState);
      const { success } = res.data;
      if (success) {
        alert("수정되었습니다");
        resetFormValues();
        onClose();
      }
    } else {
      console.log("cancel");
    }
  };

  return (
    <Modal {...props}>
      <ModalContainer>
        <PaddingContainer>
          <StyledForm onSubmit={handleSubmit}>
            <ContentContainer
              style={{
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <FoodName>{foodName}</FoodName>
              <CloseButton onClick={handleClose}>X</CloseButton>
            </ContentContainer>
            <ContentContainer>
              <div>출처: {source}</div>
              <div>기관명: {departmentName}</div>
              <div>일자: {date}</div>
              <div>BLD: {bld}</div>
            </ContentContainer>

            <RowContainer>
              {/* <MidText>영양정보</MidText> */}
              <ContentContainer style={{ border: "none" }}>
                <div>열량: {calories}kcal</div>
                <div>제공량: {servingAmount}g</div>
                <div>탄수화물: {carbohydrate_g}g</div>
                <div>지방: {fat_g}g</div>
                <div>단백질: {protein_g}g</div>
                <div>칼슘: {calcium_mg}mg</div>
                <div>철분: {iron_mg}mg</div>
                <div>나트륨: {sodium_mg}mg</div>
                <div>비타민 A: {vitamin_a_ug_re}ug re</div>
                <div>비타민 B1: {vitamin_b1_mg}mg</div>
                <div>비타민 B2{vitamin_b2_mg}mg</div>
                <div>비타민 C: {vitamin_c_mg}mg</div>
                <NutrientInput
                  name="calories"
                  defaultValue={calories}
                  handleFormChange={handleFormChange}
                />
              </ContentContainer>

              <MidText>분류</MidText>
              <ContentContainer style={{ border: "none" }}>
                <div>음식 대분류</div>
                <StyledSelect
                  defaultValue={nuviFoodMainCategoryIdDefault}
                  onChange={(e) =>
                    handleFormChange(e, "nuviFoodMainCategoryId")
                  }
                >
                  <option value="">없음</option>
                  {nuviFoodMainCategories.map(({ id, categoryName }) => (
                    <option key={id} value={id}>
                      {categoryName}
                    </option>
                  ))}
                </StyledSelect>

                <div>음식 중분류</div>
                <StyledSelect
                  defaultValue={nuviFoodSubCategoryIdDefault}
                  onChange={(e) => handleFormChange(e, "nuviFoodSubCategoryId")}
                >
                  <option value="">없음</option>

                  {nuviFoodSubCategories
                    .filter(
                      // 대분류에 속하는 중분류만 표출
                      (subCategory) =>
                        subCategory.nuviFoodMainCategoryId ===
                        Number(formState.nuviFoodMainCategoryId)
                    )
                    .map((el, idx) => (
                      <option key={idx} value={el.id}>
                        {el.categoryName}
                      </option>
                    ))}
                </StyledSelect>

                <div>식품군</div>
                <StyledSelect
                  defaultValue={foodGroupIdDefault}
                  onChange={(e) => handleFormChange(e, "foodGroupId")}
                >
                  <option value="">없음</option>
                  {foodGroups.map(({ id, groupName }) => (
                    <option key={id} value={id}>
                      {groupName}
                    </option>
                  ))}
                </StyledSelect>
              </ContentContainer>
            </RowContainer>
            <ButtonContainer>
              <DeleteButton type="button" onClick={deleteNuviFood}>
                삭제
              </DeleteButton>
              <EditButton type="submit">수정</EditButton>
            </ButtonContainer>
          </StyledForm>
        </PaddingContainer>
      </ModalContainer>
    </Modal>
  );
}

const NUTRIENT_INFO = {
  calories: {
    label: "열량",
    unit: "kcal",
  },
  amount: {
    label: "제공량",
    unit: "g",
  },
  carbohydrate_g: {
    label: "탄수화물",
    unit: "g",
  },
  fat_g: {
    label: "지방",
    unit: "g",
  },
  protein_g: {
    label: "단백질",
    unit: "g",
  },
  calcium_mg: {
    label: "칼슘",
    unit: "mg",
  },
  iron_mg: {
    label: "철분",
    unit: "mg",
  },
  sodium_mg: {
    label: "나트륨",
    unit: "mg",
  },
  vitamin_a_ug_re: {
    label: "비타민 A",
    unit: "ug re",
  },
  vitamin_b1_mg: {
    label: "비타민 B1",
    unit: "mg",
  },
  vitamin_b2_mg: {
    label: "비타민 B2",
    unit: "kcal",
  },
  vitamin_c_mg: {
    label: "비타민 C",
    unit: "mg",
  },
};

function NutrientInput({ name, defaultValue, handleFormChange }) {
  const label = useMemo(() => NUTRIENT_INFO[name].label, [name]);
  const unit = useMemo(() => NUTRIENT_INFO[name].unit, [name]);

  return (
    <NutrientInputContainer>
      <InputLabel>{label}</InputLabel>
      <StyledNumberInput
        defaultValue={defaultValue}
        type="number"
        step="any"
        onChange={(e) => handleFormChange(e, name)}
      />
      <Unit>{unit}</Unit>
    </NutrientInputContainer>
  );
}

// nutrient input
const NutrientInputContainer = styled.div`
  width: 100%;
  border: 1px solid red;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const InputLabel = styled.div`
  width: 120px;
  height: 30px;
  border: 1px solid green;
  display: flex;
  align-items: center;
`;

const StyledNumberInput = styled.input`
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
`;

const Unit = styled.div`
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
`;

// modal
const ModalContainer = styled.div`
  position: absolute;
  width: 1000px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: default;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 3.5);
`;

const PaddingContainer = styled.div`
  width: 95%;
  height: 95%;
`;

const RowContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid gray;
  display: flex;
  flex-direction: row;
  flex: 3;
`;

const ContentContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
`;

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  align-items: stretch;
  justify-content: stretch;
`;

const StyledSelect = styled.select`
  width: 120px;
  height: 50px;
`;

const ButtonContainer = styled(ContentContainer)`
  display: flex;
  justify-content: space-between;
  border: none;
`;

const EditButton = styled.button`
  width: 220px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  width: 120px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid black;
  cursor: pointer;
  background-color: red;
  opacity: 50%;
`;

const CloseButton = styled.div`
  width: 40px;
  text-align: end;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
`;

//text
const FoodName = styled.div`
  font-size: 40px;
  font-weight: bold;
`;

const MidText = styled.div`
  min-width: 100px;
  font-size: 20px;
`;
