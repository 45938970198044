import React, { useState, useEffect } from "react";
import styled from "styled-components";

import DatePicker from "components/Input/DatePicker";
import GeneralDropDownFilter from "./GeneralDropDownFilter";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions, selectors } from "data";

export default function SearchBox({
  date,
  bld,
  setItemCalories,
  handleDate,
  handleBld,
  handleEditMode,
  handleFakeView,
  handleSodium,
  isInEditMode,
  isInFakeView,
  showSodium,
}) {
  const dispatch = useDispatch();

  const history = useHistory();
  const menuList = useSelector(selectors.dashboard.getMenuList);

  let menuListId = null;
  if (menuList) {
    menuListId = menuList.menuInfo.id;
  }

  const [menuImage, setMenuImage] = useState(null);

  const handleFileInput = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch(actions.dashboard.setMenuPreviewSrc(reader.result));
    };
    reader.readAsDataURL(file);

    await setMenuImage(e.target.files[0]);
  };

  const handlePost = async () => {
    const formData = await new FormData();
    await formData.append("file", menuImage);
    await dispatch(actions.dashboard.setMenuImage({ menuListId, formData }));
    await dispatch(actions.dashboard.setMenuPreviewSrc(null));
  };

  const deleteImage = async () => {
    dispatch(
      actions.modal.setModal({
        modalType: "CONDITIONAL",
        modalProps: {
          contents: `사진을 삭제하시겠습니까?`,
          onClick: async () => {
            await dispatch(actions.dashboard.deleteMenuImage({ menuListId }));
            await dispatch(actions.modal.clearModal());
            await history.go(0);
          },
          buttonName: "확인",
        },
      })
    );
  };

  return (
    <Container>
      <SubContainer>
        <DatePicker
          InputStyles={{ color: "black" }}
          containerStyles={{ width: "300px", marginRight: "10px" }}
          setValue={(date) => {
            setItemCalories({});
            handleDate(date);
            handleFakeView(false);
          }}
          value={date}
        />
        <GeneralDropDownFilter
          options={[
            { name: "조식", value: "B" },
            { name: "중식", value: "L" },
            { name: "석식", value: "D" },
          ]}
          containerStyles={{ marginRight: "10px" }}
          setValue={(bld) => {
            handleBld(bld);
          }}
          value={bld}
        />

        <EditButton
          onClick={() => {
            handleEditMode(!isInEditMode);
            handleFakeView(true);
          }}
        >
          {isInEditMode ? "완료" : "수정"}
        </EditButton>

        <StyledLabel htmlFor="input-file">사진 선택</StyledLabel>
        <input
          id="input-file"
          type="file"
          name="file"
          onChange={(e) => handleFileInput(e)}
          style={{ display: "none" }}
        />
        <EditButton onClick={handlePost}>사진 저장</EditButton>
        <EditButton onClick={deleteImage}>사진 삭제</EditButton>
        <EditButton onClick={handleSodium}>
          {showSodium ? "나트륨 가리기" : "나트륨 표출"}
        </EditButton>
      </SubContainer>
    </Container>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;

  @media (max-width: 1120px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  display: flex;
  @media (max-width: 1120px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const EditButton = styled.div`
  display: flex;
  justify-content: center;
  width: 150px;
  height: 35px;
  border: 3px solid white;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.blue_3};
  &:hover {
    background-color: lightgray;
  }
  @media (max-width: 1120px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  justify-content: center;
  width: 150px;
  height: 35px;
  border: 3px solid white;
  border-radius: 5px;
  font-size: 24px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.blue_3};
  @media (max-width: 1120px) {
    font-size: 0.8rem;
    width: 100%;
  }
`;
