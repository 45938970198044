import axios from "../../axios";
import * as actionTypes from "../../actionTypes";

const checkIsNew = (
  bld,
  monthData,
  displayingDays,
  Identifier1,
  Identifier2
) => {
  let existDays = [];
  if (bld == "L") {
    if (!(Identifier1 in monthData)) {
      return true;
    }
    existDays = Object.keys(monthData[Identifier1]);
    for (var i = 0; i < displayingDays.length; i++) {
      if (!existDays.includes(String(displayingDays[i]))) {
        return true;
      }
    }
  }

  if (bld == "D") {
    if (!(Identifier2 in monthData)) {
      return true;
    }
    existDays = Object.keys(monthData[Identifier2]);

    for (var i = 0; i < displayingDays.length; i++) {
      if (!existDays.includes(String(displayingDays[i]))) {
        return true;
      }
    }
  }
  if (bld == "LD") {
    if (!(Identifier1 in monthData) || !(Identifier2 in monthData)) {
      return true;
    }
    existDays = Object.keys(monthData[Identifier1]);
    for (var i = 0; i < displayingDays.length; i++) {
      if (!existDays.includes(String(displayingDays[i]))) {
        return true;
      }
    }

    existDays = Object.keys(monthData[Identifier2]);

    for (var i = 0; i < displayingDays.length; i++) {
      if (!existDays.includes(String(displayingDays[i]))) {
        return true;
      }
    }
  }
  console.log("not new");
  return false;
};

export const initUserInputData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = 1;
    const config = state.recommend.user.config;
    const displayingDays = state.recommend.selection.displayingDays;
    const year = state.recommend.calendar.year;
    const month = state.recommend.calendar.month;
    let receivedMonthData = {};
    let receivedAmountData = {};
    await axios
      .get("monthdata/userinput", {
        params: {
          userId,
          year,
          month,
          config,
          bld: "LD",
          displayingDays: JSON.stringify(displayingDays),
        },
      })
      .then((receivedData) => {
        const parsedReceivedData = JSON.parse(receivedData.data);
        receivedMonthData = parsedReceivedData["monthly_json"];
        receivedAmountData = parsedReceivedData["amount_json"];
      })
      .catch((e) => console.log(e));
    dispatch({
      type: actionTypes.UPDATE_USER_INPUT_DATA,
      userInputData: receivedMonthData,
      amountData: receivedAmountData,
    });
  };
};

export const initMonthData = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = 1;
    const config = state.recommend.user.config;
    const bld = state.recommend.calendar.showLD;
    const displayingDays = state.recommend.selection.displayingDays;
    const year = state.recommend.calendar.year;
    const month = state.recommend.calendar.month;
    let monthData = state.recommend.user.monthData;
    let amountData = state.recommend.user.amountData;
    const Identifier1 = `${year}-${month}-L`;
    const Identifier2 = `${year}-${month}-D`;

    if (!checkIsNew(bld, monthData, displayingDays, Identifier1, Identifier2)) {
      return;
    }

    await axios
      .get("monthdata", {
        params: {
          userId,
          year,
          month,
          config,
          displayingDays: JSON.stringify(displayingDays),
          bld: "LD",
        },
      })
      .then((receivedData) => {
        [monthData, amountData] = processReceivedData(
          receivedData,
          monthData,
          amountData
        );
      })
      .catch((e) => console.log(e));
    dispatch({ type: actionTypes.UPDATE_MONTHDATA, monthData });
    dispatch({ type: actionTypes.UPDATE_AMOUNT_DATA, amountData });
    dispatch({
      type: actionTypes.UPDATE_START_DOWNLOAD,
    });
  };
};

export const initConfig = () => {
  return async (dispatch, getState) => {
    const userId = 1;
    const configData = await getConfigData(userId);
    dispatch({ type: actionTypes.UPDATE_CONFIG, config: configData });
  };
};

export const postConfig = (data, userId) => {
  return axios.post("config", { config: data, userId });
};

const processReceivedData = (receivedData, monthData, amountData) => {
  const parsedReceivedData = JSON.parse(receivedData.data);
  const receivedMonthData = parsedReceivedData["monthly_json"];
  const receivedAmountData = parsedReceivedData["amount_json"];
  if (Object.keys(monthData).length == 0) {
    monthData = receivedMonthData;
    amountData = receivedAmountData;
    return [monthData, amountData];
  }
  for (var key in receivedMonthData) {
    if (!monthData[key]) {
      monthData[key] = receivedMonthData[key];
      amountData[key] = receivedAmountData[key];
    } else {
      monthData[key] = {};
      amountData[key] = {};
      monthData[key] = Object.assign(monthData[key], receivedMonthData[key]);
      amountData[key] = Object.assign(amountData[key], receivedAmountData[key]);
    }
  }
  return [monthData, receivedAmountData];
};

const getConfigData = (userId) => {
  let defaultConfigData = {
    affiliation_type: "high",
    sidedish_num: "2",
    dessert_num: "1",
    target_calories: "800",
    special: "wed",
    meal_price: "3000",
    isinDB: false,
  };

  return axios
    .get("config", { params: { userId: userId } })
    .then((data) => {
      const configData = JSON.parse(data.data);
      return { ...configData, isinDB: true };
    })
    .catch((e) => {
      console.log("new userId:", userId);
      return defaultConfigData;
    });
};
