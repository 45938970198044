import React from "react";
import { connect } from "react-redux";
import Button from "../Buttons/Button";
import NutritionTable from "../NutritionTable";

import * as actionTypes from "data/recommendation/actionTypes";
import { ContinueHandler } from "data/recommendation/actions/recommend";
import "./index.css";

const GetSummary = (props) => {
  const getDayToMenuMonth = () => {
    const Identifier = `${props.year}-${props.month}-${
      props.showLD === "D" ? "D" : "L"
    }`;

    let _dayToMenuMonth = {};

    if (props.monthData && Object.keys(props.monthData).includes(Identifier)) {
      _dayToMenuMonth = props.monthData[Identifier];
    }

    return _dayToMenuMonth;
  };
  const dayToMenuMonth = getDayToMenuMonth();

  if (!(props.selectedDay in dayToMenuMonth)) {
    return;
  }

  const menuList = dayToMenuMonth[props.selectedDay].split("-");

  if (!props.modifyState || !(props.selectedDay in dayToMenuMonth)) {
    return Summary;
  }

  let Summary = "";
  let dayToMenu = "";

  const [IdentifierV1, IdentifierV2] = [
    String(props.year) + "-" + String(props.month) + "-" + "L",
    String(props.year) + "-" + String(props.month) + "-" + "D",
  ];

  const validationCheck =
    IdentifierV1 in props.monthData && IdentifierV2 in props.monthData;

  const defineDayToMenu = () => {
    const dayToMenuLunch = {};
    const dayToMenuDinner = {};

    if (validationCheck) {
      const days = Object.values(props.displayingDays);
      if (props.displayingDays) {
        for (var i = 0; i < days.length; i++) {
          dayToMenuLunch[days[i]] = props.monthData[IdentifierV1][days[i]];
          dayToMenuDinner[days[i]] = props.monthData[IdentifierV2][days[i]];
        }
      }
    }
    return [dayToMenuLunch, dayToMenuDinner];
  };

  const [dayToMenuLunch, dayToMenuDinner] = defineDayToMenu();

  const buttons = (
    <div style={{ paddingTop: "20px" }}>
      <Button clicked={() => props.onContinueHandler()}>수정하기</Button>
      <Button clicked={() => props.onUpdateModifyState()}>취소하기</Button>
    </div>
  );

  switch (props.monthOrWeek) {
    case "month":
      if (props.selectedDay in dayToMenuMonth) {
        Summary = (
          <div className="MenuSummary month">
            <NutritionTable menuList={menuList} />
            {buttons}
          </div>
        );
      }
      return Summary;
    case "week":
      if (props.selectedLD === "L") {
        dayToMenu = dayToMenuLunch;
      } else if (props.selectedLD === "D") {
        dayToMenu = dayToMenuDinner;
      }

      const dayMenu = dayToMenu[props.selectedDay];

      if (!dayMenu) {
        return Summary;
      }

      if (props.selectedLD === "L") {
        dayToMenu = dayToMenuLunch;
      } else if (props.selectedLD === "D") {
        dayToMenu = dayToMenuDinner;
      }

      Summary = (
        <>
          <div className="MenuSummary week">
            <NutritionTable menuList={dayMenu.split("-")} />
            {buttons}
          </div>
        </>
      );

      if (props.selectedDay !== 0 && String(props.selectedDay) in dayToMenu) {
        return Summary;
      }

      return Summary;
  }
};

const mapStateToProps = (state) => {
  return {
    week: state.recommend.calendar.week,
    userName: state.recommend.user.userName,
    year: state.recommend.calendar.year,
    month: state.recommend.calendar.month,
    showLD: state.recommend.calendar.showLD,
    monthData: state.recommend.user.monthData,
    modifyState: state.recommend.selection.modifyState,
    menuList: state.recommend.selection.menuList,
    selectedLD: state.recommend.selection.selectedLD,
    selectedDay: state.recommend.selection.selectedDay,
    selectedDays: state.recommend.selection.selectedDays,
    displayingDays: state.recommend.selection.displayingDays,
    monthOrWeek: state.recommend.calendar.monthOrWeek,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateModifyState: () =>
      dispatch({
        type: actionTypes.UPDATE_MODIFY_STATE,
      }),

    onContinueHandler: () => dispatch(ContinueHandler()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetSummary);
