import * as actionTypes from "../actionTypes";

const today = new Date();
const thisMonth = today.getMonth() + 1;
const thisYear = today.getFullYear();

const initialState = {
  week: 0,
  year: thisYear,
  month: thisMonth,
  showCalendar: false,
  version: 1,
  showLD: "LD",
  monthOrWeek: "week",
  totalNutrition: {
    amount: 0,
    calories: 0,
    carbohydrate_g: 0,
    protein_g: 0,
    fat_g: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_WEEK:
      return {
        ...state,
        week: action.week,
      };

    case actionTypes.UPDATE_MONTH:
      return {
        ...state,
        month: action.month,
      };

    case actionTypes.UPDATE_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case actionTypes.UPDATE_VERSION:
      return {
        ...state,
        version: action.version,
      };

    case actionTypes.UPDATE_SHOW_LD:
      return {
        ...state,
        showLD: action.showLD,
      };

    case actionTypes.UPDATE_SHOW_CALENDAR:
      return {
        ...state,
        showCalendar: !state.showCalendar,
      };

    case actionTypes.UPDATE_MONTH_OR_WEEK:
      return {
        ...state,
        monthOrWeek: action.monthOrWeek,
      };
    case actionTypes.UPDATE_TOTAL_NUTRITION:
      return {
        ...state,
        totalNutrition: action.totalNutrition,
      };
    // case actionTypes.UPDATE_TOTAL_NUTRITION:
    //   return {
    //     ...state,
    //     totalCalories: action.totalCalories,
    //     totalQuantity: action.totalQuantity
    //   };
    default:
      return state;
  }
};
