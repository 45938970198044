import React, { useEffect, useState, useMemo } from "react";

import { apiClient } from "api/client";

import styled from "styled-components";

import AddMenuModal from "./AddMenuModal";
import Emoji from "components/Basics/Emoji";
import { useFoodGroups } from "hooks";

export default function EditBox({ customFoodList, menuInfo, setIsEditMode }) {
  const { comment, Quizzes } = menuInfo;

  const [updatedList, setUpdatedList] = useState(customFoodList);
  const [modalOpen, setModalOpen] = useState(false);
  //
  const [menuComment, setMenuComment] = useState(comment);
  const [answer, setAnswer] = useState(
    Quizzes && Quizzes[0] ? Quizzes[0].answer : null
  );
  const [quizContent, setQuizContent] = useState(
    Quizzes && Quizzes[0] ? Quizzes[0].quizContent : null
  );
  const [quizComment, setQuizComment] = useState(
    Quizzes && Quizzes[0] ? Quizzes[0].commentRight : null
  );

  const { departmentId } = menuInfo;

  const handleMenuComment = (e) => {
    setMenuComment(e.target.value);
  };

  const handleQuizContent = (e) => {
    setQuizContent(e.target.value);
  };

  const handleQuizAnaswer = (e) => {
    setAnswer(e.target.value);
  };

  const handleQuizComment = (e) => {
    setQuizComment(e.target.value);
  };

  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  console.log('## updated list: ', updatedList);

  const totalCalories = updatedList.reduce(
    (a, c) => a + c.calories * c.nuviCustomRatio,
    0
  );

  const totalServingAmount = updatedList.reduce(
    (a, c) => a + c.servingAmount * c.nuviCustomRatio,
    0
  );

  const addToUpdatedList = (food) => {
    // 기존 음식과 중복되는 지 체크하는 로직 추가
    const foodIdList = updatedList.map(({ id: foodId }) => foodId);
    if (foodIdList.includes(food.id)) {
      alert("이미 추가되어 있는 음식입니다");
    } else {
      setUpdatedList([...updatedList, food]);
    }
  };

  const deleteFromList = (selectedFood) => {
    const index = updatedList.indexOf(selectedFood);
    const toBeUpdated = updatedList.slice();
    toBeUpdated.splice(index, 1);
    setUpdatedList(toBeUpdated);
  };

  const updateFoodRatio = (selectedFood, ratio) => {
    const index = updatedList.indexOf(selectedFood);
    const ratioUpdated = { ...selectedFood, nuviCustomRatio: ratio };
    const tobeUpdated = updatedList.slice();
    tobeUpdated.splice(index, 1, ratioUpdated);
    setUpdatedList(tobeUpdated);
  };

  const updateCustomFoodName = (selectedFood, foodName) => {
    const index = updatedList.indexOf(selectedFood);
    const nameUpdated = { ...selectedFood, foodName };
    const tobeUpdated = updatedList.slice();
    tobeUpdated.splice(index, 1, nameUpdated);
    setUpdatedList(tobeUpdated);
  };

  const updateMenu = async () => {
    try {
      if (window.confirm("메뉴를 수정하시겠습니까?")) {
        const res = await apiClient.patch(`/api/menu/single`, {
          menuInfo,
          updatedList,
          menuComment,
          quizContent,
          quizComment,
          answer,
        });
        setUpdatedList([]);
        setMenuComment(comment);
        setIsEditMode(false);
        alert("메뉴가 수정되었습니다");
      }
    } catch (e) {
      alert(e.message);
    }
  };

  useEffect(() => {
    console.log("re-render: ", menuComment);
    setUpdatedList(customFoodList);
  }, []);

  return (
    <>
      <ResultRowNameContainer>메뉴 코멘트</ResultRowNameContainer>
      <ResultRowContainer>
        <ResultRow>코멘트: </ResultRow>
        <StyledTextArea
          defaultValue={menuComment}
          onChange={handleMenuComment}
        />
      </ResultRowContainer>
      <ResultRowNameContainer>퀴즈</ResultRowNameContainer>
      <ResultRowContainer>
        <ResultRow>퀴즈: </ResultRow>
        <StyledTextArea
          defaultValue={quizContent}
          onChange={handleQuizContent}
        />
      </ResultRowContainer>
      <ResultRowContainer>
        <ResultRow>정답: </ResultRow>
        <select onChange={handleQuizAnaswer} style={{ width: "100%" }}>
          <option value="O" selected={answer === "O" ? true : false}>
            O
          </option>
          <option value="X" selected={answer === "X" ? true : false}>
            X
          </option>
        </select>
      </ResultRowContainer>
      <ResultRowContainer>
        <ResultRow>해설: </ResultRow>
        <StyledTextArea
          defaultValue={quizComment}
          onChange={handleQuizComment}
        />
      </ResultRowContainer>

      <ResultRowNameContainer>
        <ResultRow>누비푸드ID</ResultRow>
        <ResultRow>누비푸드명</ResultRow>
        <ResultRow>비율</ResultRow>
        <ResultRow>칼로리(kcal)</ResultRow>
        <ResultRow>제공량</ResultRow>
        <ResultRow>식품군</ResultRow>
        <ResultRow>삭제</ResultRow>
      </ResultRowNameContainer>
      <ModalWrapper visible={modalOpen}>
        <ModalInner>
          <AddMenuModal
            addToUpdatedList={addToUpdatedList}
            handleModalOpen={handleModalOpen}
            modalOpen={modalOpen}
            departmentId={departmentId}
          />
        </ModalInner>
      </ModalWrapper>

      {updatedList.map((food, idx) => (
        <FoodElement
          key={idx}
          food={food}
          menuInfo={menuInfo}
          deleteFromList={deleteFromList}
          updateFoodRatio={updateFoodRatio}
          updateCustomFoodName={updateCustomFoodName}
        />
      ))}
      <TotalInfoContainer>
        <TotalCalorieDiv>
          총 칼로리: {totalCalories.toFixed(2)}kcal
        </TotalCalorieDiv>
        <TotalCalorieDiv>
          총 제공량: {totalServingAmount.toFixed(2)}g
        </TotalCalorieDiv>
      </TotalInfoContainer>
      <ButtonContainer>
        <StyledButton onClick={handleModalOpen}>음식 추가하기</StyledButton>
        <StyledButton onClick={updateMenu}>메뉴 업데이트</StyledButton>
      </ButtonContainer>
    </>
  );
}

function FoodElement({
  food,
  menuInfo,
  updateFoodRatio,
  updateCustomFoodName,
  deleteFromList,
}) {
  const [ratio, setRatio] = useState(food.nuviCustomRatio);
  const [foodName, setFoodName] = useState(food.foodName);

  const foodGroupList = useFoodGroups();

  const handleFoodGroupName = (foodGroupId) => {
    for (let i = 0; i < foodGroupList.length; i++) {
      if (foodGroupList[i].id === foodGroupId) {
        return foodGroupList[i].groupName;
      }
    }
    return "없음";
  };

  const updatedCalories = useMemo(
    () => ((food.calories / food.nuviCustomRatio) * ratio).toFixed(2),
    [food, ratio]
  );

  const updatedServingAmount = useMemo(
    () => ((food.servingAmount / food.nuviCustomRatio) * ratio).toFixed(2),
    [food, ratio]
  );

  return (
    <ResultRowContainer>
      <ResultRow>{food.id}</ResultRow>
      <ResultRow>{food.foodName}</ResultRow>
      <ResultRow>
        <StyledInput
          type="number"
          onChange={(e) => {
            setRatio(e.target.value);
            updateFoodRatio(food, e.target.value);
          }}
          defaultValue={food.nuviCustomRatio}
          step="0.1"
          min="0"
        />
      </ResultRow>
      <ResultRow>{(food.calories * ratio).toFixed(2)}kcal</ResultRow>
      <ResultRow>{(food.servingAmount * ratio).toFixed(2)}</ResultRow>

      <ResultRow>{handleFoodGroupName(food.foodGroupId)}</ResultRow>
      <ResultRow
        onClick={() => deleteFromList(food)}
        style={{ cursor: "pointer" }}
      >
        <Emoji symbol="🗑️" label="bin" />
      </ResultRow>
    </ResultRowContainer>
  );
}

const ResultRowContainer = styled.div`
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  padding: 2px;
  marign: 3px;
  border: 1px solid black;
  border-radius: 5px;
  &:hover {
    background-color: lightgray;
  }
  &:active {
    background-color: gold;
  }
`;

const ResultRow = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  padding: 2px;
  margin: 3px;
  font-size: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 25px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 45px;
`;

const TotalInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TotalCalorieDiv = styled.div`
  margin: 20px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 300;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button`
  width: 150px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid black;
  margin: 15px;
  font-size: 22px;
  cursor: pointer;
  &:hover {
    background-color: lightgray;
  }
`;

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  width: 1500px;
  height: 950px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: white;
  border: 3px solid black;
  font-size: 0.8rem;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ResultRowNameContainer = styled(ResultRowContainer)`
  background-color: #f0f0f0;
  cursor: default;
  margin-top: 10px;
`;
